import React, { useState, useEffect, createContext, useContext } from 'react';
import { AwsClient } from 'aws4fetch';

import { AuthContext } from './AuthContext';
import { API_ENDPOINT } from '../consts/globals';

export const MerchantsContext = createContext();

const MerchantsContextProvider = props => {
  const { creds } = useContext(AuthContext);
  const [merchants, setMerchants] = useState([]);
  const [response, setResponse] = useState({});

  const abortController = new AbortController();
  const signal = abortController.signal;

  const aws = new AwsClient({
    accessKeyId: creds.accessKeyId,
    secretAccessKey: creds.secretAccessKey,
    sessionToken: creds.sessionToken
  });

  const getMerchants = async () => {
    try {
      const response = await aws.fetch(`${API_ENDPOINT}/system/merchants`, {
        signal: signal,
        method: 'GET',
        headers: {
          'Content-type': 'application/json',
          Accept: 'application/json'
        }
      });

      if (!response.ok) {
        throw Error(response.statusText);
      }

      const responseJson = await response.json();

      setMerchants(responseJson.data);
    } catch (error) {
      console.log(error);
    }
  };

  const createMerchant = async merchant => {
    setResponse({});

    let image64;

    if (merchant.image) {
      image64 = new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(merchant.image[0].originFileObj);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
      });
    }

    try {
      const transformedMerchant = {
        ...merchant,
        image: await image64
      };

      const response = await aws.fetch(`${API_ENDPOINT}/system/merchants`, {
        method: 'POST',
        headers: {
          'Content-type': 'application/json',
          Accept: 'application/json'
        },
        body: JSON.stringify(transformedMerchant)
      });

      const responseJson = await response.json();
      setResponse(responseJson);

      if (!response.ok) {
        throw Error(response.statusText);
      }

      getMerchants();
    } catch (error) {
      console.log(error);
    }
  };

  const editMerchant = async (merchant, id) => {
    setResponse({});

    let image64;

    if (merchant.image) {
      image64 = new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(merchant.image[0].originFileObj);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
      });
    }

    try {
      const { image, ...transformedMerchant } = merchant;

      if (merchant.image) {
        transformedMerchant.image = await image64;
      }

      const response = await aws.fetch(
        `${API_ENDPOINT}/system/merchants/${id}`,
        {
          method: 'PATCH',
          headers: {
            'Content-type': 'application/json',
            Accept: 'application/json'
          },
          body: JSON.stringify(transformedMerchant)
        }
      );

      const responseJson = await response.json();
      setResponse(responseJson);

      if (!response.ok) {
        throw Error(response.statusText);
      }

      getMerchants();
    } catch (error) {
      console.log(error);
    }
  };

  const deleteMerchant = async id => {
    setResponse({});

    try {
      const response = await aws.fetch(
        `${API_ENDPOINT}/system/merchants/${id}`,
        {
          method: 'DELETE',
          headers: {
            'Content-type': 'application/json',
            Accept: 'application/json'
          }
        }
      );

      const responseJson = await response.json();
      setResponse(responseJson);

      if (!response.ok) {
        throw Error(response.statusText);
      }

      getMerchants();
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getMerchants();

    return function cleanup() {
      abortController.abort();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <MerchantsContext.Provider
      value={{
        merchants,
        getMerchants,
        createMerchant,
        editMerchant,
        deleteMerchant,
        response,
        setResponse
      }}
    >
      {props.children}
    </MerchantsContext.Provider>
  );
};

export default MerchantsContextProvider;
