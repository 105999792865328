import React, { useContext, useEffect, useState } from 'react';
import { Modal, Form, Input, InputNumber } from 'antd';

import { MembershipPlansContext } from '../../contexts/MembershipPlansContext';

const CreateMembershipPlanForm = props => {
  const { createMembershipPlan, response, setResponse } = useContext(
    MembershipPlansContext
  );
  const { modalVisible, setModalVisible, modal } = props;
  const [validationError, setValidationError] = useState({});
  const [loading, setLoading] = useState(false);

  const { getFieldDecorator, getFieldError } = props.form;

  const nameError = getFieldError('name') || validationError.name;
  const descriptionError =
    getFieldError('description') || validationError.description;
  const priceError = getFieldError('price') || validationError.price;
  const daysError = getFieldError('days') || validationError.days;

  const closeModal = () => {
    setValidationError({});
    setModalVisible(false);
    props.form.resetFields();
  };

  const handleOk = () => {
    setLoading(true);

    props.form.validateFields((error, fieldsValue) => {
      if (error) {
        setLoading(false);
        return;
      }

      createMembershipPlan(fieldsValue);
    });
  };

  const handleCancel = () => {
    setResponse({});
    setLoading(false);
    closeModal();
  };

  const handleChange = e => {
    const currentValidation = { ...validationError };

    if (validationError) {
      setValidationError(currentValidation);
    }
  };

  useEffect(() => {
    if (modal === 'create') {
      if (response.errors) {
        setValidationError(response.errors);
      } else {
        setValidationError({});
      }

      if (
        response.requestFor === 'create' &&
        response.requestInProgress === false &&
        response.id
      ) {
        closeModal();
      }

      if (
        response.requestFor === 'create' &&
        response.requestInProgress === false
      ) {
        setLoading(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [response]);

  if (modal === 'create') {
    return (
      <Modal
        title={`Add new membership plan`}
        visible={modalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        bodyStyle={{ maxHeight: '50vh', overflowY: 'scroll' }}
        confirmLoading={loading}
      >
        <Form layout="vertical" onSubmit={handleOk}>
          <Form.Item
            label="Name"
            validateStatus={nameError ? 'error' : ''}
            help={nameError || ''}
          >
            {getFieldDecorator('name', {
              rules: [
                {
                  required: true,
                  message: 'Please input a membership plan name!'
                }
              ],
              onChange: e => handleChange(e)
            })(<Input name="name" placeholder="Name" />)}
          </Form.Item>
          <Form.Item
            label="Description"
            validateStatus={descriptionError ? 'error' : ''}
            help={descriptionError || ''}
          >
            {getFieldDecorator('description', {
              onChange: e => handleChange(e)
            })(<Input name="description" placeholder="Description" />)}
          </Form.Item>
          <Form.Item
            label="Price"
            validateStatus={priceError ? 'error' : ''}
            help={priceError || ''}
          >
            {getFieldDecorator('price', {
              rules: [
                {
                  required: true,
                  message: 'Please input a membership plan price!'
                }
              ],
              onChange: e => handleChange(e)
            })(
              <InputNumber
                name="price"
                placeholder="Price"
                min={0}
                step={1}
                precision={2}
                formatter={value =>
                  `€ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                }
                parser={value => value.replace(/€\s?|(,*)/g, '')}
                style={{ width: '100%' }}
              />
            )}
          </Form.Item>
          <Form.Item
            label="Days"
            validateStatus={daysError ? 'error' : ''}
            help={daysError || ''}
          >
            {getFieldDecorator('days', {
              rules: [
                {
                  required: true,
                  message: 'Please input a membership plan duration!'
                }
              ],
              onChange: e => handleChange(e)
            })(
              <InputNumber
                name="days"
                placeholder="Days"
                min={0}
                step={1}
                style={{ width: '100%' }}
              />
            )}
          </Form.Item>
        </Form>
      </Modal>
    );
  } else {
    return null;
  }
};

const CreateMembershipPlan = Form.create()(CreateMembershipPlanForm);

export default CreateMembershipPlan;
