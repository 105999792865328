import React, { useContext } from 'react';
import { Layout } from 'antd';
import styled from 'styled-components';
import { Switch, Route } from 'react-router-dom';

import { APP_NAME } from '../consts/globals';

import { AdminContext } from '../contexts/AdminContext';
import Sidebar from './shared/Sidebar';
import Header from './shared/Header';
import Footer from './shared/Footer';
import Welcome from './Welcome';
import AnalyticsContextProvider from '../contexts/AnalyticsContext';
import Analytics from './analytics/Analytics';
import UsersContextProvider from '../contexts/UsersContext';
import UsersList from '../components/users/UsersList';
import CountriesContextProvider from '../contexts/CountriesContext';
import CountriesList from '../components/countries/CountriesList';
import CitiesContextProvider from '../contexts/CitiesContext';
import CitiesList from '../components/cities/CitiesList';
import MerchantsContextProvider from '../contexts/MerchantsContext';
import MerchantsList from '../components/merchants/MerchantsList';
import CommunitiesContextProvider from '../contexts/CommunitiesContext';
import CommunitiesList from '../components/communities/CommunitiesList';
import RedeemLocationsContextProvider from '../contexts/RedeemLocationsContext';
import RedeemLocationsList from '../components/redeemLocations/RedeemLocationsList';
import CampaignsContextProvider from '../contexts/CampaignsContext';
import CampaignsList from '../components/campaigns/CampaignsList';
import CouponsContextProvider from '../contexts/CouponsContext';
import CouponsList from '../components/coupons/CouponsList';
import NewsContextProvider from '../contexts/NewsContext';
import NewsList from '../components/news/NewsList';
import MembershipsContextProvider from '../contexts/MembershipsContext';
import MembershipsList from '../components/memberships/MembershipsList';
import MembershipPlansContextProvider from '../contexts/MembershipPlansContext';
import MembershipPlansList from '../components/membershipPlans/MembershipPlansList';
import InvoicesContextProvider from '../contexts/InvoicesContext';
import OffersList from './offers/OffersList';
import InvoicesList from './invoices/InvoicesList';
import LegalContextProvider from '../contexts/LegalContext';
import LegalList from './legal/LegalList';
import NotFound from './NotFound';

const { Content } = Layout;

const StyledLayout = styled(Layout)`
  min-height: 100vh;
`;

const StyledContent = styled(Content)`
  margin: 1.5rem;
`;

const StyledWrapper = styled.div`
  padding: 1.5rem;
  background: #fff;
  position: relative;
`;

const StyledAnalyticsWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #f0f2f5;
  z-index: 1;
`;

const Home = () => {
  const { userRole } = useContext(AdminContext);

  return (
    <div>
      <StyledLayout>
        <Sidebar />
        <Layout>
          <Header />
          <StyledContent>
            <StyledWrapper>
              {(() => {
                switch (userRole) {
                  case `${APP_NAME}-admin`:
                    return (
                      <Switch>
                        <Route path="/" exact>
                          <AnalyticsContextProvider>
                            <Analytics />
                          </AnalyticsContextProvider>
                          <StyledAnalyticsWrapper />
                        </Route>
                        <Route path="/users">
                          <UsersContextProvider>
                            <UsersList />
                          </UsersContextProvider>
                        </Route>
                        <Route path="/countries">
                          <CountriesContextProvider>
                            <CountriesList />
                          </CountriesContextProvider>
                        </Route>
                        <Route path="/cities">
                          <CountriesContextProvider>
                            <CitiesContextProvider>
                              <CitiesList />
                            </CitiesContextProvider>
                          </CountriesContextProvider>
                        </Route>
                        <Route path="/merchants">
                          <MerchantsContextProvider>
                            <MerchantsList />
                          </MerchantsContextProvider>
                        </Route>
                        <Route path="/communities">
                          <CountriesContextProvider>
                            <CitiesContextProvider>
                              <CommunitiesContextProvider>
                                <CommunitiesList />
                              </CommunitiesContextProvider>
                            </CitiesContextProvider>
                          </CountriesContextProvider>
                        </Route>
                        <Route path="/redeem-locations">
                          <CountriesContextProvider>
                            <CitiesContextProvider>
                              <RedeemLocationsContextProvider>
                                <RedeemLocationsList />
                              </RedeemLocationsContextProvider>
                            </CitiesContextProvider>
                          </CountriesContextProvider>
                        </Route>
                        <Route path="/campaigns">
                          <CountriesContextProvider>
                            <CitiesContextProvider>
                              <CommunitiesContextProvider>
                                <RedeemLocationsContextProvider>
                                  <MerchantsContextProvider>
                                    <CampaignsContextProvider>
                                      <CampaignsList />
                                    </CampaignsContextProvider>
                                  </MerchantsContextProvider>
                                </RedeemLocationsContextProvider>
                              </CommunitiesContextProvider>
                            </CitiesContextProvider>
                          </CountriesContextProvider>
                        </Route>
                        <Route path="/news">
                          <CountriesContextProvider>
                            <CitiesContextProvider>
                              <CommunitiesContextProvider>
                                <NewsContextProvider>
                                  <NewsList />
                                </NewsContextProvider>
                              </CommunitiesContextProvider>
                            </CitiesContextProvider>
                          </CountriesContextProvider>
                        </Route>
                        <Route
                          path="/memberships/:uuid"
                          render={({ match }) => (
                            <UsersContextProvider>
                              <MembershipPlansContextProvider>
                                <MembershipsContextProvider match={match}>
                                  <MembershipsList />
                                </MembershipsContextProvider>
                              </MembershipPlansContextProvider>
                            </UsersContextProvider>
                          )}
                        />
                        <Route path="/membership-plans">
                          <MembershipPlansContextProvider>
                            <MembershipPlansList />
                          </MembershipPlansContextProvider>
                        </Route>
                        <Route path="/coupons">
                          <CountriesContextProvider>
                            <CitiesContextProvider>
                              <CommunitiesContextProvider>
                                <RedeemLocationsContextProvider>
                                  <MerchantsContextProvider>
                                    <CampaignsContextProvider>
                                      <CouponsContextProvider>
                                        <CouponsList />
                                      </CouponsContextProvider>
                                    </CampaignsContextProvider>
                                  </MerchantsContextProvider>
                                </RedeemLocationsContextProvider>
                              </CommunitiesContextProvider>
                            </CitiesContextProvider>
                          </CountriesContextProvider>
                        </Route>
                        <Route path="/offers">
                          <InvoicesContextProvider>
                            <OffersList />
                          </InvoicesContextProvider>
                        </Route>
                        <Route path="/invoices">
                          <InvoicesContextProvider>
                            <InvoicesList />
                          </InvoicesContextProvider>
                        </Route>
                        <Route path="/legal">
                          <LegalContextProvider>
                            <LegalList />
                          </LegalContextProvider>
                        </Route>
                        <Route component={NotFound} />
                      </Switch>
                    );

                  case `${APP_NAME}-editor`:
                    return (
                      <Switch>
                        <Route path="/" exact>
                          <Welcome />
                        </Route>
                        <Route path="/countries">
                          <CountriesContextProvider>
                            <CountriesList />
                          </CountriesContextProvider>
                        </Route>
                        <Route component={NotFound} />
                      </Switch>
                    );

                  default:
                    return (
                      <Switch>
                        <Route path="/" exact>
                          <Welcome />
                        </Route>
                        <Route path="/countries">
                          <CountriesContextProvider>
                            <CountriesList />
                          </CountriesContextProvider>
                        </Route>
                        <Route component={NotFound} />
                      </Switch>
                    );
                }
              })()}
            </StyledWrapper>
          </StyledContent>
          <Footer />
        </Layout>
      </StyledLayout>
    </div>
  );
};

export default Home;
