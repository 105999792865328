import React, { useContext, useEffect, useState } from 'react';
import { Modal, Form, Input, Select, Checkbox, Tooltip, Icon } from 'antd';

import { CouponsContext } from '../../contexts/CouponsContext';
import { CampaignsContext } from '../../contexts/CampaignsContext';

const { Option } = Select;

const CreateCouponForm = props => {
  const { createCoupon, response, setResponse } = useContext(CouponsContext);
  const { campaigns } = useContext(CampaignsContext);
  const { modalVisible, setModalVisible, modal } = props;
  const [validationError, setValidationError] = useState({});
  const [loading, setLoading] = useState(false);
  const [unique, setUnique] = useState(false);

  const { getFieldDecorator, getFieldError } = props.form;

  const campaignError = getFieldError('campaign') || validationError.campaign;
  const couponCodeError =
    getFieldError('coupon_code') || validationError.coupon_code;
  const uniqueError = getFieldError('unique') || validationError.unique;
  const limitError = getFieldError('limited') || validationError.limited;

  const closeModal = () => {
    setValidationError({});
    setModalVisible(false);
    props.form.resetFields();
    setUnique(false);
  };

  const handleOk = () => {
    setLoading(true);

    props.form.validateFields((error, values) => {
      if (error) {
        setLoading(false);
        return;
      }

      createCoupon(values);
    });
  };

  const handleCancel = () => {
    setResponse({});
    setLoading(false);
    closeModal();
  };

  const handleChange = e => {
    const currentValidation = { ...validationError };

    if (e.file) {
      currentValidation.flag = undefined;
    } else {
      const eventTarget = e.target;

      if (eventTarget) {
        const fieldName = eventTarget.name;
        currentValidation[fieldName] = undefined;
      }
    }

    if (validationError) {
      setValidationError(currentValidation);
    }
  };

  const handleSelect = () => {
    const currentValidation = { ...validationError };

    currentValidation.campaign = undefined;

    if (validationError) {
      setValidationError(currentValidation);
    }
  };

  const handleUnique = e => {
    setUnique(e.target.checked);
  };

  useEffect(() => {
    if (response.errors) {
      setValidationError(response.errors);
    } else {
      setValidationError({});
    }

    if (response === 'Coupon created successfully.') {
      closeModal();
    }

    if (Object.entries(response).length !== 0) {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [response]);

  if (modal === 'create') {
    return (
      <Modal
        title={`Add new coupon code`}
        visible={modalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        confirmLoading={loading}
      >
        <Form layout="vertical" onSubmit={handleOk}>
          <Form.Item
            label="Campaign"
            validateStatus={campaignError ? 'error' : ''}
            help={campaignError || ''}
          >
            {getFieldDecorator('campaign', {
              rules: [{ required: true, message: 'Please select a campaign!' }],
              onChange: e => handleSelect(e)
            })(
              <Select
                name="campaign"
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
                placeholder="Select campaign"
                style={{ width: 200 }}
              >
                {campaigns.map(campaign => {
                  return (
                    <Option key={campaign.id} value={campaign.id}>
                      {`${campaign.name}`}
                    </Option>
                  );
                })}
              </Select>
            )}
          </Form.Item>
          <Form.Item
            label="Coupon code"
            validateStatus={couponCodeError ? 'error' : ''}
            help={couponCodeError || ''}
          >
            {getFieldDecorator('coupon_code', {
              rules: [
                { required: true, message: 'Please enter a coupon code!' }
              ],
              onChange: e => handleChange(e)
            })(<Input name="coupon_code" placeholder="Coupon code" />)}
          </Form.Item>
          <Form.Item
            validateStatus={uniqueError ? 'error' : ''}
            help={uniqueError || ''}
          >
            {getFieldDecorator('unique', {
              initialValue: false,
              valuePropName: 'checked',
              onChange: e => handleUnique(e)
            })(<Checkbox name="unique">Unique</Checkbox>)}
          </Form.Item>
          {unique ? (
            <Form.Item
              label="Limit number"
              validateStatus={limitError ? 'error' : ''}
              help={limitError || ''}
            >
              {getFieldDecorator('limited', {
                initialValue: 0,
                rules: [
                  { required: true, message: 'Please enter a limit number!' },
                  {
                    pattern: /^[0-9]*$/,
                    message: 'Must be a number!'
                  }
                ],
                onChange: e => handleChange(e)
              })(
                <Input
                  name="limited"
                  suffix={
                    <Tooltip title="If the number is 0 the coupon is limitless, otherwise it is limited to the entered number">
                      <Icon
                        type="info-circle"
                        style={{ color: 'rgba(0,0,0,.45)' }}
                      />
                    </Tooltip>
                  }
                />
              )}
            </Form.Item>
          ) : null}
        </Form>
      </Modal>
    );
  } else {
    return null;
  }
};

const CreateCoupon = Form.create()(CreateCouponForm);

export default CreateCoupon;
