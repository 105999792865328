import React, { useContext, Fragment } from 'react';
import { Row, Col, Table } from 'antd';
import styled from 'styled-components';

import { AnalyticsContext } from '../../contexts/AnalyticsContext';

const StyledWrapper = styled.div`
  padding: 1.5rem;
  background: #fff;
  position: relative;
  margin-bottom: 2rem;

  h3 {
    margin-bottom: 1.5rem;
    margin-left: 1rem;
  }
`;

const UsersDetails = () => {
  const { usersStats } = useContext(AnalyticsContext);

  const columns = [
    {
      title: 'User',
      dataIndex: 'user_id'
    },
    {
      title: 'Coupons used',
      dataIndex: 'coupons_used'
    }
  ];

  return (
    <Fragment>
      <Row gutter={24}>
        <Col xs={24} sm={24} md={24} lg={24} xl={12}>
          <StyledWrapper>
            <h3>Top 10 users</h3>
            <Table
              columns={columns}
              rowKey={(user) => user.user_id}
              dataSource={usersStats}
              pagination={false}
              scroll={{ x: 'max-content' }}
              size="middle"
            />
          </StyledWrapper>
        </Col>
      </Row>
    </Fragment>
  );
};

export default UsersDetails;
