import React from 'react';

const NotFound = () => {
  return (
    <div>
      <p>
        The page you were looking for does not exist or you don't have
        sufficient permissions to see it!
      </p>
    </div>
  );
};

export default NotFound;
