import React, { useState, useEffect, useContext } from 'react';
import { Drawer, Typography, Spin } from 'antd';

import { MembershipPlansContext } from '../../contexts/MembershipPlansContext';

const { Text } = Typography;

const SingleMembershipPlan = props => {
  const { response, setResponse } = useContext(MembershipPlansContext);
  const {
    drawer,
    setDrawer,
    selectedMembershipPlan,
    setSelectedMembershipPlan
  } = props;
  const [loading, setLoading] = useState(true);

  const transformedMembershipPlan = {
    Name: selectedMembershipPlan.name,
    Description: selectedMembershipPlan.description || '-',
    Price: selectedMembershipPlan.price,
    Days: selectedMembershipPlan.days
  };

  useEffect(() => {
    if (drawer) {
      setLoading(true);
    }
  }, [drawer]);

  useEffect(() => {
    if (
      response.requestFor === 'get single' &&
      response.requestInProgress === false &&
      response.id
    ) {
      setLoading(false);
    }
  }, [response]);

  return (
    <Drawer
      width={640}
      placement="right"
      closable={false}
      onClose={() => {
        setDrawer(false);
        setResponse({});
        setLoading(false);
        setTimeout(() => {
          setSelectedMembershipPlan({});
        }, 300);
      }}
      visible={drawer}
    >
      <Spin spinning={loading}>
        <div>
          {Object.keys(transformedMembershipPlan).map((keyName, i) => {
            return (
              <p key={i}>
                <Text>
                  {keyName}:{' '}
                  <Text type="secondary">
                    {transformedMembershipPlan[keyName]}
                  </Text>
                </Text>
              </p>
            );
          })}
        </div>
      </Spin>
    </Drawer>
  );
};

export default SingleMembershipPlan;
