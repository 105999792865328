import React, { useContext, useState, Fragment } from 'react';
import styled from 'styled-components';
import { Table, Icon, Button } from 'antd';

import { CountriesContext } from '../../contexts/CountriesContext';
import CreateCountry from './CreateCountry';
import EditCountry from './EditCountry';
import DeleteCountry from './DeleteCountry';

const StyledButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 1rem;
`;

const StyledButton = styled(Button)`
  margin-right: 0.5rem;
`;

const StyledIcon = styled.span`
  cursor: pointer;
`;

const CountriesList = () => {
  const { countries } = useContext(CountriesContext);
  const [modalVisible, setModalVisible] = useState(false);
  const [modal, setModal] = useState('');
  const [selectedCountry, setSelectedCountry] = useState({});

  const transformedCountries = countries.map(country => {
    const countryName = country.name
      .split(' ')
      .map(w => w.substring(0, 1).toUpperCase() + w.substring(1))
      .join(' ');

    const transformedCountry = {
      id: country.id,
      name: countryName,
      iso: country.iso.toUpperCase(),
      visible: country.visible ? true : false
    };

    return transformedCountry;
  });

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      sorter: (a, b) => a.name.localeCompare(b.name),
      sortDirections: ['descend', 'ascend']
    },
    {
      title: 'ID',
      dataIndex: 'id',
      sorter: (a, b) => a.id - b.id,
      sortDirections: ['descend', 'ascend']
    },
    {
      title: 'ISO',
      dataIndex: 'iso'
    },
    {
      title: 'Action',
      render: country => (
        <span>
          <StyledIcon
            onClick={() => {
              setModalVisible(true);
              setModal('edit');
              setSelectedCountry(country);
            }}
          >
            <Icon type="edit" />
          </StyledIcon>
        </span>
      )
    }
  ];

  return (
    <Fragment>
      <StyledButtonWrapper>
        <StyledButton
          shape="circle"
          icon="plus"
          type="primary"
          onClick={() => {
            setModalVisible(true);
            setModal('create');
          }}
        ></StyledButton>
      </StyledButtonWrapper>
      <Table
        columns={columns}
        rowKey={country => country.id}
        dataSource={transformedCountries}
        pagination={{ pageSize: 10 }}
        scroll={{ x: 'max-content' }}
      />
      <CreateCountry
        modalVisible={modalVisible}
        setModalVisible={setModalVisible}
        modal={modal}
      />
      <EditCountry
        modalVisible={modalVisible}
        setModalVisible={setModalVisible}
        modal={modal}
        selectedCountry={selectedCountry}
      />
      <DeleteCountry
        modalVisible={modalVisible}
        setModalVisible={setModalVisible}
        modal={modal}
        selectedCountry={selectedCountry}
      />
    </Fragment>
  );
};

export default CountriesList;
