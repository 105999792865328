import React, { useState, useEffect, createContext } from 'react';
import AWS from 'aws-sdk';
import { Auth } from 'aws-amplify';

import { APP_NAME } from '../consts/globals';

export const UsersContext = createContext();

const UsersContextProvider = props => {
  const [users, setUsers] = useState([]);
  const [transformedUsers, setTransformedUsers] = useState([]);
  const [groups, setGroups] = useState([]);
  const [userGroups, setUserGroups] = useState([]);
  const [response, setResponse] = useState({
    progress: false
  });

  const customAttributes = [
    'street',
    'zip',
    'city',
    'country',
    'business_name',
    'business_country',
    'business_city',
    'business_zip',
    'business_address',
    'business_vat'
  ];

  const getUsers = async () => {
    try {
      const cognito = new AWS.CognitoIdentityServiceProvider();
      let allUsers = [];
      let more = true;
      let paginationToken = '';
      
      while (more) {
        let params = {
          UserPoolId: process.env.REACT_APP_AWS_USER_POOLS_ID,
          Limit: 60
        };

        if (paginationToken !== '') {
          params.PaginationToken = paginationToken;
        }

        const rawUsers = await cognito.listUsers(params).promise();
        allUsers = allUsers.concat(rawUsers.Users);

        if (rawUsers.PaginationToken) {
          paginationToken = rawUsers.PaginationToken;
        } else {
          more = false;
        }
      }

      setUsers(allUsers);
    } catch (error) {
      console.log(error);
    }
  };

  const getGroups = async () => {
    const params = {
      UserPoolId: process.env.REACT_APP_AWS_USER_POOLS_ID
    };

    const cognito = new AWS.CognitoIdentityServiceProvider();
    await cognito.listGroups(params, function(error, data) {
      if (error) {
        console.log(error);
      } else {
        const filteredGroups = data.Groups.filter(group => {
          return group.GroupName.includes(APP_NAME);
        });

        setGroups(filteredGroups);
      }
    });
  };

  const createUser = async user => {
    setResponse({
      progress: true
    });

    let params = {
      username: user.email,
      password: getRandomString(30),
      attributes: {
        email: user.email
      }
    };

    const existingUserAttributes = customAttributes.filter(attribute => {
      return user[attribute];
    });

    existingUserAttributes.forEach(param => {
      params.attributes['custom:' + param] = user[param];
    });

    try {
      await Auth.signUp(params);
      getUsers();
      setResponse({ progress: false });
    } catch (error) {
      setResponse({ ...error, progress: false });
    }
  };

  const getRandomString = bytes => {
    const randomValues = new Uint8Array(bytes);
    window.crypto.getRandomValues(randomValues);
    return Array.from(randomValues)
      .map(intToHex)
      .join('');
  };

  const intToHex = nr => {
    return nr.toString(16).padStart(2, '0');
  };

  const getUserGroups = async user => {
    const params = {
      UserPoolId: process.env.REACT_APP_AWS_USER_POOLS_ID,
      Username: user.username
    };

    const cognito = new AWS.CognitoIdentityServiceProvider();
    await cognito.adminListGroupsForUser(params, function(error, data) {
      if (error) {
        console.log(error);
      } else {
        setUserGroups(data.Groups);
      }
    });
  };

  const changeUserGroup = async user => {
    setResponse({ requestFor: 'change group', requestInProgress: true });

    const removeGroupParams = {
      UserPoolId: process.env.REACT_APP_AWS_USER_POOLS_ID,
      Username: user.username,
      GroupName: userGroups[0] ? userGroups[0].GroupName : user.group
    };

    let changeGroupParams = {
      UserPoolId: process.env.REACT_APP_AWS_USER_POOLS_ID,
      Username: user.username,
      GroupName: user.group
    };

    const cognito = new AWS.CognitoIdentityServiceProvider();

    cognito.adminRemoveUserFromGroup(removeGroupParams, function(error, data) {
      if (error) {
        setResponse({
          ...error,
          requestFor: 'change group',
          requestInProgress: false
        });
      } else {
        setResponse({
          ...data,
          requestFor: 'change group',
          requestInProgress: true
        });

        cognito.adminAddUserToGroup(changeGroupParams, function(error, data) {
          if (error) {
            setResponse({
              ...error,
              requestFor: 'change group',
              requestInProgress: false
            });
          } else {
            getUsers();
            setResponse({
              ...data,
              requestFor: 'change group',
              requestInProgress: false
            });
          }
          getUserGroups(user);
        });
      }
    });
  };

  const removeUserGroup = async (user, group) => {
    setResponse({ requestFor: 'remove group', requestInProgress: true });

    let params = {
      UserPoolId: process.env.REACT_APP_AWS_USER_POOLS_ID,
      Username: user.username,
      GroupName: group
    };

    const cognito = new AWS.CognitoIdentityServiceProvider();
    cognito.adminRemoveUserFromGroup(params, function(error, data) {
      if (error) {
        setResponse({
          ...error,
          requestFor: 'remove group',
          requestInProgress: false
        });
      } else {
        setResponse({
          ...data,
          requestFor: 'remove group',
          requestInProgress: false
        });
        getUserGroups(user);
      }
    });
  };

  const editUser = async user => {
    setResponse({
      progress: true
    });

    let params = {
      UserPoolId: process.env.REACT_APP_AWS_USER_POOLS_ID,
      Username: user.email,
      UserAttributes: [
        {
          Name: 'email',
          Value: user.email
        }
      ]
    };

    const existingUserAttributes = customAttributes.filter(attribute => {
      return user[attribute];
    });

    existingUserAttributes.forEach(param => {
      params.UserAttributes.push({
        Name: 'custom:' + param,
        Value: user[param]
      });
    });

    const cognito = new AWS.CognitoIdentityServiceProvider();
    cognito.adminUpdateUserAttributes(params, function(error, data) {
      if (error) {
        setResponse({ ...error, progress: false });
      } else {
        getUsers();
        setResponse({ ...data, progress: false });
      }
    });
  };

  const deleteUser = async user => {
    setResponse({
      progress: true
    });

    let params = {
      UserPoolId: process.env.REACT_APP_AWS_USER_POOLS_ID,
      Username: user.username
    };

    const cognito = new AWS.CognitoIdentityServiceProvider();
    cognito.adminDeleteUser(params, function(error, data) {
      if (error) {
        setResponse({ ...error, progress: false });
      } else {
        getUsers();
        setResponse({ ...data, progress: false });
      }
    });
  };

  const transformResults = users => {
    const transformedArray = users.map(user => {
      const transformedUser = {
        username: user.Username,
        id: user.Username,
        status: user.UserStatus
      };

      const userEmail = user.Attributes.find(obj => obj.Name === 'email');
      const userPhone = user.Attributes.find(
        obj => obj.Name === 'phone_number'
      );

      user.Attributes.forEach(attribute => {
        let key = attribute.Name;

        if (attribute.Name.includes('custom:')) {
          key = attribute.Name.split(':')[1];
        }

        transformedUser[key] = attribute.Value;
      });

      transformedUser.contact = userEmail ? userEmail.Value : userPhone.Value;

      return transformedUser;
    });

    setTransformedUsers(transformedArray);
  };

  useEffect(() => {
    getUsers();
    getGroups();
  }, []);

  useEffect(() => {
    transformResults(users);
  }, [users]);

  return (
    <UsersContext.Provider
      value={{
        transformedUsers,
        createUser,
        editUser,
        deleteUser,
        groups,
        userGroups,
        getUserGroups,
        changeUserGroup,
        removeUserGroup,
        response,
        setResponse,
        users,
        getUsers
      }}
    >
      {props.children}
    </UsersContext.Provider>
  );
};

export default UsersContextProvider;
