import React, { useContext } from 'react';
import { Card } from 'antd';
import styled from 'styled-components';
import { PieChart, Pie, Tooltip, Cell } from 'recharts';
import { AnalyticsContext } from '../../contexts/AnalyticsContext';
import moment from 'moment';

const StyledCard = styled(Card)`
  min-height: 265px;

  p {
    color: rgba(0, 0, 0, 0.45);
    margin-bottom: 0.5rem;
  }

  span {
    color: rgba(0, 0, 0, 0.45);
    font-size: 14px;
    font-weight: 400;
  }

  h4 {
    span {
      cursor: default;
      margin-left: 0.5rem;
    }
  }
`;

const StyledTitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: no-wrap;
`;

const StyledOuterWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
`;

const StyledInnerWrapper = styled.div`
  margin-right: 2rem;
  margin-bottom: 1rem;

  p {
    margin-bottom: 1rem;
  }
`;

const StyledRefreshDate = styled.p`
  font-size: 10px;
`;

const StyledActiveIndicator = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 4px;
  background-color: #001529;
`;

const StyledRechartsTooltip = styled.div`
  background-color: #fff;
  padding: 12px 16px;
  background-clip: padding-box;
  border-radius: 4px;
  -webkit-box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  -webkit-box-shadow: 0 0 8px rgba(0, 0, 0, 0.15);
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.15);
  display: flex;
  flex-wrap: no-wrap;

  p {
    color: rgba(0, 0, 0, 0.45);
    margin-bottom: 0;
  }
`;

const COLORS = ['#f0f2f5', '#1890ff'];

const CampaignsOverview = (props) => {
  const { active } = props;
  // Prepare data returned from the api
  const { benefitWidgetCampaigns } = useContext(AnalyticsContext);

  const widgetData = benefitWidgetCampaigns.data || {};
  const couponsBreakdown = {
    normal: widgetData.coupons ? widgetData.coupons.normal : {},
    unique_limited: widgetData.coupons ? widgetData.coupons.unique_limited : {},
    unique_unlimited: widgetData.coupons
      ? widgetData.coupons.unique_unlimited
      : {}
  };

  const data01 = [
    {
      name: 'Active',
      value:
        couponsBreakdown.normal.total - couponsBreakdown.normal.redeemed || 0
    },
    { name: 'Redeemed', value: couponsBreakdown.normal.redeemed || 0 }
  ];

  const data02 = [
    {
      name: 'Active',
      value:
        couponsBreakdown.unique_limited.total -
          couponsBreakdown.unique_limited.redeemed || 0
    },
    { name: 'Redeemed', value: couponsBreakdown.unique_limited.redeemed || 0 }
  ];

  const CustomTooltip = ({ active, payload, label }) => {
    if (active) {
      return (
        <StyledRechartsTooltip>
          <p>{`${payload[0].name}:`}&nbsp;</p>
          <p>{payload[0].value}</p>
        </StyledRechartsTooltip>
      );
    }

    return null;
  };

  return (
    <StyledCard>
      {active ? <StyledActiveIndicator /> : null}
      <StyledTitleWrapper>
        <p>Active campaigns</p>
        <StyledRefreshDate>
          Refreshed<br></br>
          {benefitWidgetCampaigns.timestamp
            ? moment(benefitWidgetCampaigns.timestamp).toLocaleString()
            : 'no data'}
        </StyledRefreshDate>
      </StyledTitleWrapper>
      <h2 className="ant-typography">{widgetData.total_campaings || 0}</h2>
      <StyledOuterWrapper>
        <StyledInnerWrapper>
          <p>Normal</p>
          <PieChart width={60} height={60}>
            <Pie
              data={data01}
              dataKey="value"
              nameKey="name"
              cx="50%"
              cy="50%"
              innerRadius={20}
              outerRadius={30}
              startAngle={-270}
              paddingAngle={5}
            >
              {data01.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={COLORS[index % COLORS.length]}
                />
              ))}
            </Pie>
            <Tooltip
              content={CustomTooltip}
              allowEscapeViewBox={{ x: true, y: true }}
              wrapperStyle={{ zIndex: 1000 }}
            />
          </PieChart>
        </StyledInnerWrapper>
        <StyledInnerWrapper>
          <p>Unique limited</p>
          <PieChart width={60} height={60}>
            <Pie
              data={data02}
              dataKey="value"
              nameKey="name"
              cx="50%"
              cy="50%"
              innerRadius={20}
              outerRadius={30}
              startAngle={-270}
              paddingAngle={5}
            >
              {data02.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={COLORS[index % COLORS.length]}
                />
              ))}
            </Pie>
            <Tooltip
              content={CustomTooltip}
              allowEscapeViewBox={{ x: true, y: true }}
              wrapperStyle={{ zIndex: 1000 }}
            />
          </PieChart>
        </StyledInnerWrapper>
        <StyledInnerWrapper>
          <p>Unique unlimited</p>
          <div>
            <h3>{couponsBreakdown.unique_unlimited.total || 0} coupons</h3>
            <h3>{couponsBreakdown.unique_unlimited.redeemed || 0} redeems</h3>
          </div>
        </StyledInnerWrapper>
      </StyledOuterWrapper>
    </StyledCard>
  );
};

export default CampaignsOverview;
