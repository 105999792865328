import React, { useContext } from 'react';
import { Layout, Menu, Icon } from 'antd';
import styled from 'styled-components';
import logo from '../../img/home-united-logo-white.png';
import { Link, withRouter } from 'react-router-dom';

import { APP_NAME } from '../../consts/globals';

import { AdminContext } from '../../contexts/AdminContext';

const StyledSider = styled(Layout.Sider)`
  box-shadow: 2px 0 6px rgba(0, 21, 41, 0.35);
`;

const StyledLogo = styled.div`
  padding: 1.5rem 1.5rem 2.5rem 1.5rem;

  img {
    height: 40px;
  }
`;

const Sidebar = props => {
  const { userRole } = useContext(AdminContext);
  const { location } = props;

  return (
    <StyledSider width="256">
      <StyledLogo>
        <Link to="/">
          <img src={logo} alt="home united logo" />
        </Link>
      </StyledLogo>

      {(() => {
        switch (userRole) {
          case `${APP_NAME}-admin`:
            return (
              <Menu
                theme="dark"
                selectedKeys={[location.pathname]}
                mode="inline"
              >
                <Menu.Item key="/users">
                  <Link to="/users">
                    <Icon type="team" />
                    <span>Users</span>
                  </Link>
                </Menu.Item>
                <Menu.Item key="/countries">
                  <Link to="/countries">
                    <Icon type="flag" />
                    <span>Countries</span>
                  </Link>
                </Menu.Item>
                <Menu.Item key="/cities">
                  <Link to="/cities">
                    <Icon type="global" />
                    <span>Cities</span>
                  </Link>
                </Menu.Item>
                <Menu.Item key="/merchants">
                  <Link to="/merchants">
                    <Icon type="skin" />
                    <span>Merchants</span>
                  </Link>
                </Menu.Item>
                <Menu.Item key="/communities">
                  <Link to="/communities">
                    <Icon type="coffee" />
                    <span>Communities</span>
                  </Link>
                </Menu.Item>
                <Menu.Item key="/redeem-locations">
                  <Link to="/redeem-locations">
                    <Icon type="shopping" />
                    <span>Redeem locations</span>
                  </Link>
                </Menu.Item>
                <Menu.Item key="/campaigns">
                  <Link to="/campaigns">
                    <Icon type="percentage" />
                    <span>Campaigns</span>
                  </Link>
                </Menu.Item>
                <Menu.Item key="/coupons">
                  <Link to="/coupons">
                    <Icon type="barcode" />
                    <span>Coupons</span>
                  </Link>
                </Menu.Item>
                <Menu.Item key="/news">
                  <Link to="/news">
                    <Icon type="file-text" />
                    <span>News</span>
                  </Link>
                </Menu.Item>
                <Menu.Item key="/membership-plans">
                  <Link to="/membership-plans">
                    <Icon type="schedule" />
                    <span>Membership Plans</span>
                  </Link>
                </Menu.Item>
                <Menu.Item key="/offers">
                  <Link to="/offers">
                    <Icon type="calculator" />
                    <span>Offers</span>
                  </Link>
                </Menu.Item>
                <Menu.Item key="/invoices">
                  <Link to="/invoices">
                    <Icon type="euro" />
                    <span>Invoices</span>
                  </Link>
                </Menu.Item>
                <Menu.Item key="/legal">
                  <Link to="/legal">
                    <Icon type="file-done" />
                    <span>Legal</span>
                  </Link>
                </Menu.Item>
              </Menu>
            );

          case `${APP_NAME}-editor`:
            return (
              <Menu
                theme="dark"
                selectedKeys={[location.pathname]}
                mode="inline"
              >
                <Menu.Item key="/countries">
                  <Link to="/countries">
                    <Icon type="flag" />
                    <span>Countries</span>
                  </Link>
                </Menu.Item>
              </Menu>
            );

          default:
            return (
              <Menu
                theme="dark"
                selectedKeys={[location.pathname]}
                mode="inline"
              >
                <Menu.Item key="/countries">
                  <Link to="/countries">
                    <Icon type="flag" />
                    <span>Countries</span>
                  </Link>
                </Menu.Item>
              </Menu>
            );
        }
      })()}
    </StyledSider>
  );
};

export default withRouter(Sidebar);
