import React, { useContext } from 'react';
import { Card } from 'antd';
import styled from 'styled-components';
import { AnalyticsContext } from '../../contexts/AnalyticsContext';
import moment from 'moment';

const StyledCard = styled(Card)`
  min-height: 265px;

  p {
    color: rgba(0, 0, 0, 0.45);
    margin-bottom: 0.5rem;
  }

  span {
    color: rgba(0, 0, 0, 0.45);
    font-size: 14px;
    font-weight: 400;
  }

  h4 {
    span {
      cursor: default;
      margin-left: 0.5rem;
    }
  }
`;

const StyledTitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: no-wrap;
`;

const StyledOuterWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
`;

const StyledInnerWrapper = styled.div`
  margin-right: 4rem;
`;

const StyledActiveIndicator = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 4px;
  background-color: #001529;
`;

const StyledRefreshDate = styled.p`
  font-size: 10px;
`;

const UsersOverview = (props) => {
  const { active } = props;

  // Prepare data returned from the api
  const { benefitWidgetUsers } = useContext(AnalyticsContext);
  const widgetData = benefitWidgetUsers.data || {};

  return (
    <StyledCard>
      {active ? <StyledActiveIndicator /> : null}
      <StyledTitleWrapper>
        <p>Total users</p>
        <StyledRefreshDate>
          Refreshed<br></br>
          {benefitWidgetUsers.timestamp
            ? moment(benefitWidgetUsers.timestamp).toLocaleString()
            : 'no data'}
        </StyledRefreshDate>
      </StyledTitleWrapper>
      <h2 className="ant-typography">{widgetData.total_users || 0}</h2>
      <StyledOuterWrapper>
        <StyledInnerWrapper>
          <p>Active membership</p>
          <h4 className="ant-typography">
            {widgetData.active_memberships || 0}
          </h4>
        </StyledInnerWrapper>
        <div>
          <p>Used coupons this month</p>
          <h4 className="ant-typography">
            {widgetData.total_used_coupon_this_month || 0}
          </h4>
        </div>
      </StyledOuterWrapper>
    </StyledCard>
  );
};

export default UsersOverview;
