import React, { useContext, useState, Fragment } from 'react';
import styled from 'styled-components';
import { Table, Icon, Button, Divider } from 'antd';

import { CitiesContext } from '../../contexts/CitiesContext';
import CreateCity from './CreateCity';
import EditCity from './EditCity';
import DeleteCity from './DeleteCity';

const StyledButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 1rem;
`;

const StyledButton = styled(Button)`
  margin-right: 0.5rem;
`;

const StyledIcon = styled.span`
  cursor: pointer;
`;

const CitiesList = () => {
  const { transformedCities } = useContext(CitiesContext);
  const [modalVisible, setModalVisible] = useState(false);
  const [modal, setModal] = useState('');
  const [selectedCity, setSelectedCity] = useState({});

  const cities = transformedCities.map(city => {
    const cityName = city.name
      .split(' ')
      .map(w => w.substring(0, 1).toUpperCase() + w.substring(1))
      .join(' ');

    const transformedCity = {
      ...city,
      name: cityName
    };

    return transformedCity;
  });

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      sorter: (a, b) => a.name.localeCompare(b.name),
      sortDirections: ['descend', 'ascend']
    },
    {
      title: 'ID',
      dataIndex: 'id',
      sorter: (a, b) => a.id - b.id,
      sortDirections: ['descend', 'ascend']
    },
    {
      title: 'Postal code',
      dataIndex: 'postal_code'
    },
    {
      title: 'Country',
      dataIndex: 'country.name',
      sorter: (a, b) => a.country.name.localeCompare(b.country.name),
      sortDirections: ['descend', 'ascend']
    },
    {
      title: 'Action',
      render: city => (
        <span>
          <StyledIcon
            onClick={() => {
              setModalVisible(true);
              setModal('edit');
              setSelectedCity(city);
            }}
          >
            <Icon type="edit" />
          </StyledIcon>
          <Divider type="vertical" />
          <StyledIcon
            onClick={() => {
              setModalVisible(true);
              setModal('delete');
              setSelectedCity(city);
            }}
          >
            <Icon type="delete" />
          </StyledIcon>
        </span>
      )
    }
  ];

  return (
    <Fragment>
      <StyledButtonWrapper>
        <StyledButton
          shape="circle"
          icon="plus"
          type="primary"
          onClick={() => {
            setModalVisible(true);
            setModal('create');
          }}
        ></StyledButton>
      </StyledButtonWrapper>
      <Table
        columns={columns}
        rowKey={city => city.id}
        dataSource={cities}
        pagination={{ pageSize: 10 }}
        scroll={{ x: 'max-content' }}
      />
      <CreateCity
        modalVisible={modalVisible}
        setModalVisible={setModalVisible}
        modal={modal}
      />
      <EditCity
        modalVisible={modalVisible}
        setModalVisible={setModalVisible}
        modal={modal}
        selectedCity={selectedCity}
      />
      <DeleteCity
        modalVisible={modalVisible}
        setModalVisible={setModalVisible}
        modal={modal}
        selectedCity={selectedCity}
      />
    </Fragment>
  );
};

export default CitiesList;
