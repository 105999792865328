import React, { useContext, useEffect, useState } from 'react';
import { Modal, Form, Select } from 'antd';

import { CouponsContext } from '../../contexts/CouponsContext';
import { CampaignsContext } from '../../contexts/CampaignsContext';

const { Option } = Select;

const ExportCouponsForm = props => {
  const { exportCoupons, response, setResponse } = useContext(CouponsContext);
  const { campaigns } = useContext(CampaignsContext);
  const { modalVisible, setModalVisible, modal } = props;
  const [validationError, setValidationError] = useState({});
  const [loading, setLoading] = useState(false);

  const { getFieldDecorator, getFieldError } = props.form;

  const campaignError = getFieldError('campaign') || validationError.campaign;

  const closeModal = () => {
    setValidationError({});
    setModalVisible(false);
    props.form.resetFields();
  };

  const handleOk = () => {
    setLoading(true);

    props.form.validateFields((error, values) => {
      if (error) {
        setLoading(false);
        return;
      }

      exportCoupons(values);
    });
  };

  const handleCancel = () => {
    setResponse({});
    setLoading(false);
    closeModal();
  };

  const handleSelect = () => {
    const currentValidation = { ...validationError };

    currentValidation.campaign = undefined;

    if (validationError) {
      setValidationError(currentValidation);
    }
  };

  useEffect(() => {
    if (response.errors) {
      setValidationError(response.errors);
    } else {
      setValidationError({});
    }

    if (response.message === 'Success') {
      closeModal();
    }

    if (Object.entries(response).length !== 0) {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [response]);

  if (modal === 'export') {
    return (
      <Modal
        title={`Export coupons`}
        visible={modalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        confirmLoading={loading}
      >
        <Form layout="vertical" onSubmit={handleOk}>
          <Form.Item
            label="Campaign"
            validateStatus={campaignError ? 'error' : ''}
            help={campaignError || ''}
          >
            {getFieldDecorator('campaign', {
              rules: [{ required: true, message: 'Please select a campaign!' }],
              onChange: e => handleSelect(e)
            })(
              <Select
                name="campaign"
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
                placeholder="Select campaign"
                style={{ width: 200 }}
              >
                {campaigns.map(campaign => {
                  return (
                    <Option key={campaign.id} value={campaign.id}>
                      {`${campaign.name}`}
                    </Option>
                  );
                })}
              </Select>
            )}
          </Form.Item>
        </Form>
      </Modal>
    );
  } else {
    return null;
  }
};

const ExportCoupons = Form.create()(ExportCouponsForm);

export default ExportCoupons;
