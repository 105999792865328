import React, { useState, useContext, useEffect } from 'react';
import { Row, Col } from 'antd';
import styled from 'styled-components';

import { AnalyticsContext } from '../../contexts/AnalyticsContext';
import CampaignsOverview from './CampaignsOverview';
import IncomeOverview from './IncomeOverview';
import UsersOverview from './UsersOverview';
import CampaignsDetails from './CampaignsDetails';
import IncomeDetails from './IncomeDetails';
import UsersDetails from './UsersDetails';

const AnalitycsWrapper = styled.div`
  position: relative;
  z-index: 2;
`

const StyledOverviewWrapper = styled.div`
  cursor: pointer;
  margin-bottom: 1rem;
`;

const StyledDetailsWrapper = styled.div`
  margin-top: 2rem;
`;

const Analytics = () => {
  const { getCouponsStats, getUsersStats, getIncomeStats } = useContext(
    AnalyticsContext
  );
  const [selectedAnalytic, setSelectedAnalytic] = useState('campaigns');

  useEffect(() => {
    if (selectedAnalytic === 'campaigns') {
      getCouponsStats();
    } else if (selectedAnalytic === 'income') {
      getIncomeStats();
    } else if (selectedAnalytic === 'users') {
      getUsersStats();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedAnalytic]);

  return (
    <AnalitycsWrapper>
      <Row gutter={12}>
        <Col xs={24} sm={24} md={24} lg={24} xl={8}>
          <StyledOverviewWrapper
            onClick={() => setSelectedAnalytic('campaigns')}
          >
            <CampaignsOverview
              active={selectedAnalytic === 'campaigns' ? true : false}
            />
          </StyledOverviewWrapper>
        </Col>
        <Col xs={24} sm={24} md={24} lg={24} xl={8}>
          <StyledOverviewWrapper onClick={() => setSelectedAnalytic('income')}>
            <IncomeOverview
              active={selectedAnalytic === 'income' ? true : false}
            />
          </StyledOverviewWrapper>
        </Col>
        <Col xs={24} sm={24} md={24} lg={24} xl={8}>
          <StyledOverviewWrapper onClick={() => setSelectedAnalytic('users')}>
            <UsersOverview
              active={selectedAnalytic === 'users' ? true : false}
            />
          </StyledOverviewWrapper>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <StyledDetailsWrapper>
            {selectedAnalytic === 'campaigns' ? (
              <CampaignsDetails />
            ) : selectedAnalytic === 'income' ? (
              <IncomeDetails />
            ) : selectedAnalytic === 'users' ? (
              <UsersDetails />
            ) : null}
          </StyledDetailsWrapper>
        </Col>
      </Row>
    </AnalitycsWrapper>
  );
};

export default Analytics;
