import React, { useContext, useState, Fragment } from 'react';
import styled from 'styled-components';
import { Table, Input, Button, Icon, Divider } from 'antd';
import moment from 'moment';

import { InvoicesContext } from '../../contexts/InvoicesContext';
import SingleInvoice from './SingleInvoice';

const StyledButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 1rem;
  height: 32px;
`;

// const StyledButton = styled(Button)`
//   margin-right: 0.5rem;
// `;

// const StyledIcon = styled.span`
//   cursor: pointer;
// `;

const StyledSpan = styled.span`
  color: #1890ff;
  cursor: pointer;
`;

const InvoicesList = () => {
  const { invoices, viewPDF, downloadPDF } = useContext(
    InvoicesContext
  );
  // const [modalVisible, setModalVisible] = useState(false);
  // const [modal, setModal] = useState('');
  const [drawer, setDrawer] = useState(false);
  const [selectedInvoice, setSelectedInvoice] = useState({});

  let searchInput;

  const getColumnSearchProps = dataIndex => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon="search"
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button
          onClick={() => handleReset(clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) => {
      if (record[dataIndex]) {
        return record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase());
      } else {
        return null;
      }
    },
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => searchInput.select());
      }
    }
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
  };

  const handleReset = clearFilters => {
    clearFilters();
  };

  const columns = [
    {
      title: 'Number',
      key: 'invoice_number',
      sorter: (a, b) => a.invoice_number.localeCompare(b.invoice_number),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('invoice_number'),
      render: invoice => (
        <StyledSpan
          onClick={() => {
            setSelectedInvoice(invoice);
            setDrawer(true);
          }}
        >
          {invoice.invoice_number}
        </StyledSpan>
      )
    },
    {
      title: 'User',
      dataIndex: 'billing_email',
      key: 'billing_email',
      ...getColumnSearchProps('billing_email')
    },
    {
      title: 'Issued at',
      dataIndex: 'issued_at',
      key: 'issued_at',
      sorter: (a, b) => moment(a.issued_at) - moment(b.issued_at),
      sortDirections: ['descend', 'ascend'],
      defaultSortOrder: 'descend'
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      sorter: (a, b) => a.status.localeCompare(b.status),
      sortDirections: ['descend', 'ascend']
    },
    {
      title: 'PDF',
      render: invoice => (
        <div>
          <StyledSpan onClick={e => handleViewPDF(e, invoice.id)}>
            View
          </StyledSpan>
          <Divider type="vertical" />
          <StyledSpan onClick={e => handleDownloadPDF(e, invoice.id)}>
            Download
          </StyledSpan>
        </div>
      )
    }
  ];

  const handleViewPDF = (e, id) => {
    viewPDF('invoice', id);
  };
  const handleDownloadPDF = (e, id) => {
    downloadPDF('invoice', id);
  };

  return (
    <Fragment>
      <StyledButtonWrapper>
        {/* <StyledButton
          shape="circle"
          icon="plus"
          type="primary"
          onClick={() => {
            setModalVisible(true);
            setModal('create');
          }}
        ></StyledButton> */}
      </StyledButtonWrapper>
      <Table
        columns={columns}
        dataSource={invoices}
        pagination={{ pageSize: 10 }}
        scroll={{ x: 'max-content' }}
        rowKey={invoice => invoice.id}
      />
      <SingleInvoice
        drawer={drawer}
        setDrawer={setDrawer}
        selectedInvoice={selectedInvoice}
        setSelectedInvoice={setSelectedInvoice}
      />
    </Fragment>
  );
};

export default InvoicesList;
