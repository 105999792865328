import React, { Fragment } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
// import { Layout } from 'antd';
// import styled from 'styled-components';

import Home from '../components/Home';
import PrivateRoute from './auth/PrivateRoute';

import AuthContextProvider from '../contexts/AuthContext';
import AdminContextProvider from '../contexts/AdminContext';

// const { Content } = Layout;

// const StyledLayout = styled(Content)`
//   display: flex;
//   justify-content: center;
//   margin: 5% auto 50px;
// `;

const Main = props => {
  const { authState } = props;
  return (
    <Fragment>
      {authState === 'signedIn' ? (
        <Router>
          <AuthContextProvider>
            <AdminContextProvider>
              <PrivateRoute path="/">
                <Home />
              </PrivateRoute>
            </AdminContextProvider>
          </AuthContextProvider>
        </Router>
      ) : null}
    </Fragment>
  );
};

export default Main;
