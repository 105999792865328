import React, { useContext, useEffect, useState } from 'react';
import { Modal, Form, Input, Select } from 'antd';

import { MembershipsContext } from '../../contexts/MembershipsContext';

const { Option } = Select;

const CreateMembershipForm = props => {
  const {
    membershipPlans,
    createMembership,
    response,
    setResponse
  } = useContext(MembershipsContext);
  const { modalVisible, setModalVisible, modal } = props;
  const [validationError, setValidationError] = useState({});
  const [loading, setLoading] = useState(false);

  const { getFieldDecorator, getFieldError } = props.form;

  const membershipPlanError =
    getFieldError('membership_plan_id') || validationError.membership_plan_id;
  const emailError = getFieldError('email') || validationError.email;
  const firstNameError =
    getFieldError('first_name') || validationError.first_name;
  const lastNameError = getFieldError('last_name') || validationError.last_name;
  const phoneError = getFieldError('phone') || validationError.phone;
  const countryError = getFieldError('country') || validationError.country;
  const cityError = getFieldError('city') || validationError.city;
  const zipError = getFieldError('zip') || validationError.zip;
  const addressError = getFieldError('address') || validationError.address;

  const businessNameError =
    getFieldError('business_name') || validationError.business_name;
  const businessCountryError =
    getFieldError('business_country') || validationError.business_country;
  const businessCityError =
    getFieldError('business_city') || validationError.business_city;
  const businessZipError =
    getFieldError('business_zip') || validationError.business_zip;
  const businessAddressError =
    getFieldError('business_address') || validationError.business_address;
  const businessVatError =
    getFieldError('business_vat') || validationError.business_vat;

  const closeModal = () => {
    setValidationError({});
    setModalVisible(false);
    props.form.resetFields();
  };

  const handleOk = () => {
    setLoading(true);

    props.form.validateFields((error, fieldsValue) => {
      if (error) {
        setLoading(false);
        return;
      }

      createMembership(fieldsValue);
    });
  };

  const handleCancel = () => {
    setResponse({});
    setLoading(false);
    closeModal();
  };

  const handleChange = e => {
    const currentValidation = { ...validationError };

    const eventTarget = e.target;

    if (eventTarget) {
      const fieldName = eventTarget.name;
      currentValidation[fieldName] = undefined;
    }

    if (validationError) {
      setValidationError(currentValidation);
    }
  };

  const handleMembershipPlan = () => {
    const currentValidation = { ...validationError };

    currentValidation.membership_plan_id = undefined;

    if (validationError) {
      setValidationError(currentValidation);
    }
  };

  useEffect(() => {
    if (modal === 'create') {
      if (response.errors) {
        setValidationError(response.errors);
      } else {
        setValidationError({});
      }

      if (
        response.requestFor === 'create' &&
        response.requestInProgress === false &&
        response.id
      ) {
        closeModal();
      }

      if (
        response.requestFor === 'create' &&
        response.requestInProgress === false
      ) {
        setLoading(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [response]);

  if (modal === 'create') {
    return (
      <Modal
        title={`Add new membership`}
        visible={modalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        bodyStyle={{ maxHeight: '50vh', overflowY: 'scroll' }}
        confirmLoading={loading}
      >
        <Form layout="vertical" onSubmit={handleOk}>
          <Form.Item
            validateStatus={membershipPlanError ? 'error' : ''}
            help={membershipPlanError || ''}
          >
            {getFieldDecorator('membership_plan_id', {
              onChange: () => handleMembershipPlan()
            })(
              <Select
                name="membership_plan_id"
                mode="single"
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
                placeholder="Membership plans"
              >
                {membershipPlans.map(community => {
                  return (
                    <Option key={community.id} value={community.id}>
                      {community.name}
                    </Option>
                  );
                })}
              </Select>
            )}
          </Form.Item>
          <Form.Item
            validateStatus={emailError ? 'error' : ''}
            help={emailError || ''}
          >
            {getFieldDecorator('email', {
              rules: [
                {
                  type: 'email',
                  message: 'The input is not a valid E-mail address!'
                }
              ],
              onChange: e => handleChange(e)
            })(<Input name="email" placeholder="E-mail" />)}
          </Form.Item>
          <Form.Item
            validateStatus={firstNameError ? 'error' : ''}
            help={firstNameError || ''}
          >
            {getFieldDecorator('first_name', {
              onChange: e => handleChange(e)
            })(<Input name="first_name" placeholder="First name" />)}
          </Form.Item>
          <Form.Item
            validateStatus={lastNameError ? 'error' : ''}
            help={lastNameError || ''}
          >
            {getFieldDecorator('last_name', {
              onChange: e => handleChange(e)
            })(<Input name="last_name" placeholder="Last name" />)}
          </Form.Item>
          <Form.Item
            validateStatus={phoneError ? 'error' : ''}
            help={phoneError || ''}
          >
            {getFieldDecorator('phone', {
              rules: [
                {
                  pattern: /^[+]?[0-9]{4,16}$/,
                  message: 'The input is not a valid phone number!'
                }
              ],
              onChange: e => handleChange(e)
            })(<Input name="phone" placeholder="Phone" type="tel" />)}
          </Form.Item>
          <Form.Item
            validateStatus={countryError ? 'error' : ''}
            help={countryError || ''}
          >
            {getFieldDecorator('country', {
              onChange: e => handleChange(e)
            })(<Input name="country" placeholder="Country" />)}
          </Form.Item>
          <Form.Item
            validateStatus={cityError ? 'error' : ''}
            help={cityError || ''}
          >
            {getFieldDecorator('city', {
              onChange: e => handleChange(e)
            })(<Input name="city" placeholder="City" />)}
          </Form.Item>
          <Form.Item
            validateStatus={zipError ? 'error' : ''}
            help={zipError || ''}
          >
            {getFieldDecorator('zip', {
              onChange: e => handleChange(e)
            })(<Input name="zip" placeholder="Zip" />)}
          </Form.Item>
          <Form.Item
            validateStatus={addressError ? 'error' : ''}
            help={addressError || ''}
          >
            {getFieldDecorator('address', {
              onChange: e => handleChange(e)
            })(<Input name="address" placeholder="Address" />)}
          </Form.Item>

          <h3>Business info</h3>

          <Form.Item
            validateStatus={businessNameError ? 'error' : ''}
            help={businessNameError || ''}
          >
            {getFieldDecorator('business_name', {
              onChange: e => handleChange(e)
            })(<Input name="business_name" placeholder="Business Name" />)}
          </Form.Item>
          <Form.Item
            validateStatus={businessCountryError ? 'error' : ''}
            help={businessCountryError || ''}
          >
            {getFieldDecorator('business_country', {
              onChange: e => handleChange(e)
            })(
              <Input name="business_country" placeholder="Business Country" />
            )}
          </Form.Item>
          <Form.Item
            validateStatus={businessCityError ? 'error' : ''}
            help={businessCityError || ''}
          >
            {getFieldDecorator('business_city', {
              onChange: e => handleChange(e)
            })(<Input name="business_city" placeholder="Business City" />)}
          </Form.Item>
          <Form.Item
            validateStatus={businessZipError ? 'error' : ''}
            help={businessZipError || ''}
          >
            {getFieldDecorator('business_zip', {
              onChange: e => handleChange(e)
            })(<Input name="business_zip" placeholder="Business Zip" />)}
          </Form.Item>
          <Form.Item
            validateStatus={businessAddressError ? 'error' : ''}
            help={businessAddressError || ''}
          >
            {getFieldDecorator('business_address', {
              onChange: e => handleChange(e)
            })(
              <Input name="business_address" placeholder="Business Address" />
            )}
          </Form.Item>
          <Form.Item
            validateStatus={businessVatError ? 'error' : ''}
            help={businessVatError || ''}
          >
            {getFieldDecorator('business_vat', {
              onChange: e => handleChange(e)
            })(<Input name="business_vat" placeholder="Business Vat" />)}
          </Form.Item>
        </Form>
      </Modal>
    );
  } else {
    return null;
  }
};

const CreateMembership = Form.create()(CreateMembershipForm);

export default CreateMembership;
