import React, { useState, useEffect, createContext } from 'react';
import { Auth } from 'aws-amplify';
import AWS from 'aws-sdk';

export const AuthContext = createContext();

const AuthContextProvider = props => {
  const [cognitoUser, setCognitoUser] = useState({});
  const [loading, setLoading] = useState(true);
  const [auth, setAuth] = useState(false);
  // const [signInError, setSignInError] = useState({});
  // const [signUpError, setSignUpError] = useState({});
  const [creds, setCreds] = useState({});

  // const signIn = async email => {
  //   try {
  //     setCognitoUser(await Auth.signIn(email));
  //     history.replace({ pathname: '/answer-challenge' });
  //     setSignInError({});
  //   } catch (error) {
  //     setSignInError(error);
  //   }
  // };

  const signOut = async () => {
    setAuth(false);
    await Auth.signOut();
    // history.replace({ pathname: '/sign-in' });
  };

  // const answerCustomChallenge = async answer => {
  //   try {
  //     setCognitoUser(await Auth.sendCustomChallengeAnswer(cognitoUser, answer));
  //     return isAuthenticated();
  //   } catch (error) {
  //     window.location.reload();
  //   }
  // };

  // const signUp = async values => {
  //   let params = {
  //     username: values.email,
  //     password: getRandomString(30),
  //     attributes: {
  //       email: values.email
  //     }
  //   };

  //   if (values.phone_number) {
  //     params.attributes.phone_number = values.phone_number;
  //   }

  //   try {
  //     await Auth.signUp(params);
  //     history.replace({ pathname: '/sign-in' });
  //     setSignUpError({});
  //   } catch (error) {
  //     setSignUpError(error);
  //   }
  // };

  // const getRandomString = bytes => {
  //   const randomValues = new Uint8Array(bytes);
  //   window.crypto.getRandomValues(randomValues);
  //   return Array.from(randomValues)
  //     .map(intToHex)
  //     .join('');
  // };

  // const intToHex = nr => {
  //   return nr.toString(16).padStart(2, '0');
  // };

  const isAuthenticated = async () => {
    try {
      await Auth.currentSession();
      await getCredentials();
      await getUserDetails();
      setAuth(true);

      return true;
    } catch {
      setAuth(false);

      return false;
    }
  };

  const getUserDetails = async () => {
    setCognitoUser(await Auth.currentAuthenticatedUser());
    const admin = await Auth.currentAuthenticatedUser();
    return admin;
  };

  const getCredentials = async () => {
    const creds = await Auth.currentCredentials();

    setCreds(creds);

    AWS.config.update({
      region: process.env.REACT_APP_AWS_USER_POOLS_REGION,
      accessKeyId: creds.accessKeyId,
      secretAccessKey: creds.secretAccessKey,
      sessionToken: creds.sessionToken
    });
  };

  useEffect(() => {
    (async function init() {
      setAuth(await isAuthenticated());
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AuthContext.Provider
      value={{
        auth,
        cognitoUser,
        creds,
        isAuthenticated,
        getUserDetails,
        loading,
        setLoading,
        signOut
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthContextProvider;
