import React, { useContext, useEffect, useState } from 'react';
import { Modal, Form, Select, Icon, Spin } from 'antd';
import styled from 'styled-components';

import { UsersContext } from '../../contexts/UsersContext';

const { Option } = Select;

const StyledIcon = styled(Icon)`
  cursor: pointer;
`;

const ChangeUserGroupForm = props => {
  const {
    groups,
    userGroups,
    changeUserGroup,
    removeUserGroup,
    response,
    setResponse
  } = useContext(UsersContext);
  const { modalVisible, setModalVisible, modal, selectedUser } = props;
  const [validationError, setValidationError] = useState({});
  const [loading, setLoading] = useState(false);
  const [removeLoading, setRemoveLoading] = useState(false);

  const { getFieldDecorator, getFieldError } = props.form;

  const groupError = getFieldError('group') || validationError.group;

  const sortedGroups = userGroups.sort((a, b) => {
    return a.Precedence - b.Precedence;
  });

  const closeModal = () => {
    setValidationError({});
    setModalVisible(false);
    props.form.resetFields();
  };

  const handleOk = () => {
    setLoading(true);

    props.form.validateFields((error, values) => {
      if (error) {
        setLoading(false);
        return;
      }

      values.username = selectedUser.username;

      changeUserGroup(values);
    });
  };

  const handleCancel = () => {
    setResponse({
      progress: false
    });
    setLoading(false);
    closeModal();
  };

  const handleChange = () => {
    const currentValidation = { ...validationError };

    currentValidation.group = undefined;

    if (validationError) {
      setValidationError(currentValidation);
    }
  };

  const handleRemove = (user, group) => {
    removeUserGroup(user, group);
  };

  useEffect(() => {
    if (modal === 'group') {
      if (response.code) {
        setValidationError(response);
      } else {
        setValidationError({});
      }

      if (
        response.requestFor === 'remove group' &&
        response.requestInProgress === true
      ) {
        setRemoveLoading(true);
      } else if (
        response.requestFor === 'remove group' &&
        response.requestInProgress === false
      ) {
        setRemoveLoading(false);
      }

      if (
        response.requestFor === 'change group' &&
        response.requestInProgress === false &&
        !response.code
      ) {
        closeModal();
      }

      if (
        response.requestFor === 'change group' &&
        response.requestInProgress === false
      ) {
        setLoading(false);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [response]);

  if (modal === 'group') {
    return (
      <Modal
        title={`Change ${selectedUser.username} groups`}
        visible={modalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        confirmLoading={loading}
      >
        <Spin spinning={removeLoading}>
          <p>Current groups:</p>
          <ul>
            {sortedGroups.map((group, index) => {
              return (
                <li key={group.GroupName}>
                  {group.GroupName.toUpperCase()}{' '}
                  <StyledIcon
                    type="close-circle"
                    onClick={() => {
                      handleRemove(selectedUser, group.GroupName);
                    }}
                  />
                </li>
              );
            })}
          </ul>

          <p>Add user to:</p>
          <Form layout="vertical" onSubmit={handleOk}>
            <Form.Item
              validateStatus={groupError ? 'error' : ''}
              help={groupError || ''}
            >
              {getFieldDecorator('group', {
                initialValue: userGroups[0] ? userGroups[0].GroupName : null,
                onChange: e => handleChange(e)
              })(
                <Select
                  name="group"
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  placeholder="Group"
                >
                  {groups.map(group => {
                    return (
                      <Option key={group.GroupName} value={group.GroupName}>
                        {group.GroupName}
                      </Option>
                    );
                  })}
                </Select>
              )}
            </Form.Item>
          </Form>
        </Spin>
      </Modal>
    );
  } else {
    return null;
  }
};

const ChangeUserGroup = Form.create()(ChangeUserGroupForm);

export default ChangeUserGroup;
