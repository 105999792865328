import React, { useState } from 'react';
import styled from 'styled-components';
import { Drawer, Typography, Spin } from 'antd';

const { Text } = Typography;

const StyledImageWrapper = styled.div`
  margin-bottom: 2.5rem;
`;

const StyledImage = styled.img`
  max-width: 100%;
  object-fit: cover;
`;

const SingleCommunity = props => {
  const { drawer, setDrawer, selectedCommunity, setSelectedCommunity } = props;
  const [imageLoaded, setImageLoaded] = useState(false);

  const transformedCommunity = {
    Name: selectedCommunity.name,
    'E-mail': selectedCommunity.email || '-',
    Phone: selectedCommunity.phone || '-',
    URL: selectedCommunity.url || '-',
    Address: selectedCommunity.address || '-',
    Floor: selectedCommunity.floor || '-',
    Tagline: selectedCommunity.tagline || '-',
    Pitch: selectedCommunity.pitch || '-',
    'Monthly credits': selectedCommunity.monthly_credits || '-',
    'Remaining credits': selectedCommunity.remaining_credits || '-',
    Linkedin: selectedCommunity.linkedin || '-',
    Xing: selectedCommunity.xing || '-',
    Instagram: selectedCommunity.instagram || '-',
    Facebook: selectedCommunity.facebook || '-',
    Vkontakte: selectedCommunity.vkontakte || '-',
    'News feed URL': selectedCommunity.news_feed_url || '-',
    Description: selectedCommunity.description || '-'
  };

  if (selectedCommunity.Cities && selectedCommunity.Cities.length > 0) {
    transformedCommunity.Cities = selectedCommunity.Cities.map(
      (city, index) => {
        if (index === selectedCommunity.Cities.length - 1) {
          return `${city.name} (${city.postal_code})`;
        } else {
          return `${city.name} (${city.postal_code}), `;
        }
      }
    );
  } else {
    transformedCommunity.Cities = '-';
  }

  if (selectedCommunity.Countries && selectedCommunity.Countries.length > 0) {
    transformedCommunity.Countries = selectedCommunity.Countries.map(
      (country, index) => {
        if (index === selectedCommunity.Countries.length - 1) {
          return `${country.name} (${country.iso})`;
        } else {
          return `${country.name} (${country.iso}), `;
        }
      }
    );
  } else {
    transformedCommunity.Countries = '-';
  }

  return (
    <Drawer
      width={640}
      placement="right"
      closable={false}
      onClose={() => {
        setDrawer(false);
        setTimeout(() => {
          setSelectedCommunity({});
        }, 300);
      }}
      visible={drawer}
    >
      {selectedCommunity.image ? (
        <StyledImageWrapper>
          <Spin spinning={!imageLoaded}>
            <StyledImage
              src={selectedCommunity.image}
              onLoad={() => setImageLoaded(true)}
            />
          </Spin>
        </StyledImageWrapper>
      ) : null}
      <div>
        {Object.keys(transformedCommunity).map((keyName, i) => {
          return (
            <p key={i}>
              <Text>
                {keyName}:{' '}
                <Text type="secondary">{transformedCommunity[keyName]}</Text>
              </Text>
            </p>
          );
        })}
      </div>
    </Drawer>
  );
};

export default SingleCommunity;
