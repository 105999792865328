import React, { useContext, useEffect, useState } from 'react';
import { Modal, Form, Input, InputNumber, Spin } from 'antd';

import { MembershipPlansContext } from '../../contexts/MembershipPlansContext';

const EditMembershipPlanForm = props => {
  const { editMembershipPlan, response, setResponse } = useContext(
    MembershipPlansContext
  );
  const {
    modalVisible,
    setModalVisible,
    modal,
    selectedMembershipPlan,
    setSelectedMembershipPlan
  } = props;
  const [validationError, setValidationError] = useState({});
  const [loading, setLoading] = useState(false);
  const [initialLoading, setInitialLoading] = useState(false);

  const { getFieldDecorator, getFieldError } = props.form;

  const nameError = getFieldError('name') || validationError.name;
  const descriptionError =
    getFieldError('description') || validationError.description;
  const priceError = getFieldError('price') || validationError.price;
  const daysError = getFieldError('days') || validationError.days;

  const closeModal = () => {
    setValidationError({});
    setModalVisible(false);
    setSelectedMembershipPlan({});
    props.form.resetFields();
  };

  const handleOk = () => {
    setLoading(true);

    props.form.validateFields((error, fieldsValue) => {
      if (error) {
        setLoading(false);
        return;
      }

      editMembershipPlan(fieldsValue, selectedMembershipPlan.id);
    });
  };

  const handleCancel = () => {
    setResponse({});
    setLoading(false);
    closeModal();
  };

  const handleChange = e => {
    const currentValidation = { ...validationError };

    if (validationError) {
      setValidationError(currentValidation);
    }
  };

  useEffect(() => {
    if (modal === 'edit') {
      if (response.errors) {
        setValidationError(response.errors);
      } else {
        setValidationError({});
      }

      if (
        response.requestFor === 'get single' &&
        response.requestInProgress === true
      ) {
        setInitialLoading(true);
      } else if (
        response.requestFor === 'get single' &&
        response.requestInProgress === false
      ) {
        setInitialLoading(false);
      }

      if (
        response.requestFor === 'edit' &&
        response.requestInProgress === false &&
        response.id
      ) {
        closeModal();
      }

      if (
        response.requestFor === 'edit' &&
        response.requestInProgress === false
      ) {
        setLoading(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [response]);

  if (modal === 'edit') {
    return (
      <Modal
        title={`Edit membership plan`}
        visible={modalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        bodyStyle={{ maxHeight: '50vh', overflowY: 'scroll' }}
        confirmLoading={loading}
      >
        <Spin spinning={initialLoading}>
          <Form layout="vertical" onSubmit={handleOk}>
            <Form.Item
              label="Name"
              validateStatus={nameError ? 'error' : ''}
              help={nameError || ''}
            >
              {getFieldDecorator('name', {
                initialValue: selectedMembershipPlan.name,
                rules: [
                  {
                    required: true,
                    message: 'Please input a membership plan name!'
                  }
                ],
                onChange: e => handleChange(e)
              })(<Input name="name" placeholder="Name" />)}
            </Form.Item>

            <Form.Item
              label="Description"
              validateStatus={descriptionError ? 'error' : ''}
              help={descriptionError || ''}
            >
              {getFieldDecorator('description', {
                initialValue: selectedMembershipPlan.description,
                onChange: e => handleChange(e)
              })(<Input name="description" placeholder="Description" />)}
            </Form.Item>
            <Form.Item
              label="Price"
              validateStatus={priceError ? 'error' : ''}
              help={priceError || ''}
            >
              {getFieldDecorator('price', {
                initialValue: selectedMembershipPlan.price,
                rules: [
                  {
                    required: true,
                    message: 'Please input a membership plan price!'
                  }
                ],
                onChange: e => handleChange(e)
              })(
                <InputNumber
                  name="price"
                  placeholder="Price"
                  min={0}
                  step={1}
                  precision={2}
                  formatter={value =>
                    `€ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                  }
                  parser={value => value.replace(/€\s?|(,*)/g, '')}
                  style={{ width: '100%' }}
                />
              )}
            </Form.Item>
            <Form.Item
              label="Days"
              validateStatus={daysError ? 'error' : ''}
              help={daysError || ''}
            >
              {getFieldDecorator('days', {
                initialValue: selectedMembershipPlan.days,
                rules: [
                  {
                    required: true,
                    message: 'Please input a membership plan duration!'
                  }
                ],
                onChange: e => handleChange(e)
              })(
                <InputNumber
                  name="days"
                  placeholder="Days"
                  min={0}
                  step={1}
                  style={{ width: '100%' }}
                />
              )}
            </Form.Item>
          </Form>
        </Spin>
      </Modal>
    );
  } else {
    return null;
  }
};

const EditMembershipPlan = Form.create()(EditMembershipPlanForm);

export default EditMembershipPlan;
