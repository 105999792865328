import React, { useState, useEffect, createContext, useContext } from 'react';
import { AwsClient } from 'aws4fetch';

import { AuthContext } from './AuthContext';
import { CitiesContext } from './CitiesContext';
import { API_ENDPOINT } from '../consts/globals';

export const RedeemLocationsContext = createContext();

const RedeemLocationsContextProvider = props => {
  const { cities, countries, getCities } = useContext(CitiesContext);
  const { creds } = useContext(AuthContext);
  const [redeemLocations, setRedeemLocations] = useState([]);
  const [transformedRedeemLocations, setTransformedRedeemLocations] = useState(
    []
  );
  const [response, setResponse] = useState({});

  const abortController = new AbortController();
  const signal = abortController.signal;

  const aws = new AwsClient({
    accessKeyId: creds.accessKeyId,
    secretAccessKey: creds.secretAccessKey,
    sessionToken: creds.sessionToken
  });

  const getRedeemLocations = async () => {
    try {
      const response = await aws.fetch(
        `${API_ENDPOINT}/system/redeem-locations`,
        {
          signal: signal,
          method: 'GET',
          headers: {
            'Content-type': 'application/json',
            Accept: 'application/json'
          }
        }
      );

      if (!response.ok) {
        throw Error(response.statusText);
      }

      const responseJson = await response.json();

      setRedeemLocations(responseJson.data);
    } catch (error) {
      console.log(error);
    }
  };

  const createRedeemLocation = async redeemLocation => {
    setResponse({});

    try {
      const response = await aws.fetch(
        `${API_ENDPOINT}/system/redeem-locations`,
        {
          method: 'POST',
          headers: {
            'Content-type': 'application/json',
            Accept: 'application/json'
          },
          body: JSON.stringify(redeemLocation)
        }
      );

      const responseJson = await response.json();
      setResponse(responseJson);

      if (!response.ok) {
        throw Error(response.statusText);
      }

      getRedeemLocations();
    } catch (error) {
      console.log(error);
    }
  };

  const editRedeemLocation = async (redeemLocation, id) => {
    setResponse({});

    try {
      const response = await aws.fetch(
        `${API_ENDPOINT}/system/redeem-locations/${id}`,
        {
          method: 'PATCH',
          headers: {
            'Content-type': 'application/json',
            Accept: 'application/json'
          },
          body: JSON.stringify(redeemLocation)
        }
      );

      const responseJson = await response.json();
      setResponse(responseJson);

      if (!response.ok) {
        throw Error(response.statusText);
      }

      getRedeemLocations();
    } catch (error) {
      console.log(error);
    }
  };

  const deleteRedeemLocation = async id => {
    setResponse({});

    try {
      const response = await aws.fetch(
        `${API_ENDPOINT}/system/redeem-locations/${id}`,
        {
          method: 'DELETE',
          headers: {
            'Content-type': 'application/json',
            Accept: 'application/json'
          }
        }
      );

      const responseJson = await response.json();
      setResponse(responseJson);

      if (!response.ok) {
        throw Error(response.statusText);
      }

      getRedeemLocations();
    } catch (error) {
      console.log(error);
    }
  };

  const getQR = async id => {
    try {
      const response = await aws.fetch(
        `${API_ENDPOINT}/system/redeem-locations/${id}/qr`,
        {
          signal: signal,
          method: 'GET',
          headers: {
            'Content-type': 'application/json',
            Accept: 'application/json'
          },
          responseType: 'blob'
        }
      );

      if (!response.ok) {
        throw Error(response.statusText);
      }

      const responseJson = await response.blob();

      //Create a Blob from the PDF Stream
      const file = new Blob([responseJson], { type: 'application/pdf' });
      const fileURL = URL.createObjectURL(file);

      return fileURL;
    } catch (error) {
      console.log(error);
    }
  };

  const viewQR = async id => {
    const url = await getQR(id);
    window.open(url);
  };

  const downloadQR = async id => {
    const url = await getQR(id);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${id}`);
    link.click();
  };

  const transformResults = redeemLocations => {
    const transformedArray = redeemLocations.map(redeemLocation => {
      const redeemCity = cities.find(obj => {
        return obj.id === redeemLocation.city_id;
      });

      let redeemCountry;

      if (redeemCity) {
        redeemCountry = countries.find(obj => {
          return obj.id === redeemCity.country_id;
        });
      }

      const transformedRedeemLocation = {
        ...redeemLocation,
        city: redeemCity,
        country: redeemCountry
      };

      return transformedRedeemLocation;
    });

    setTransformedRedeemLocations(transformedArray);
  };

  useEffect(() => {
    getCities();
    getRedeemLocations();

    return function cleanup() {
      abortController.abort();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (redeemLocations.length > 0) {
      transformResults(redeemLocations);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cities, redeemLocations]);

  return (
    <RedeemLocationsContext.Provider
      value={{
        cities,
        redeemLocations,
        transformedRedeemLocations,
        getRedeemLocations,
        createRedeemLocation,
        editRedeemLocation,
        deleteRedeemLocation,
        viewQR,
        downloadQR,
        response,
        setResponse
      }}
    >
      {props.children}
    </RedeemLocationsContext.Provider>
  );
};

export default RedeemLocationsContextProvider;
