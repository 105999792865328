import React, {
  useContext,
  Fragment,
  useState,
  useRef,
  useLayoutEffect
} from 'react';
import { Row, Col } from 'antd';
import styled from 'styled-components';
import { BarChart, CartesianGrid, XAxis, YAxis, Bar, Tooltip } from 'recharts';

import { AnalyticsContext } from '../../contexts/AnalyticsContext';

const StyledWrapper = styled.div`
  padding: 1.5rem;
  background: #fff;
  position: relative;
  margin-bottom: 2rem;
  width: 100%;

  h3 {
    margin-bottom: 1.5rem;
    margin-left: 1rem;
  }
`;

const StyledRechartsTooltip = styled.div`
  background-color: #fff;
  padding: 12px 16px;
  background-clip: padding-box;
  border-radius: 4px;
  -webkit-box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  -webkit-box-shadow: 0 0 8px rgba(0, 0, 0, 0.15);
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.15);
  display: flex;
  flex-direction: column;
  flex-wrap: no-wrap;

  p {
    color: rgba(0, 0, 0, 0.45);
    margin-bottom: 0;
  }
`;

const IncomeDetails = () => {
  const { incomeStats } = useContext(AnalyticsContext);
  const [dimensions, setDimensions] = useState({});
  const graphWrapper = useRef();

  // holds the timer for setTimeout and clearInterval
  let movement_timer = null;

  // the number of ms the window size must stay the same size before the
  // dimension state variable is reset
  const RESET_TIMEOUT = 100;

  let data;

  if (incomeStats) {
    data = incomeStats.graph.data;

    data = incomeStats.graph.data.map((entry) => {
      const bar = {
        calendar_date: entry.calendar_date.substr(5),
        total_sum: entry.total_sum
      };

      return bar;
    });
  }

  const CustomTooltip = ({ active, payload, label }) => {
    if (active) {
      return (
        <StyledRechartsTooltip>
          <p>{`Date: ${payload[0].payload.calendar_date}`}</p>
          <p>{`Income: ${parseInt(payload[0].payload.total_sum).toFixed(
            2
          )} €`}</p>
        </StyledRechartsTooltip>
      );
    }

    return null;
  };

  const setGraphDimensions = () => {
    if (graphWrapper.current) {
      setDimensions({
        width: graphWrapper.current.getBoundingClientRect().width - 100
      });
    }
  };

  useLayoutEffect(() => {
    setGraphDimensions();
  }, [graphWrapper]);

  window.addEventListener('resize', () => {
    clearInterval(movement_timer);
    movement_timer = setTimeout(setGraphDimensions, RESET_TIMEOUT);
  });

  return (
    <Fragment>
      <Row gutter={24}>
        <Col xs={24} sm={24} md={24} lg={24} xl={12}>
          <StyledWrapper ref={graphWrapper}>
            <h3>Income last 30 days</h3>
            <BarChart width={dimensions.width} height={250} data={data}>
              <CartesianGrid stroke="#eee" strokeDasharray="3 3" />
              <XAxis dataKey="calendar_date" />
              <YAxis />
              <Tooltip
                content={CustomTooltip}
                allowEscapeViewBox={{ x: true, y: true }}
                wrapperStyle={{ zIndex: 1000 }}
              />
              <Bar dataKey="total_sum" fill="#1890ff" />
            </BarChart>
          </StyledWrapper>
        </Col>
      </Row>
    </Fragment>
  );
};

export default IncomeDetails;
