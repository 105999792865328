import React from 'react';

const Welcome = () => {
  return (
    <div>
      <p>Welcome to BackOffice!</p>
    </div>
  );
};

export default Welcome;
