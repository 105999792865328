import React, { useContext, useState, Fragment } from 'react';
import styled from 'styled-components';
import { Table, Button } from 'antd';

import { MembershipsContext } from '../../contexts/MembershipsContext';
import SingleMembership from './SingleMembership';
import CreateMembership from './CreateMembership';

const StyledButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 1rem;
`;

const StyledButton = styled(Button)`
  margin-right: 0.5rem;
`;

const StyledSpan = styled.span`
  color: #1890ff;
  cursor: pointer;
`;

const MembershipList = () => {
  const {
    memberships,
    getSingleMembership,
    selectedMembership,
    setSelectedMembership
  } = useContext(MembershipsContext);
  const [modalVisible, setModalVisible] = useState(false);
  const [modal, setModal] = useState('');
  const [drawer, setDrawer] = useState(false);

  const columns = [
    {
      title: 'Expires',
      key: 'expires_at',
      sorter: (a, b) => a.name.localeCompare(b.name),
      sortDirections: ['descend', 'ascend'],
      render: membership => (
        <StyledSpan
          onClick={() => {
            getSingleMembership(membership.id);
            setDrawer(true);
          }}
        >
          {membership.expires_at}
        </StyledSpan>
      )
    },
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      sorter: (a, b) => a.id - b.id,
      sortDirections: ['descend', 'ascend']
    },
    {
      title: 'Plan',
      dataIndex: 'MembershipPlan.name'
    },
    {
      title: 'Payment status',
      dataIndex: 'payment_status',
      sorter: (a, b) => a.payment_status.localeCompare(b.payment_status),
      sortDirections: ['descend', 'ascend']
    }
  ];

  return (
    <Fragment>
      <StyledButtonWrapper>
        <StyledButton
          shape="circle"
          icon="plus"
          type="primary"
          onClick={() => {
            setModalVisible(true);
            setModal('create');
          }}
        ></StyledButton>
      </StyledButtonWrapper>
      <Table
        columns={columns}
        rowKey={membership => membership.id}
        dataSource={memberships}
        pagination={{ pageSize: 10 }}
        scroll={{ x: 'max-content' }}
      />
      <CreateMembership
        modalVisible={modalVisible}
        setModalVisible={setModalVisible}
        modal={modal}
      />
      <SingleMembership
        drawer={drawer}
        setDrawer={setDrawer}
        selectedMembership={selectedMembership}
        setSelectedMembership={setSelectedMembership}
      />
    </Fragment>
  );
};

export default MembershipList;
