import React, { useState, useEffect, createContext, useContext } from 'react';
import { AwsClient } from 'aws4fetch';

import { AuthContext } from './AuthContext';
import { CommunitiesContext } from './CommunitiesContext';
import { RedeemLocationsContext } from './RedeemLocationsContext';
import { MerchantsContext } from './MerchantsContext';
import { API_ENDPOINT } from '../consts/globals';

export const CampaignsContext = createContext();

const CampaignsContextProvider = props => {
  const { cities, countries, communities, getCommunities } = useContext(
    CommunitiesContext
  );
  const { creds } = useContext(AuthContext);
  const { redeemLocations, getRedeemLocations } = useContext(
    RedeemLocationsContext
  );
  let benefit = { title: '', price_before: '', price_after: '', error: false };

  const { merchants, getMerchants } = useContext(MerchantsContext);
  const [campaigns, setCampaigns] = useState([]);
  const [selectedCampaign, setSelectedCampaign] = useState({});
  const [response, setResponse] = useState({});
  const [benefits, setBenefits] = useState([{ ...benefit }]);
  const [gallery, setGallery] = useState([]);
  const [image, setImage] = useState(undefined);

  const abortController = new AbortController();
  const signal = abortController.signal;

  const aws = new AwsClient({
    accessKeyId: creds.accessKeyId,
    secretAccessKey: creds.secretAccessKey,
    sessionToken: creds.sessionToken
  });

  const getCampaigns = async () => {
    setResponse({ requestFor: 'get all', requestInProgress: true });

    try {
      const response = await aws.fetch(`${API_ENDPOINT}/system/campaigns`, {
        signal: signal,
        method: 'GET',
        headers: {
          'Content-type': 'application/json',
          Accept: 'application/json'
        }
      });

      const responseJson = await response.json();
      setResponse({
        ...responseJson,
        requestFor: 'get all',
        requestInProgress: false
      });

      if (!response.ok) {
        throw Error(response.statusText);
      }

      setCampaigns(responseJson.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getSingleCampaign = async id => {
    setResponse({ requestFor: 'get single', requestInProgress: true });

    try {
      const response = await aws.fetch(
        `${API_ENDPOINT}/system/campaigns/${id}?include=cities,countries,communities,redeem_locations,merchant,medias`,
        {
          method: 'GET',
          headers: {
            'Content-type': 'application/json',
            Accept: 'application/json'
          }
        }
      );

      const responseJson = await response.json();
      setResponse({
        ...responseJson,
        requestFor: 'get single',
        requestInProgress: false
      });

      if (!response.ok) {
        throw Error(response.statusText);
      }
      if (responseJson.benefits && responseJson.benefits.length) {
        setBenefits(
          responseJson.benefits.map(benefit => {
            return {
              error: false,
              ...benefit
            };
          })
        );
      }

      setGallery(
        responseJson.Medias
          ? responseJson.Medias.map(media => {
              return {
                uid: media.id,
                name: media.title,
                status: 'done',
                url: media.path,
                existing: true
              };
            })
          : []
      );

      setImage(responseJson.image);

      const transformedCampaign = {
        ...responseJson,
        featured: responseJson.featured ? true : false,
        show_end_date: responseJson.show_end_date ? true : false,
        freemium: responseJson.freemium ? true : false
      };

      setSelectedCampaign(transformedCampaign);
    } catch (error) {
      console.log(error);
    }
  };

  const createCampaign = async campaign => {
    setResponse({ requestFor: 'create', requestInProgress: true });

    let image64;

    if (campaign.image) {
      image64 = new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(campaign.image[0].originFileObj);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
      });
    }

    try {
      const transformedCampaign = {
        ...campaign,
        image: await image64
      };

      const response = await aws.fetch(`${API_ENDPOINT}/system/campaigns`, {
        method: 'POST',
        headers: {
          'Content-type': 'application/json',
          Accept: 'application/json'
        },
        body: JSON.stringify(transformedCampaign)
      });

      const responseJson = await response.json();
      setResponse({
        ...responseJson,
        requestFor: 'create',
        requestInProgress: false
      });

      if (!response.ok) {
        throw Error(response.statusText);
      }

      getCampaigns();
    } catch (error) {
      console.log(error);
    }
  };

  const editCampaign = async (campaign, id) => {
    setResponse({
      requestFor: 'edit',
      requestInProgress: true
    });

    let image64;

    if (campaign.image) {
      image64 = new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(campaign.image[0].originFileObj);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
      });
    }

    try {
      const { image, ...transformedCampaign } = campaign;

      if (campaign.image) {
        transformedCampaign.image = await image64;
      }

      const response = await aws.fetch(
        `${API_ENDPOINT}/system/campaigns/${id}`,
        {
          method: 'PATCH',
          headers: {
            'Content-type': 'application/json',
            Accept: 'application/json'
          },
          body: JSON.stringify(transformedCampaign)
        }
      );

      const responseJson = await response.json();
      setResponse({
        ...responseJson,
        requestFor: 'edit',
        requestInProgress: false
      });

      if (!response.ok) {
        throw Error(response.statusText);
      }

      getCampaigns();
    } catch (error) {
      console.log(error);
    }
  };

  const deleteCampaign = async id => {
    setResponse({ requestFor: 'delete', requestInProgress: true });

    try {
      const response = await aws.fetch(
        `${API_ENDPOINT}/system/campaigns/${id}`,
        {
          method: 'DELETE',
          headers: {
            'Content-type': 'application/json',
            Accept: 'application/json'
          }
        }
      );

      const responseJson = await response.json();
      setResponse({
        ...responseJson,
        requestFor: 'delete',
        requestInProgress: false
      });

      if (!response.ok) {
        throw Error(response.statusText);
      }

      getCampaigns();
    } catch (error) {
      console.log(error);
    }
  };

  const asyncForEach = async (array, callback) => {
    for (let index = 0; index < array.length; index++) {
      await callback(array[index], index, array);
    }
  };

  const addBenefit = i => {
    benefits.splice(i + 1, 0, { ...benefit });

    setBenefits([...benefits]);
  };

  const deleteBenefit = i => {
    //don't let user delete last benefit
    if (benefits.length < 2) return;

    benefits.splice(i, 1);
    setBenefits([...benefits]);
  };

  const updateBenefit = (e, i, key) => {
    let benefit = { ...benefits[i] };
    benefit[key] = e.target.value;
    benefit.error = !validateBenefit(benefit);
    benefits.splice(i, 1, benefit);
    setBenefits([...benefits]);
  };

  const validateBenefit = benefit => {
    if (isEmptyBenefit(benefit)) return isEmptyBenefit(benefit);

    return (
      !!benefit.title.trim().length &&
      !!benefit.price_before.trim().length &&
      !!benefit.price_after.trim().length
    );
  };

  const isEmptyBenefit = benefit => {
    return (
      !benefit.title.trim().length &&
      !benefit.price_before.trim().length &&
      !benefit.price_after.trim().length
    );
  };

  useEffect(() => {
    getCommunities();
    getRedeemLocations();
    getMerchants();
    getCampaigns();

    return function cleanup() {
      abortController.abort();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <CampaignsContext.Provider
      value={{
        cities,
        countries,
        communities,
        redeemLocations,
        merchants,
        campaigns,
        getSingleCampaign,
        selectedCampaign,
        setSelectedCampaign,
        createCampaign,
        editCampaign,
        deleteCampaign,
        response,
        setResponse,
        benefit,
        benefits,
        setBenefits,
        addBenefit,
        deleteBenefit,
        updateBenefit,
        validateBenefit,
        isEmptyBenefit,
        gallery,
        setGallery,
        asyncForEach,
        image, setImage
      }}
    >
      {props.children}
    </CampaignsContext.Provider>
  );
};

export default CampaignsContextProvider;
