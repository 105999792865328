import React, { useContext, useEffect, useState } from 'react';
import { Modal, Form, Input, Select } from 'antd';

import { CitiesContext } from '../../contexts/CitiesContext';

const { Option } = Select;

const CreateCityForm = props => {
  const { countries, createCity, response, setResponse } = useContext(
    CitiesContext
  );
  const { modalVisible, setModalVisible, modal } = props;
  const [validationError, setValidationError] = useState({});
  const [loading, setLoading] = useState(false);

  const { getFieldDecorator, getFieldError } = props.form;

  const nameError = getFieldError('name') || validationError.name;
  const countryError =
    getFieldError('country_id') || validationError.country_id;
  const postalError =
    getFieldError('postal_code') || validationError.postal_code;

  const closeModal = () => {
    setValidationError({});
    setModalVisible(false);
    props.form.resetFields();
  };

  const handleOk = () => {
    setLoading(true);
    props.form.validateFields((error, values) => {
      if (error) {
        setLoading(false);
        return;
      }

      createCity(values);
    });
  };

  const handleCancel = () => {
    setResponse({});
    setLoading(false);
    closeModal();
  };

  const handleChange = e => {
    const eventTarget = e.target;
    const currentValidation = { ...validationError };

    if (eventTarget) {
      const fieldName = eventTarget.name;
      currentValidation[fieldName] = undefined;
    } else {
      currentValidation.country_id = undefined;
    }

    if (validationError) {
      setValidationError(currentValidation);
    }
  };

  useEffect(() => {
    if (response.errors) {
      setValidationError(response.errors);
    } else {
      setValidationError({});
    }

    if (response.id) {
      closeModal();
    }

    if (Object.entries(response).length !== 0) {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [response]);

  if (modal === 'create') {
    return (
      <Modal
        title={`Add new city`}
        visible={modalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        confirmLoading={loading}
      >
        <Form layout="vertical" onSubmit={handleOk}>
          <Form.Item
            label="Name"
            validateStatus={nameError ? 'error' : ''}
            help={nameError || ''}
          >
            {getFieldDecorator('name', {
              rules: [{ required: true, message: 'Please input a city name!' }],
              onChange: e => handleChange(e)
            })(<Input name="name" placeholder="Name" />)}
          </Form.Item>
          <Form.Item
            label="Postal code"
            validateStatus={postalError ? 'error' : ''}
            help={postalError || ''}
          >
            {getFieldDecorator('postal_code', {
              rules: [
                { required: true, message: 'Please input a postal code!' },
                {
                  min: 4,
                  message: 'Postal code must be between 4 and 9 characters!'
                },
                {
                  max: 9,
                  message: 'Postal code must be between 4 and 9 characters!'
                }
              ],
              onChange: e => handleChange(e)
            })(
              <Input
                name="postal_code"
                placeholder="Postal code"
                maxLength={9}
              />
            )}
          </Form.Item>
          <Form.Item
            label="Country"
            validateStatus={countryError ? 'error' : ''}
            help={countryError || ''}
          >
            {getFieldDecorator('country_id', {
              rules: [{ required: true, message: 'Please select a country!' }],
              onChange: e => handleChange(e)
            })(
              <Select
                name="country_id"
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
                placeholder="Country"
              >
                {countries.map(country => {
                  return (
                    <Option key={country.id} value={country.id}>
                      {`${country.name} (${country.iso})`}
                    </Option>
                  );
                })}
              </Select>
            )}
          </Form.Item>
        </Form>
      </Modal>
    );
  } else {
    return null;
  }
};

const CreateCity = Form.create()(CreateCityForm);

export default CreateCity;
