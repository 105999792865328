import React, { useContext, useEffect, useState } from 'react';
import { Modal, Form, Input, Upload, Button, Icon } from 'antd';

import { MerchantsContext } from '../../contexts/MerchantsContext';

const CreateMerchantForm = props => {
  const { createMerchant, response, setResponse } = useContext(
    MerchantsContext
  );
  const { modalVisible, setModalVisible, modal } = props;
  const [validationError, setValidationError] = useState({});
  const [loading, setLoading] = useState(false);

  const { getFieldDecorator, getFieldError } = props.form;

  const nameError = getFieldError('name') || validationError.name;
  const addressError = getFieldError('address') || validationError.address;
  const phoneError = getFieldError('phone') || validationError.phone;
  const websiteError = getFieldError('website') || validationError.website;
  const facebookError = getFieldError('facebook') || validationError.facebook;
  const instagramError =
    getFieldError('instagram') || validationError.instagram;
  const contactEmailError =
    getFieldError('contact_email') || validationError.contact_email;
  const contactPersonError =
    getFieldError('contact_person') || validationError.contact_person;
  const provisionError =
    getFieldError('provision_percentage') ||
    validationError.provision_percentage;
  const imageError = getFieldError('image') || validationError.image;

  const closeModal = () => {
    setValidationError({});
    setModalVisible(false);
    props.form.resetFields();
  };

  const handleOk = () => {
    setLoading(true);

    props.form.validateFields((error, values) => {
      if (error) {
        setLoading(false);
        return;
      }

      createMerchant(values);
    });
  };

  const handleCancel = () => {
    setResponse({});
    setLoading(false);
    closeModal();
  };

  const handleChange = e => {
    const currentValidation = { ...validationError };

    if (e.file) {
      currentValidation.image = undefined;
    } else {
      const eventTarget = e.target;

      if (eventTarget) {
        const fieldName = eventTarget.name;
        currentValidation[fieldName] = undefined;
      }
    }

    if (validationError) {
      setValidationError(currentValidation);
    }
  };

  useEffect(() => {
    if (response.errors) {
      setValidationError(response.errors);
    } else {
      setValidationError({});
    }

    if (response.id) {
      closeModal();
    }

    if (Object.entries(response).length !== 0) {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [response]);

  const normFile = e => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  if (modal === 'create') {
    return (
      <Modal
        title={`Add new merchant`}
        visible={modalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        bodyStyle={{ maxHeight: '50vh', overflowY: 'scroll' }}
        confirmLoading={loading}
      >
        <Form layout="vertical" onSubmit={handleOk}>
          <Form.Item
            label="Name"
            validateStatus={nameError ? 'error' : ''}
            help={nameError || ''}
          >
            {getFieldDecorator('name', {
              rules: [
                { required: true, message: 'Please input a merchant name!' }
              ],
              onChange: e => handleChange(e)
            })(<Input name="name" placeholder="Name" />)}
          </Form.Item>
          <Form.Item
            label="Address"
            validateStatus={addressError ? 'error' : ''}
            help={addressError || ''}
          >
            {getFieldDecorator('address', {
              onChange: e => handleChange(e)
            })(<Input name="address" placeholder="Address" />)}
          </Form.Item>
          <Form.Item
            label="Phone number"
            validateStatus={phoneError ? 'error' : ''}
            help={phoneError || ''}
          >
            {getFieldDecorator('phone', {
              rules: [
                {
                  pattern: /^[+]?[0-9]{4,16}$/,
                  message: 'The input is not a valid phone number!'
                }
              ],
              onChange: e => handleChange(e)
            })(<Input name="phone" placeholder="Phone" type="tel" />)}
          </Form.Item>
          <Form.Item
            label="Website URL"
            validateStatus={websiteError ? 'error' : ''}
            help={websiteError || ''}
          >
            {getFieldDecorator('website', {
              rules: [
                {
                  pattern: /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=+$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=+$,\w]+@)[A-Za-z0-9.-]+)((?:\/[+~%/.\w-_]*)?\??(?:[-+=&;%@.\w_]*)#?(?:[.!/\\\w]*))?)/,
                  message: 'The input is not a valid URL!'
                }
              ],
              onChange: e => handleChange(e)
            })(<Input name="website" placeholder="Website URL" />)}
          </Form.Item>
          <Form.Item
            label="Facebook URL"
            validateStatus={facebookError ? 'error' : ''}
            help={facebookError || ''}
          >
            {getFieldDecorator('facebook', {
              rules: [
                {
                  pattern: /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=+$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=+$,\w]+@)[A-Za-z0-9.-]+)((?:\/[+~%/.\w-_]*)?\??(?:[-+=&;%@.\w_]*)#?(?:[.!/\\\w]*))?)/,
                  message: 'The input is not a valid URL!'
                }
              ],
              onChange: e => handleChange(e)
            })(<Input name="facebook" placeholder="Facebook URL" />)}
          </Form.Item>
          <Form.Item
            label="Instagram URL"
            validateStatus={instagramError ? 'error' : ''}
            help={instagramError || ''}
          >
            {getFieldDecorator('instagram', {
              rules: [
                {
                  pattern: /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=+$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=+$,\w]+@)[A-Za-z0-9.-]+)((?:\/[+~%/.\w-_]*)?\??(?:[-+=&;%@.\w_]*)#?(?:[.!/\\\w]*))?)/,
                  message: 'The input is not a valid URL!'
                }
              ],
              onChange: e => handleChange(e)
            })(<Input name="instagram" placeholder="Instagram URL" />)}
          </Form.Item>
          <Form.Item
            label="Contact email"
            validateStatus={contactEmailError ? 'error' : ''}
            help={contactEmailError || ''}
          >
            {getFieldDecorator('contact_email', {
              rules: [
                {
                  type: 'email',
                  message: 'The input is not a valid E-mail address!'
                }
              ],
              onChange: e => handleChange(e)
            })(<Input name="contact_email" placeholder="Contact email" />)}
          </Form.Item>
          <Form.Item
            label="Contact person"
            validateStatus={contactPersonError ? 'error' : ''}
            help={contactPersonError || ''}
          >
            {getFieldDecorator('contact_person', {
              onChange: e => handleChange(e)
            })(<Input name="contact_person" placeholder="Contact person" />)}
          </Form.Item>
          <Form.Item
            label="Provision %"
            validateStatus={provisionError ? 'error' : ''}
            help={provisionError || ''}
          >
            {getFieldDecorator('provision_percentage', {
              rules: [
                {
                  pattern: /^[1-9][0-9]?$|^100$/,
                  message: 'The input is not a valid percentage number!'
                }
              ],
              onChange: e => handleChange(e)
            })(
              <Input
                name="provision_percentage"
                placeholder="Provision percentage"
              />
            )}
          </Form.Item>
          <Form.Item
            label="Image"
            validateStatus={imageError ? 'error' : ''}
            help={imageError || ''}
          >
            {getFieldDecorator('image', {
              valuePropName: 'fileList',
              getValueFromEvent: normFile,
              onChange: e => handleChange(e)
            })(
              <Upload name="image" beforeUpload={() => false}>
                <Button>
                  <Icon type="upload" /> Click to upload image
                </Button>
              </Upload>
            )}
          </Form.Item>
        </Form>
      </Modal>
    );
  } else {
    return null;
  }
};

const CreateMerchant = Form.create()(CreateMerchantForm);

export default CreateMerchant;
