import React, { useState, useEffect, createContext, useContext } from 'react';
import { AwsClient } from 'aws4fetch';

import { AuthContext } from './AuthContext';
import { API_ENDPOINT } from '../consts/globals';

export const CountriesContext = createContext();

const CountriesContextProvider = props => {
  const { creds } = useContext(AuthContext);
  const [countries, setCountries] = useState([]);
  const [response, setResponse] = useState({});

  const abortController = new AbortController();
  const signal = abortController.signal;

  const aws = new AwsClient({
    accessKeyId: creds.accessKeyId,
    secretAccessKey: creds.secretAccessKey,
    sessionToken: creds.sessionToken
  });

  const getCountries = async () => {
    try {
      const response = await aws.fetch(`${API_ENDPOINT}/system/countries`, {
        signal: signal,
        method: 'GET',
        headers: {
          'Content-type': 'application/json',
          Accept: 'application/json'
        }
      });

      if (!response.ok) {
        throw Error(response.statusText);
      }

      const responseJson = await response.json();

      setCountries(responseJson.data);
    } catch (error) {
      console.log(error);
    }
  };

  const createCountry = async country => {
    setResponse({});

    let image64;

    if (country.flag) {
      image64 = new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(country.flag[0].originFileObj);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
      });
    }

    try {
      const transformedCountry = {
        ...country,
        flag: await image64
      };

      const response = await aws.fetch(`${API_ENDPOINT}/system/countries`, {
        method: 'POST',
        headers: {
          'Content-type': 'application/json',
          Accept: 'application/json'
        },
        body: JSON.stringify(transformedCountry)
      });

      const responseJson = await response.json();
      setResponse(responseJson);

      if (!response.ok) {
        throw Error(response.statusText);
      }

      getCountries();
    } catch (error) {
      console.log(error);
    }
  };

  const editCountry = async (country, id) => {
    setResponse({});

    let image64;

    if (country.flag) {
      image64 = new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(country.flag[0].originFileObj);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
      });
    }

    try {
      const { flag, ...transformedCountry } = country;

      if (country.flag) {
        transformedCountry.flag = await image64;
      }

      const response = await aws.fetch(
        `${API_ENDPOINT}/system/countries/${id}`,
        {
          method: 'PATCH',
          headers: {
            'Content-type': 'application/json',
            Accept: 'application/json'
          },
          body: JSON.stringify(transformedCountry)
        }
      );

      const responseJson = await response.json();
      setResponse(responseJson);

      if (!response.ok) {
        throw Error(response.statusText);
      }

      getCountries();
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getCountries();

    return function cleanup() {
      abortController.abort();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <CountriesContext.Provider
      value={{
        countries,
        getCountries,
        createCountry,
        editCountry,
        response,
        setResponse
      }}
    >
      {props.children}
    </CountriesContext.Provider>
  );
};

export default CountriesContextProvider;
