import React from 'react';
import styled from 'styled-components';
import { Authenticator, Greetings } from 'aws-amplify-react';

import Main from './components/Main';

const signUpConfig = {
  hideAllDefaults: true,
  defaultCountryCode: '1',
  signUpFields: [
    {
      label: 'Email',
      key: 'username',
      required: true,
      displayOrder: 1,
      type: 'string'
    },
    {
      label: 'Password',
      key: 'password',
      required: true,
      displayOrder: 2,
      type: 'password'
    },
    {
      label: 'PhoneNumber',
      key: 'phone_number',
      required: false,
      displayOrder: 3,
      type: 'string'
    }
  ]
};

const LoginWrapper = styled.div`
  a {
    &[class^='Anchor'] {
      color: #1890ff;
    }
  }

  button {
    &[class^='Button__button'] {
      background-color: #1890ff;
    }
  }
`;

const App = () => {
  return (
    <LoginWrapper>
      <Authenticator
        signUpConfig={signUpConfig}
        usernameAttributes="Email"
        hide={[Greetings]}
      >
        <Main />
      </Authenticator>
    </LoginWrapper>
  );
};

export default App;
