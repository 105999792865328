import React, { useState, useEffect, createContext, useContext } from 'react';
import { AwsClient } from 'aws4fetch';

import { AuthContext } from './AuthContext';
import { CountriesContext } from './CountriesContext';
import { API_ENDPOINT } from '../consts/globals';

export const CitiesContext = createContext();

const CitiesContextProvider = props => {
  const { countries, getCountries } = useContext(CountriesContext);
  const { creds } = useContext(AuthContext);
  const [cities, setCities] = useState([]);
  const [transformedCities, setTransformedCities] = useState([]);
  const [response, setResponse] = useState({});

  const abortController = new AbortController();
  const signal = abortController.signal;

  const aws = new AwsClient({
    accessKeyId: creds.accessKeyId,
    secretAccessKey: creds.secretAccessKey,
    sessionToken: creds.sessionToken
  });

  const getCities = async () => {
    try {
      const response = await aws.fetch(`${API_ENDPOINT}/system/cities`, {
        signal: signal,
        method: 'GET',
        headers: {
          'Content-type': 'application/json',
          Accept: 'application/json'
        }
      });

      if (!response.ok) {
        throw Error(response.statusText);
      }

      const responseJson = await response.json();

      setCities(responseJson.data);
    } catch (error) {
      console.log(error);
    }
  };

  const createCity = async city => {
    setResponse({});

    try {
      const response = await aws.fetch(`${API_ENDPOINT}/system/cities`, {
        method: 'POST',
        headers: {
          'Content-type': 'application/json',
          Accept: 'application/json'
        },
        body: JSON.stringify(city)
      });

      const responseJson = await response.json();
      setResponse(responseJson);

      if (!response.ok) {
        throw Error(response.statusText);
      }

      getCities();
    } catch (error) {
      console.log(error);
    }
  };

  const editCity = async (city, id) => {
    setResponse({});

    try {
      const response = await aws.fetch(`${API_ENDPOINT}/system/cities/${id}`, {
        method: 'PATCH',
        headers: {
          'Content-type': 'application/json',
          Accept: 'application/json'
        },
        body: JSON.stringify(city)
      });

      const responseJson = await response.json();
      setResponse(responseJson);

      if (!response.ok) {
        throw Error(response.statusText);
      }

      getCities();
    } catch (error) {
      console.log(error);
    }
  };

  const deleteCity = async id => {
    setResponse({});

    try {
      const response = await aws.fetch(`${API_ENDPOINT}/system/cities/${id}`, {
        method: 'DELETE',
        headers: {
          'Content-type': 'application/json',
          Accept: 'application/json'
        }
      });

      const responseJson = await response.json();
      setResponse(responseJson);

      if (!response.ok) {
        throw Error(response.statusText);
      }

      getCities();
    } catch (error) {
      console.log(error);
    }
  };

  const transformResults = cities => {
    const transformedArray = cities.map(city => {
      const transformedCity = {
        ...city,
        country: countries.find(obj => {
          return obj.id === city.country_id;
        })
      };

      return transformedCity;
    });

    setTransformedCities(transformedArray);
  };

  useEffect(() => {
    getCountries();
    getCities();

    return function cleanup() {
      abortController.abort();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (countries.length > 0) {
      transformResults(cities);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countries, cities]);

  return (
    <CitiesContext.Provider
      value={{
        countries,
        cities,
        getCities,
        transformedCities,
        createCity,
        editCity,
        deleteCity,
        response,
        setResponse
      }}
    >
      {props.children}
    </CitiesContext.Provider>
  );
};

export default CitiesContextProvider;
