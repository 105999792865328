import React, { useState, useEffect, createContext, useContext } from 'react';
import { AwsClient } from 'aws4fetch';

import { AuthContext } from './AuthContext';
import { API_ENDPOINT } from '../consts/globals';

export const MembershipPlansContext = createContext();

const MembershipPlansContextProvider = props => {
  const { creds } = useContext(AuthContext);
  const [membershipPlans, setMembershipPlans] = useState([]);
  const [selectedMembershipPlan, setSelectedMembershipPlan] = useState({});
  const [response, setResponse] = useState({});

  const abortController = new AbortController();
  const signal = abortController.signal;

  const aws = new AwsClient({
    accessKeyId: creds.accessKeyId,
    secretAccessKey: creds.secretAccessKey,
    sessionToken: creds.sessionToken
  });

  const getMembershipPlans = async () => {
    setResponse({ requestFor: 'get all', requestInProgress: true });

    try {
      const response = await aws.fetch(`${API_ENDPOINT}/system/membership-plans`, {
        signal: signal,
        method: 'GET',
        headers: {
          'Content-type': 'application/json',
          Accept: 'application/json'
        }
      });

      const responseJson = await response.json();
      setResponse({
        ...responseJson,
        requestFor: 'get all',
        requestInProgress: false
      });

      if (!response.ok) {
        throw Error(response.statusText);
      }

      setMembershipPlans(responseJson.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getSingleMembershipPlan = async id => {
    setResponse({ requestFor: 'get single', requestInProgress: true });

    try {
      const response = await aws.fetch(
        `${API_ENDPOINT}/system/membership-plans/${id}?include=cities,countries,communities,redeem_locations`,
        {
          method: 'GET',
          headers: {
            'Content-type': 'application/json',
            Accept: 'application/json'
          }
        }
      );

      const responseJson = await response.json();
      setResponse({
        ...responseJson,
        requestFor: 'get single',
        requestInProgress: false
      });

      if (!response.ok) {
        throw Error(response.statusText);
      }

      setSelectedMembershipPlan(responseJson);
    } catch (error) {
      console.log(error);
    }
  };

  const createMembershipPlan = async membershipPlan => {
    setResponse({ requestFor: 'create', requestInProgress: true });

    try {
      const response = await aws.fetch(`${API_ENDPOINT}/system/membership-plans`, {
        method: 'POST',
        headers: {
          'Content-type': 'application/json',
          Accept: 'application/json'
        },
        body: JSON.stringify(membershipPlan)
      });

      const responseJson = await response.json();
      setResponse({
        ...responseJson,
        requestFor: 'create',
        requestInProgress: false
      });

      if (!response.ok) {
        throw Error(response.statusText);
      }

      getMembershipPlans();
    } catch (error) {
      console.log(error);
    }
  };

  const editMembershipPlan = async (membershipPlan, id) => {
    setResponse({
      requestFor: 'edit',
      requestInProgress: true
    });

    let image64;

    if (membershipPlan.image) {
      image64 = new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(membershipPlan.image[0].originFileObj);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
      });
    }

    try {
      const { image, ...transformedMembershipPlan } = membershipPlan;

      if (membershipPlan.image) {
        transformedMembershipPlan.image = await image64;
      }

      const response = await aws.fetch(
        `${API_ENDPOINT}/system/membership-plans/${id}`,
        {
          method: 'PATCH',
          headers: {
            'Content-type': 'application/json',
            Accept: 'application/json'
          },
          body: JSON.stringify(transformedMembershipPlan)
        }
      );

      const responseJson = await response.json();
      setResponse({
        ...responseJson,
        requestFor: 'edit',
        requestInProgress: false
      });

      if (!response.ok) {
        throw Error(response.statusText);
      }

      getMembershipPlans();
    } catch (error) {
      console.log(error);
    }
  };

  const deleteMembershipPlan = async id => {
    setResponse({ requestFor: 'delete', requestInProgress: true });

    try {
      const response = await aws.fetch(
        `${API_ENDPOINT}/system/membership-plans/${id}`,
        {
          method: 'DELETE',
          headers: {
            'Content-type': 'application/json',
            Accept: 'application/json'
          }
        }
      );

      const responseJson = await response.json();
      setResponse({
        ...responseJson,
        requestFor: 'delete',
        requestInProgress: false
      });

      if (!response.ok) {
        throw Error(response.statusText);
      }

      getMembershipPlans();
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getMembershipPlans();

    return function cleanup() {
      abortController.abort();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <MembershipPlansContext.Provider
      value={{
        membershipPlans,
        getMembershipPlans,
        getSingleMembershipPlan,
        selectedMembershipPlan,
        setSelectedMembershipPlan,
        createMembershipPlan,
        editMembershipPlan,
        deleteMembershipPlan,
        response,
        setResponse
      }}
    >
      {props.children}
    </MembershipPlansContext.Provider>
  );
};

export default MembershipPlansContextProvider;
