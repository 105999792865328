import React, { useContext, useState, Fragment } from 'react';
import styled from 'styled-components';
import { Table, Icon, Button, Divider } from 'antd';

import { MerchantsContext } from '../../contexts/MerchantsContext';
import SingleMerchant from './SingleMerchant';
import CreateMerchant from './CreateMerchant';
import EditMerchant from './EditMerchant';
import DeleteMerchant from './DeleteMerchant';

const StyledButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 1rem;
`;

const StyledButton = styled(Button)`
  margin-right: 0.5rem;
`;

const StyledIcon = styled.span`
  cursor: pointer;
`;

const StyledSpan = styled.span`
  color: #1890ff;
  cursor: pointer;
`;

const MerchantsList = () => {
  const { merchants } = useContext(MerchantsContext);
  const [modalVisible, setModalVisible] = useState(false);
  const [modal, setModal] = useState('');
  const [drawer, setDrawer] = useState(false);
  const [selectedMerchant, setSelectedMerchant] = useState({});

  const transformedMerchants = merchants.map(merchant => {
    const merchantName = merchant.name
      .split(' ')
      .map(w => w.substring(0, 1).toUpperCase() + w.substring(1).toLowerCase())
      .join(' ');

    let merchantContactPerson,
      merchantAddress,
      merchantImage,
      merchantWebsite,
      merchantContactEmail,
      merchantFacebook,
      merchantInstagram,
      merchantProvisionPercentage;

    if (merchant.contact_person) {
      merchantContactPerson = merchant.contact_person
        .split(' ')
        .map(w => w.substring(0, 1).toUpperCase() + w.substring(1))
        .join(' ');
    }

    if (merchant.address) {
      merchantAddress = merchant.address
        .split(' ')
        .map(w => w.substring(0, 1).toUpperCase() + w.substring(1))
        .join(' ');
    }

    if (merchant.image) {
      merchantImage = merchant.image;
    }

    if (merchant.website) {
      merchantWebsite = merchant.website;
    }

    if (merchant.contact_email) {
      merchantContactEmail = merchant.contact_email.toLowerCase();
    }

    if (merchant.facebook) {
      merchantFacebook = merchant.facebook;
    }

    if (merchant.instagram) {
      merchantInstagram = merchant.instagram;
    }

    if (merchant.provision_percentage) {
      merchantProvisionPercentage = merchant.provision_percentage;
    }

    const transformedMerchant = {
      name: merchantName,
      id: merchant.id,
      address: merchantAddress,
      image: merchantImage,
      phone: merchant.phone,
      contact_email: merchantContactEmail,
      contact_person: merchantContactPerson,
      provision_percentage: merchantProvisionPercentage,
      key: merchant.id,
      website: merchantWebsite,
      facebook: merchantFacebook,
      instagram: merchantInstagram
    };

    return transformedMerchant;
  });

  const columns = [
    {
      title: 'Name',
      key: 'name',
      sorter: (a, b) => a.name.localeCompare(b.name),
      sortDirections: ['descend', 'ascend'],
      render: merchant => (
        <StyledSpan
          onClick={() => {
            setSelectedMerchant(merchant);
            setDrawer(true);
          }}
        >
          {merchant.name}
        </StyledSpan>
      )
    },
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      sorter: (a, b) => a.id - b.id,
      sortDirections: ['descend', 'ascend']
    },
    {
      title: 'Address',
      dataIndex: 'address',
      key: 'address'
    },
    {
      title: 'Provision %',
      dataIndex: 'provision_percentage',
      key: 'provision_percentage',
      sorter: (a, b) => a.id - b.id,
      sortDirections: ['descend', 'ascend']
    },
    {
      title: 'Action',
      render: merchant => (
        <span>
          <StyledIcon
            onClick={() => {
              setSelectedMerchant(merchant);
              setModal('edit');
              setModalVisible(true);
            }}
          >
            <Icon type="edit" />
          </StyledIcon>
          <Divider type="vertical" />
          <StyledIcon
            onClick={() => {
              setSelectedMerchant(merchant);
              setModal('delete');
              setModalVisible(true);
            }}
          >
            <Icon type="delete" />
          </StyledIcon>
        </span>
      )
    }
  ];

  return (
    <Fragment>
      <StyledButtonWrapper>
        <StyledButton
          shape="circle"
          icon="plus"
          type="primary"
          onClick={() => {
            setModalVisible(true);
            setModal('create');
          }}
        ></StyledButton>
      </StyledButtonWrapper>
      <Table
        columns={columns}
        dataSource={transformedMerchants}
        pagination={{ pageSize: 10 }}
        scroll={{ x: 'max-content' }}
      />
      <SingleMerchant
        drawer={drawer}
        setDrawer={setDrawer}
        selectedMerchant={selectedMerchant}
        setSelectedMerchant={setSelectedMerchant}
      />
      <CreateMerchant
        modalVisible={modalVisible}
        setModalVisible={setModalVisible}
        modal={modal}
      />
      <EditMerchant
        modalVisible={modalVisible}
        setModalVisible={setModalVisible}
        modal={modal}
        selectedMerchant={selectedMerchant}
      />
      <DeleteMerchant
        modalVisible={modalVisible}
        setModalVisible={setModalVisible}
        modal={modal}
        selectedMerchant={selectedMerchant}
      />
    </Fragment>
  );
};

export default MerchantsList;
