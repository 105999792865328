import React, { useState, useEffect, createContext, useContext } from 'react';
import { AwsClient } from 'aws4fetch';

import { AuthContext } from './AuthContext';
import { API_ENDPOINT } from '../consts/globals';

export const LegalContext = createContext();

const LegalContextProvider = props => {
  const [legal, setLegal] = useState([]);
  const [response, setResponse] = useState({});
  const { creds } = useContext(AuthContext);

  const abortController = new AbortController();
  const signal = abortController.signal;

  const aws = new AwsClient({
    accessKeyId: creds.accessKeyId,
    secretAccessKey: creds.secretAccessKey,
    sessionToken: creds.sessionToken
  });

  const getLegal = async () => {
    try {
      const response = await aws.fetch(`${API_ENDPOINT}/system/legal-terms`, {
        signal: signal,
        method: 'GET',
        headers: {
          'Content-type': 'application/json',
          Accept: 'application/json'
        }
      });

      if (!response.ok) {
        throw Error(response.statusText);
      }

      const responseJson = await response.json();

      setLegal(responseJson.data);
    } catch (error) {
      console.log(error);
    }
  };

  const createLegal = async legal => {
    setResponse({});

    try {
      const response = await aws.fetch(`${API_ENDPOINT}/system/legal-terms`, {
        method: 'POST',
        headers: {
          'Content-type': 'application/json',
          Accept: 'application/json'
        },
        body: JSON.stringify(legal)
      });

      const responseJson = await response.json();
      setResponse(responseJson);

      if (!response.ok) {
        throw Error(response.statusText);
      }

      getLegal();
    } catch (error) {
      console.log(error);
    }
  };

  const editLegal = async (legal, id) => {
    setResponse({});

    try {
      const response = await aws.fetch(
        `${API_ENDPOINT}/system/legal-terms/${id}`,
        {
          method: 'PATCH',
          headers: {
            'Content-type': 'application/json',
            Accept: 'application/json'
          },
          body: JSON.stringify(legal)
        }
      );

      const responseJson = await response.json();
      setResponse(responseJson);

      if (!response.ok) {
        throw Error(response.statusText);
      }

      getLegal();
    } catch (error) {
      console.log(error);
    }
  };

  const deleteLegal = async id => {
    setResponse({});

    try {
      const response = await aws.fetch(
        `${API_ENDPOINT}/system/legal-terms/${id}`,
        {
          method: 'DELETE',
          headers: {
            'Content-type': 'application/json',
            Accept: 'application/json'
          }
        }
      );

      const responseJson = await response.json();
      setResponse(responseJson);

      if (!response.ok) {
        throw Error(response.statusText);
      }

      getLegal();
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getLegal();

    return function cleanup() {
      abortController.abort();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <LegalContext.Provider
      value={{
        legal,
        getLegal,
        createLegal,
        editLegal,
        deleteLegal,
        response,
        setResponse
      }}
    >
      {props.children}
    </LegalContext.Provider>
  );
};

export default LegalContextProvider;
