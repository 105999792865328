import React, { useState, useEffect, createContext, useContext } from 'react';
import { AwsClient } from 'aws4fetch';

import { AuthContext } from './AuthContext';
import { API_ENDPOINT } from '../consts/globals';
import { PAYMENT_ENDPOINT } from '../consts/globals';

export const AnalyticsContext = createContext();

const AnalyticsContextProvider = (props) => {
  const { creds } = useContext(AuthContext);
  const aws = new AwsClient({
    accessKeyId: creds.accessKeyId,
    secretAccessKey: creds.secretAccessKey,
    sessionToken: creds.sessionToken
  });

  const abortController = new AbortController();
  const signal = abortController.signal;

  const [benefitWidgetCampaigns, setBenefitWidgetCampaigns] = useState([]);
  const [benefitWidgetUsers, setBenefitWidgetUsers] = useState([]);
  const [benefitWidgetIncome, setBenefitWidgetIncome] = useState([]);
  const [couponsStats, setCouponsStats] = useState(null);
  const [incomeStats, setIncomeStats] = useState(null);
  const [usersStats, setUsersStats] = useState([]);

  const getBenefitWidget = async () => {
    try {
      const response = await aws.fetch(`${API_ENDPOINT}/analytics/widget`, {
        signal: signal,
        method: 'GET',
        headers: {
          'Content-type': 'application/json',
          Accept: 'application/json'
        }
      });

      if (!response.ok) {
        throw Error(response.statusText);
      }

      const responseJson = await response.json();

      setBenefitWidgetCampaigns(responseJson.campaigns || {});
      setBenefitWidgetUsers(responseJson.users || {});
    } catch (error) {
      console.log(error);
    }
  };

  const getIncomeWidget = async () => {
    try {
      const response = await aws.fetch(`${PAYMENT_ENDPOINT}/analytics/widget`, {
        signal: signal,
        method: 'GET',
        headers: {
          'Content-type': 'application/json',
          Accept: 'application/json'
        }
      });

      if (!response.ok) {
        throw Error(response.statusText);
      }

      const responseJson = await response.json();

      setBenefitWidgetIncome(responseJson.income);
    } catch (error) {
      console.log(error);
    }
  };

  const getCouponsStats = async () => {
    try {
      const response = await aws.fetch(
        `${API_ENDPOINT}/analytics/coupons-stats`,
        {
          signal: signal,
          method: 'GET',
          headers: {
            'Content-type': 'application/json',
            Accept: 'application/json'
          }
        }
      );

      if (!response.ok) {
        throw Error(response.statusText);
      }

      const responseJson = await response.json();

      setCouponsStats(responseJson);
    } catch (error) {
      console.log(error);
    }
  };

  const getIncomeStats = async () => {
    try {
      const response = await aws.fetch(
        `${PAYMENT_ENDPOINT}/analytics/income-graph`,
        {
          signal: signal,
          method: 'GET',
          headers: {
            'Content-type': 'application/json',
            Accept: 'application/json'
          }
        }
      );

      if (!response.ok) {
        throw Error(response.statusText);
      }

      const responseJson = await response.json();

      setIncomeStats(responseJson);
    } catch (error) {
      console.log(error);
    }
  };

  const getUsersStats = async () => {
    try {
      const response = await aws.fetch(
        `${API_ENDPOINT}/analytics/users-stats`,
        {
          signal: signal,
          method: 'GET',
          headers: {
            'Content-type': 'application/json',
            Accept: 'application/json'
          }
        }
      );

      if (!response.ok) {
        throw Error(response.statusText);
      }

      const responseJson = await response.json();

      setUsersStats(responseJson.top10.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getBenefitWidget();
    getIncomeWidget();

    return function cleanup() {
      abortController.abort();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AnalyticsContext.Provider
      value={{
        benefitWidgetCampaigns,
        benefitWidgetIncome,
        benefitWidgetUsers,
        couponsStats,
        getCouponsStats,
        incomeStats,
        getIncomeStats,
        usersStats,
        getUsersStats
      }}
    >
      {props.children}
    </AnalyticsContext.Provider>
  );
};

export default AnalyticsContextProvider;
