import React, { useContext, useEffect, useState } from 'react';
import { Modal, Form, Input } from 'antd';

import { UsersContext } from '../../contexts/UsersContext';

const CreateUserForm = props => {
  const { createUser, response, setResponse } = useContext(UsersContext);
  const { modalVisible, setModalVisible, modal } = props;
  const [validationError, setValidationError] = useState({});
  const [loading, setLoading] = useState(false);

  const { getFieldDecorator, getFieldError } = props.form;

  if (validationError.code === 'UsernameExistsException') {
    setValidationError({
      email: validationError.message
    });
  }

  const usernameError = getFieldError('email') || validationError.email;
  const streetError = getFieldError('street') || validationError.street;
  const zipError = getFieldError('zip') || validationError.zip;
  const cityError = getFieldError('city') || validationError.city;
  const countryError = getFieldError('country') || validationError.country;
  const businessNameError =
    getFieldError('business_name') || validationError.business_name;
  const businessCountryError =
    getFieldError('business_country') || validationError.business_country;
  const businessCityError =
    getFieldError('business_city') || validationError.business_city;
  const businessZipError =
    getFieldError('business_zip') || validationError.business_zip;
  const businessAddressError =
    getFieldError('business_address') || validationError.business_address;
  const businessVatError =
    getFieldError('business_vat') || validationError.business_vat;

  const closeModal = () => {
    setValidationError({});
    setModalVisible(false);
    props.form.resetFields();
  };

  const handleOk = () => {
    setLoading(true);

    props.form.validateFields((error, values) => {
      if (error) {
        setLoading(false);
        return;
      }

      createUser(values);
    });
  };

  const handleCancel = () => {
    setResponse({
      progress: false
    });
    setLoading(false);
    closeModal();
  };

  const handleChange = e => {
    if (e.file) {
      return;
    }

    const fieldName = e.target.name;
    const currentValidation = { ...validationError };
    currentValidation[fieldName] = undefined;
    if (validationError) {
      setValidationError(currentValidation);
    }
  };

  useEffect(() => {
    if (modal === 'create') {
      if (response.code) {
        setValidationError(response);
      } else {
        setValidationError({});
      }

      if (!response.progress && !response.code) {
        closeModal();
      }

      if (!response.progress) {
        setLoading(false);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [response]);

  if (modal === 'create') {
    return (
      <Modal
        title={`Create new user`}
        visible={modalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        confirmLoading={loading}
      >
        <Form layout="vertical" onSubmit={handleOk}>
          <Form.Item
            label="Email"
            validateStatus={usernameError ? 'error' : ''}
            help={usernameError || ''}
          >
            {getFieldDecorator('email', {
              rules: [
                {
                  type: 'email',
                  message: 'The input is not a valid E-mail address!'
                },
                {
                  required: true,
                  message: 'Please input an E-mail address!'
                }
              ],
              onChange: e => handleChange(e)
            })(<Input name="email" type="email" placeholder="Email" />)}
          </Form.Item>
          <Form.Item
            label="Street"
            validateStatus={streetError ? 'error' : ''}
            help={streetError || ''}
          >
            {getFieldDecorator('street', {
              onChange: e => handleChange(e)
            })(<Input name="street" placeholder="Street" />)}
          </Form.Item>
          <Form.Item
            label="Zip code"
            validateStatus={zipError ? 'error' : ''}
            help={zipError || ''}
          >
            {getFieldDecorator('zip', {
              onChange: e => handleChange(e)
            })(<Input name="zip" placeholder="Zip" />)}
          </Form.Item>
          <Form.Item
            label="City"
            validateStatus={cityError ? 'error' : ''}
            help={cityError || ''}
          >
            {getFieldDecorator('city', {
              onChange: e => handleChange(e)
            })(<Input name="city" placeholder="City" />)}
          </Form.Item>
          <Form.Item
            label="Country"
            validateStatus={countryError ? 'error' : ''}
            help={countryError || ''}
          >
            {getFieldDecorator('country', {
              onChange: e => handleChange(e)
            })(<Input name="country" placeholder="Country" />)}
          </Form.Item>

          <Form.Item
            label="Business name"
            validateStatus={businessNameError ? 'error' : ''}
            help={businessNameError || ''}
          >
            {getFieldDecorator('business_name', {
              onChange: e => handleChange(e)
            })(<Input name="business_name" placeholder="Business name" />)}
          </Form.Item>
          <Form.Item
            label="Business country"
            validateStatus={businessCountryError ? 'error' : ''}
            help={businessCountryError || ''}
          >
            {getFieldDecorator('business_country', {
              onChange: e => handleChange(e)
            })(
              <Input name="business_country" placeholder="Business country" />
            )}
          </Form.Item>
          <Form.Item
            label="Business city"
            validateStatus={businessCityError ? 'error' : ''}
            help={businessCityError || ''}
          >
            {getFieldDecorator('business_city', {
              onChange: e => handleChange(e)
            })(<Input name="business_city" placeholder="Business city" />)}
          </Form.Item>
          <Form.Item
            label="Business Zip code"
            validateStatus={businessZipError ? 'error' : ''}
            help={businessZipError || ''}
          >
            {getFieldDecorator('business_zip', {
              onChange: e => handleChange(e)
            })(<Input name="business_zip" placeholder="Business zip" />)}
          </Form.Item>
          <Form.Item
            label="Business address"
            validateStatus={businessAddressError ? 'error' : ''}
            help={businessAddressError || ''}
          >
            {getFieldDecorator('business_address', {
              onChange: e => handleChange(e)
            })(
              <Input name="business_address" placeholder="Business address" />
            )}
          </Form.Item>
          <Form.Item
            label="Business VAT"
            validateStatus={businessVatError ? 'error' : ''}
            help={businessVatError || ''}
          >
            {getFieldDecorator('business_vat', {
              onChange: e => handleChange(e)
            })(<Input name="business_vat" placeholder="Business vat" />)}
          </Form.Item>
        </Form>
      </Modal>
    );
  } else {
    return null;
  }
};

const CreateUser = Form.create()(CreateUserForm);

export default CreateUser;
