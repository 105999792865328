import React, { useContext, useEffect } from 'react';
import { Modal } from 'antd';
import styled from 'styled-components';

import { CountriesContext } from '../../contexts/CountriesContext';

const StyledBold = styled.span`
  margin: 0;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  line-height: 22px;
`;

const DeleteCountry = props => {
  const { deleteCountry, loading, setLoading } = useContext(CountriesContext);
  const { modalVisible, setModalVisible, modal, selectedCountry } = props;

  const closeModal = () => {
    setModalVisible(false);
  };

  const handleOk = () => {
    deleteCountry(selectedCountry);
  };

  const handleCancel = () => {
    setLoading(false);
    closeModal();
  };

  useEffect(() => {
    if (!loading) {
      closeModal();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  if (modal === 'delete') {
    return (
      <Modal
        title={`Delete ${selectedCountry.name}`}
        visible={modalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        confirmLoading={loading}
      >
        <p>
          This operation will permanently delete{' '}
          <StyledBold>{selectedCountry.name.toUpperCase()}</StyledBold>!
        </p>
        <p>Are you sure you want to continue?</p>
      </Modal>
    );
  } else {
    return null;
  }
};

export default DeleteCountry;
