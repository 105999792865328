import React, { useState, useEffect, createContext, useContext } from 'react';
import { AwsClient } from 'aws4fetch';

import { AuthContext } from './AuthContext';
import { UsersContext } from './UsersContext';
import { MembershipPlansContext } from './MembershipPlansContext';
import { API_ENDPOINT } from '../consts/globals';

export const MembershipsContext = createContext();

const MembershipsContextProvider = props => {
  const { users, getUsers } = useContext(UsersContext);
  const { membershipPlans, getMembershipPlans } = useContext(
    MembershipPlansContext
  );
  const { creds } = useContext(AuthContext);
  const [memberships, setMemberships] = useState([]);
  const [selectedMembership, setSelectedMembership] = useState({});
  const [response, setResponse] = useState({});

  const abortController = new AbortController();
  const signal = abortController.signal;

  const aws = new AwsClient({
    accessKeyId: creds.accessKeyId,
    secretAccessKey: creds.secretAccessKey,
    sessionToken: creds.sessionToken
  });

  const getMemberships = async () => {
    setResponse({ requestFor: 'get all', requestInProgress: true });

    try {
      const response = await aws.fetch(`${API_ENDPOINT}/system/memberships`, {
        signal: signal,
        method: 'GET',
        headers: {
          'Content-type': 'application/json',
          Accept: 'application/json',
          'X-User-Sub': props.match.params.uuid
        }
      });

      const responseJson = await response.json();
      setResponse({
        ...responseJson,
        requestFor: 'get all',
        requestInProgress: false
      });

      if (!response.ok) {
        throw Error(response.statusText);
      }

      setMemberships(responseJson.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getSingleMembership = async id => {
    setResponse({ requestFor: 'get single', requestInProgress: true });

    try {
      const response = await aws.fetch(
        `${API_ENDPOINT}/system/memberships/${id}?include=cities,countries,communities,redeem_locations`,
        {
          method: 'GET',
          headers: {
            'Content-type': 'application/json',
            Accept: 'application/json',
            'X-User-Sub': props.match.params.uuid
          }
        }
      );

      const responseJson = await response.json();
      setResponse({
        ...responseJson,
        requestFor: 'get single',
        requestInProgress: false
      });

      if (!response.ok) {
        throw Error(response.statusText);
      }

      setSelectedMembership(responseJson);
    } catch (error) {
      console.log(error);
    }
  };

  const createMembership = async membership => {
    setResponse({ requestFor: 'create', requestInProgress: true });

    try {
      const response = await aws.fetch(`${API_ENDPOINT}/system/memberships`, {
        method: 'POST',
        headers: {
          'Content-type': 'application/json',
          Accept: 'application/json',
          'X-User-Sub': props.match.params.uuid
        },
        body: JSON.stringify(membership)
      });

      const responseJson = await response.json();
      setResponse({
        ...responseJson,
        requestFor: 'create',
        requestInProgress: false
      });

      if (!response.ok) {
        throw Error(response.statusText);
      }

      getMemberships();
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getUsers();
    getMembershipPlans();
    getMemberships();

    return function cleanup() {
      abortController.abort();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <MembershipsContext.Provider
      value={{
        users,
        memberships,
        membershipPlans,
        getSingleMembership,
        selectedMembership,
        setSelectedMembership,
        createMembership,
        response,
        setResponse
      }}
    >
      {props.children}
    </MembershipsContext.Provider>
  );
};

export default MembershipsContextProvider;
