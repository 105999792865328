import React, { useContext, useEffect, useState } from 'react';
import { Modal, Form, Upload, Select, Button, Icon } from 'antd';

import { CouponsContext } from '../../contexts/CouponsContext';
import { CampaignsContext } from '../../contexts/CampaignsContext';

const { Option } = Select;

const ImportCouponsForm = props => {
  const { importCoupons, response, setResponse } = useContext(CouponsContext);
  const { campaigns } = useContext(CampaignsContext);
  const { modalVisible, setModalVisible, modal } = props;
  const [validationError, setValidationError] = useState({});
  const [loading, setLoading] = useState(false);

  const { getFieldDecorator, getFieldError } = props.form;

  const campaignError = getFieldError('campaign') || validationError.campaign;
  const csvError = getFieldError('csv') || validationError.number_of_coupons;

  const closeModal = () => {
    setValidationError({});
    setModalVisible(false);
    props.form.resetFields();
  };

  const handleOk = () => {
    setLoading(true);

    props.form.validateFields((error, values) => {
      if (error) {
        setLoading(false);
        return;
      }

      importCoupons(values);
    });
  };

  const handleCancel = () => {
    setResponse({});
    setLoading(false);
    closeModal();
  };

  const handleChange = e => {
    const currentValidation = { ...validationError };

    if (e.file) {
      currentValidation.csv = undefined;
    } else {
      const eventTarget = e.target;

      if (eventTarget) {
        const fieldName = eventTarget.name;
        currentValidation[fieldName] = undefined;
      }
    }

    if (validationError) {
      setValidationError(currentValidation);
    }
  };

  const handleSelect = () => {
    const currentValidation = { ...validationError };

    currentValidation.campaign = undefined;

    if (validationError) {
      setValidationError(currentValidation);
    }
  };

  const normFile = e => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  useEffect(() => {
    if (response.errors) {
      setValidationError(response.errors);
    } else {
      setValidationError({});
    }

    if (response === 'Coupons successfully imported') {
      closeModal();
    }

    if (Object.entries(response).length !== 0) {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [response]);

  if (modal === 'import') {
    return (
      <Modal
        title={`Import coupons`}
        visible={modalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        confirmLoading={loading}
      >
        <Form layout="vertical" onSubmit={handleOk}>
          <Form.Item
            label="Campaign"
            validateStatus={campaignError ? 'error' : ''}
            help={campaignError || ''}
          >
            {getFieldDecorator('campaign', {
              rules: [{ required: true, message: 'Please select a campaign!' }],
              onChange: e => handleSelect(e)
            })(
              <Select
                name="campaign"
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
                placeholder="Select campaign"
                style={{ width: 200 }}
              >
                {campaigns.map(campaign => {
                  return (
                    <Option key={campaign.id} value={campaign.id}>
                      {`${campaign.name}`}
                    </Option>
                  );
                })}
              </Select>
            )}
          </Form.Item>
          <Form.Item
            label="File"
            validateStatus={csvError ? 'error' : ''}
            help={csvError || ''}
          >
            {getFieldDecorator('csv', {
              valuePropName: 'fileList',
              getValueFromEvent: normFile,
              onChange: e => handleChange(e)
            })(
              <Upload name="csv" beforeUpload={() => false}>
                <Button>
                  <Icon type="upload" /> Click to upload .csv file
                </Button>
              </Upload>
            )}
          </Form.Item>
        </Form>
      </Modal>
    );
  } else {
    return null;
  }
};

const ImportCoupons = Form.create()(ImportCouponsForm);

export default ImportCoupons;
