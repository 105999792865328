import React, { useContext, useEffect, useState } from 'react';
import { Modal, Form, Input } from 'antd';

import { UsersContext } from '../../contexts/UsersContext';

const EditUserForm = props => {
  const { editUser, response, setResponse } = useContext(UsersContext);
  const { modalVisible, setModalVisible, modal, selectedUser } = props;
  const [validationError, setValidationError] = useState({});
  const [loading, setLoading] = useState(false);

  const { getFieldDecorator, getFieldError } = props.form;

  let usernameError;
  if (validationError.code === 'UsernameExistsException') {
    usernameError = getFieldError('username') || validationError.message;
  } else {
    usernameError = getFieldError('username');
  }
  const emailError = getFieldError('email');
  const phoneNumberError = getFieldError('phone_number');
  const streetError = getFieldError('street') || validationError.street;
  const zipError = getFieldError('zip') || validationError.zip;
  const cityError = getFieldError('city') || validationError.city;
  const countryError = getFieldError('country') || validationError.country;
  const businessNameError =
    getFieldError('business_name') || validationError.business_name;
  const businessCountryError =
    getFieldError('business_country') || validationError.business_country;
  const businessCityError =
    getFieldError('business_city') || validationError.business_city;
  const businessZipError =
    getFieldError('business_zip') || validationError.business_zip;
  const businessAddressError =
    getFieldError('business_address') || validationError.business_address;
  const businessVatError =
    getFieldError('business_vat') || validationError.business_vat;

  const closeModal = () => {
    setValidationError({});
    setModalVisible(false);
    props.form.resetFields();
  };

  const handleOk = () => {
    setLoading(true);

    props.form.validateFields((error, values) => {
      if (error) {
        setLoading(false);
        return;
      }

      editUser(values);
    });
  };

  const handleCancel = () => {
    setResponse({
      progress: false
    });
    setLoading(false);
    closeModal();
  };

  const handleChange = e => {
    if (e.file) {
      return;
    }

    const fieldName = e.target.name;
    if (fieldName === 'username') {
      const currentValidation = { ...validationError };
      currentValidation.message = undefined;
      if (validationError) {
        setValidationError(currentValidation);
      }
    }
  };

  useEffect(() => {
    if (modal === 'edit') {
      if (response.code) {
        setValidationError(response);
      } else {
        setValidationError({});
      }

      if (!response.progress && !response.code) {
        closeModal();
      }

      if (!response.progress) {
        setLoading(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [response]);

  if (modal === 'edit') {
    return (
      <Modal
        title={`Edit ${selectedUser.username}`}
        visible={modalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        confirmLoading={loading}
      >
        <Form layout="vertical" onSubmit={handleOk}>
          <Form.Item
            label="Username"
            validateStatus={usernameError ? 'error' : ''}
            help={usernameError || ''}
          >
            {getFieldDecorator('username', {
              initialValue: selectedUser.username,
              rules: [{ required: true, message: 'Please input a username!' }],
              onChange: e => handleChange(e)
            })(<Input name="username" placeholder="Username" disabled />)}
          </Form.Item>
          {selectedUser.email ? (
            <Form.Item
              label="Email"
              validateStatus={emailError ? 'error' : ''}
              help={emailError || ''}
            >
              {getFieldDecorator('email', {
                initialValue: selectedUser.email,
                rules: [
                  {
                    type: 'email',
                    message: 'The input is not a valid E-mail address!'
                  },
                  {
                    required: true,
                    message: 'Please input an E-mail address!'
                  }
                ],
                onChange: e => handleChange(e)
              })(
                <Input
                  name="email"
                  type="email"
                  placeholder="E-mail"
                  disabled
                />
              )}
            </Form.Item>
          ) : (
            <Form.Item
              label="Phone number"
              validateStatus={phoneNumberError ? 'error' : ''}
              help={phoneNumberError || ''}
            >
              {getFieldDecorator('phone_number', {
                initialValue: selectedUser.phone_number,
                rules: [
                  {
                    pattern: /^[+]?[0-9]{4,16}$/,
                    message: 'The input is not a valid phone number!'
                  },
                  {
                    required: true,
                    message: 'Please enter a phone number!'
                  }
                ],
                onChange: e => handleChange(e)
              })(<Input name="phone_number" placeholder="Phone number" />)}
            </Form.Item>
          )}

          <Form.Item
            label="Street"
            validateStatus={streetError ? 'error' : ''}
            help={streetError || ''}
          >
            {getFieldDecorator('street', {
              initialValue: selectedUser.street,
              onChange: e => handleChange(e)
            })(<Input name="street" placeholder="Street" />)}
          </Form.Item>
          <Form.Item
            label="Zip code"
            validateStatus={zipError ? 'error' : ''}
            help={zipError || ''}
          >
            {getFieldDecorator('zip', {
              initialValue: selectedUser.zip,
              onChange: e => handleChange(e)
            })(<Input name="zip" placeholder="Zip" />)}
          </Form.Item>
          <Form.Item
            label="City"
            validateStatus={cityError ? 'error' : ''}
            help={cityError || ''}
          >
            {getFieldDecorator('city', {
              initialValue: selectedUser.city,
              onChange: e => handleChange(e)
            })(<Input name="city" placeholder="City" />)}
          </Form.Item>
          <Form.Item
            label="Country"
            validateStatus={countryError ? 'error' : ''}
            help={countryError || ''}
          >
            {getFieldDecorator('country', {
              initialValue: selectedUser.country,
              onChange: e => handleChange(e)
            })(<Input name="country" placeholder="Country" />)}
          </Form.Item>

          <Form.Item
            label="Business name"
            validateStatus={businessNameError ? 'error' : ''}
            help={businessNameError || ''}
          >
            {getFieldDecorator('business_name', {
              initialValue: selectedUser.business_name,
              onChange: e => handleChange(e)
            })(<Input name="business_name" placeholder="Business name" />)}
          </Form.Item>
          <Form.Item
            label="Business country"
            validateStatus={businessCountryError ? 'error' : ''}
            help={businessCountryError || ''}
          >
            {getFieldDecorator('business_country', {
              initialValue: selectedUser.business_country,
              onChange: e => handleChange(e)
            })(
              <Input name="business_country" placeholder="Business country" />
            )}
          </Form.Item>
          <Form.Item
            label="Business city"
            validateStatus={businessCityError ? 'error' : ''}
            help={businessCityError || ''}
          >
            {getFieldDecorator('business_city', {
              initialValue: selectedUser.business_city,
              onChange: e => handleChange(e)
            })(<Input name="business_city" placeholder="Business city" />)}
          </Form.Item>
          <Form.Item
            label="Business Zip code"
            validateStatus={businessZipError ? 'error' : ''}
            help={businessZipError || ''}
          >
            {getFieldDecorator('business_zip', {
              initialValue: selectedUser.business_zip,
              onChange: e => handleChange(e)
            })(<Input name="business_zip" placeholder="Business zip" />)}
          </Form.Item>
          <Form.Item
            label="Business address"
            validateStatus={businessAddressError ? 'error' : ''}
            help={businessAddressError || ''}
          >
            {getFieldDecorator('business_address', {
              initialValue: selectedUser.business_address,
              onChange: e => handleChange(e)
            })(
              <Input name="business_address" placeholder="Business address" />
            )}
          </Form.Item>
          <Form.Item
            label="Business VAT"
            validateStatus={businessVatError ? 'error' : ''}
            help={businessVatError || ''}
          >
            {getFieldDecorator('business_vat', {
              initialValue: selectedUser.business_vat,
              onChange: e => handleChange(e)
            })(<Input name="business_vat" placeholder="Business vat" />)}
          </Form.Item>
        </Form>
      </Modal>
    );
  } else {
    return null;
  }
};

const EditUser = Form.create()(EditUserForm);

export default EditUser;
