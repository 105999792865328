import React, { useState, useEffect, useContext } from 'react';
import { Drawer, Typography, Spin } from 'antd';

import { MembershipsContext } from '../../contexts/MembershipsContext';

const { Text } = Typography;

const SingleMembership = props => {
  const { response, setResponse } = useContext(MembershipsContext);
  const {
    drawer,
    setDrawer,
    selectedMembership,
    setSelectedMembership
  } = props;
  const [loading, setLoading] = useState(true);

  const transformedMembership = {
    'E-mail': selectedMembership.email || '-',
    'First name': selectedMembership.first_name || '-',
    'Last name': selectedMembership.last_name || '-',
    Phone: selectedMembership.phone || '-',
    Country: selectedMembership.country || '-',
    City: selectedMembership.city || '-',
    Zip: selectedMembership.zip || '-',
    Address: selectedMembership.address || '-',
    'Business Name': selectedMembership.business_name || '-',
    'Business Country': selectedMembership.business_country || '-',
    'Business City': selectedMembership.business_city || '-',
    'Business Zip': selectedMembership.business_zip || '-',
    'Business Address': selectedMembership.business_address || '-',
    Price: selectedMembership.price || '-',
    Days: selectedMembership.days || '-',
    'Payment status': selectedMembership.payment_status || '-',
    Expires: selectedMembership.expires_at || '-'
  };

  if (selectedMembership.MembershipPlan) {
    transformedMembership.MembershipPlan =
      selectedMembership.MembershipPlan.name;
  } else {
    transformedMembership.MembershipPlan = '-';
  }

  useEffect(() => {
    if (drawer) {
      setLoading(true);
    }
  }, [drawer]);

  useEffect(() => {
    if (
      response.requestFor === 'get single' &&
      response.requestInProgress === false &&
      response.id
    ) {
      setLoading(false);
    }
  }, [response]);

  return (
    <Drawer
      width={640}
      placement="right"
      closable={false}
      onClose={() => {
        setDrawer(false);
        setResponse({});
        setLoading(false);
        setTimeout(() => {
          setSelectedMembership({});
        }, 300);
      }}
      visible={drawer}
    >
      <Spin spinning={loading}>
        <div>
          {Object.keys(transformedMembership).map((keyName, i) => {
            return (
              <p key={i}>
                <Text>
                  {keyName}:{' '}
                  <Text type="secondary">{transformedMembership[keyName]}</Text>
                </Text>
              </p>
            );
          })}
        </div>
      </Spin>
    </Drawer>
  );
};

export default SingleMembership;
