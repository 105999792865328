import React, { useContext, useState, Fragment } from 'react';
import styled from 'styled-components';
import { Table, Button, Form, Select } from 'antd';

import { CouponsContext } from '../../contexts/CouponsContext';
import { CampaignsContext } from '../../contexts/CampaignsContext';
import CreateCoupon from './CreateCoupon';
import GenerateCoupon from './GenerateCoupon';
import ImportCoupons from './ImportCoupons';
import ExportCoupons from './ExportCoupons';

const { Option } = Select;

const StyledButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
`;

const StyledButton = styled(Button)`
  margin-right: 0.5rem;
`;

const CouponsList = () => {
  const { coupons } = useContext(CouponsContext);
  const { campaigns, selectedCampaign, setSelectedCampaign } = useContext(
    CampaignsContext
  );
  const [modalVisible, setModalVisible] = useState(false);
  const [modal, setModal] = useState('');

  const columns = [
    {
      title: 'Code',
      dataIndex: 'coupon_code',
      sorter: (a, b) => a.coupon_code.localeCompare(b.coupon_code),
      sortDirections: ['descend', 'ascend']
    },
    {
      title: 'ID',
      dataIndex: 'id',
      sorter: (a, b) => a.id - b.id,
      sortDirections: ['descend', 'ascend']
    },
    {
      title: 'Unique',
      dataIndex: 'unique'
    }
  ];

  const handleSelect = e => {
    const selected = campaigns.find(campaign => {
      return campaign.id === e;
    });

    setSelectedCampaign(selected);
  };

  return (
    <Fragment>
      <StyledButtonWrapper>
        <Form layout="vertical">
          <Form.Item>
            <Select
              name="campaign"
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
              placeholder="Select campaign"
              onChange={handleSelect}
              style={{ width: 200 }}
              value={selectedCampaign.id}
            >
              {campaigns.map(campaign => {
                return (
                  <Option key={campaign.id} value={campaign.id}>
                    {`${campaign.name}`}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
        </Form>
        <div>
          <StyledButton
            type="primary"
            onClick={() => {
              setModalVisible(true);
              setModal('create');
            }}
          >
            Create
          </StyledButton>
          <StyledButton
            type="primary"
            onClick={() => {
              setModalVisible(true);
              setModal('generate');
            }}
          >
            Generate
          </StyledButton>
          <StyledButton
            type="primary"
            onClick={() => {
              setModalVisible(true);
              setModal('import');
            }}
          >
            Import
          </StyledButton>
          <StyledButton
            type="primary"
            onClick={() => {
              setModalVisible(true);
              setModal('export');
            }}
          >
            Export
          </StyledButton>
        </div>
      </StyledButtonWrapper>
      <Table
        columns={columns}
        rowKey={coupon => coupon.id}
        dataSource={coupons}
        pagination={{ pageSize: 10 }}
        scroll={{ x: 'max-content' }}
      />
      <CreateCoupon
        modalVisible={modalVisible}
        setModalVisible={setModalVisible}
        modal={modal}
      />
      <GenerateCoupon
        modalVisible={modalVisible}
        setModalVisible={setModalVisible}
        modal={modal}
      />
      <ImportCoupons
        modalVisible={modalVisible}
        setModalVisible={setModalVisible}
        modal={modal}
      />
      <ExportCoupons
        modalVisible={modalVisible}
        setModalVisible={setModalVisible}
        modal={modal}
      />
    </Fragment>
  );
};

export default CouponsList;
