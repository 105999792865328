import React, { useContext, useEffect, useState, useRef } from 'react';
import {
  Modal,
  Form,
  Input,
  Upload,
  Button,
  Icon,
  Select,
  DatePicker,
  Checkbox,
  Spin
} from 'antd';
import moment from 'moment';

import { CampaignsContext } from '../../contexts/CampaignsContext';

const { Option } = Select;
const { RangePicker } = DatePicker;
const { TextArea, Group } = Input;

const EditCampaignForm = (props) => {
  const {
    cities,
    countries,
    communities,
    redeemLocations,
    merchants,
    editCampaign,
    response,
    setResponse,
    benefit,
    benefits,
    setBenefits,
    addBenefit,
    deleteBenefit,
    updateBenefit,
    isEmptyBenefit,
    asyncForEach,
    gallery,
    setGallery,
    image,
    setImage
  } = useContext(CampaignsContext);

  const {
    modalVisible,
    setModalVisible,
    modal,
    selectedCampaign,
    setSelectedCampaign
  } = props;

  const [validationError, setValidationError] = useState({});
  const [loading, setLoading] = useState(false);
  const [initialLoading, setInitialLoading] = useState(false);
  const [descCharCount, setDescCharCount] = useState(0);
  const [shortDescCharCount, setShortDescCharCount] = useState(0);
  const [selectedCountries, setSelectedCountries] = useState({});
  const [selectedCities, setSelectedCities] = useState({});
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [deleteMedias, setDeleteMedias] = useState([]);
  const [staticCampaign, setStaticCampaign] = useState(false);
  const descInput = useRef(null);
  const shortDescInput = useRef(null);

  const { getFieldDecorator, getFieldError, setFieldsValue } = props.form;

  const nameError = getFieldError('name') || validationError.name;
  const rewardPointsError =
    getFieldError('reward_points') || validationError.reward_points;
  const shortDescriptionError =
    getFieldError('short_description') || validationError.short_description;
  const descriptionError =
    getFieldError('description') || validationError.description;
  const conditionsError =
    getFieldError('conditions') || validationError.conditions;
  const discountError = getFieldError('discount') || validationError.discount;
  const merchantError =
    getFieldError('merchant_id') || validationError.merchant_id;
  const dateError = getFieldError('date') || validationError.date;
  const showEndDateError =
    getFieldError('show_end_date') || validationError.show_end_date;
  const featuredError = getFieldError('featured') || validationError.featured;
  const freemiumError = getFieldError('freemium') || validationError.freemium;
  const staticError = getFieldError('static') || validationError.static;
  const citiesError = getFieldError('city_ids') || validationError.city_ids;
  const countriesError =
    getFieldError('country_ids') || validationError.country_ids;
  const communitiesError =
    getFieldError('community_ids') || validationError.community_ids;
  const redeemLocationsError =
    getFieldError('redeem_locations_ids') ||
    validationError.redeem_locations_ids;
  const imageError = getFieldError('image') || validationError.image;
  const galleryError = getFieldError('gallery') || validationError.gallery;
  const discountCodeError =
    getFieldError('discount_code') || validationError.discount_code;

  const closeModal = () => {
    setValidationError({});
    setModalVisible(false);
    setSelectedCampaign({});
    setShortDescCharCount(null);
    setDescCharCount(null);
    props.form.resetFields();
  };

  const handleOk = () => {
    setLoading(true);

    props.form.validateFields(async (error, fieldsValue) => {
      if (error) {
        setLoading(false);
        return;
      }

      const { date, ...noDate } = fieldsValue;
      let values;

      if (fieldsValue.date) {
        values = {
          ...noDate,
          date_start: fieldsValue.date[0].format('YYYY-MM-DD HH:mm:ss'),
          date_end: fieldsValue.date[1].format('YYYY-MM-DD HH:mm:ss')
        };
      } else {
        values = {
          ...noDate
        };
      }

      // return if there are errors on any benefit
      if (benefits.some((benefit) => benefit.error)) return;

      let filledBenefits = benefits.filter(
        (benefit) => !isEmptyBenefit(benefit)
      );

      if (filledBenefits) {
        values.benefits = filledBenefits;
      }

      let add_medias = [];

      await (async () => {
        await asyncForEach(gallery, async (file) => {
          if (!file.existing) {
            add_medias.push(await getBase64(file.originFileObj));
          }
        });
      })();

      editCampaign(
        { ...values, add_medias, delete_medias: deleteMedias },
        selectedCampaign.id
      );
      setImage(undefined);
      setGallery([]);
      setDeleteMedias([]);

      setBenefits([{ ...benefit }]);
    });
  };

  const handleCancel = () => {
    setResponse({});
    setLoading(false);
    setImage(undefined);
    setGallery([]);
    setDeleteMedias([]);
    closeModal();
    setBenefits([{ ...benefit }]);
  };

  const handleDescription = (e) => {
    setDescCharCount(e.target.value.length);

    const currentValidation = { ...validationError };

    if (e.file) {
      currentValidation.image = undefined;
    } else {
      const eventTarget = e.target;

      if (eventTarget) {
        const fieldName = eventTarget.name;
        currentValidation[fieldName] = undefined;
      }
    }

    if (validationError) {
      setValidationError(currentValidation);
    }
  };

  const handleShortDescription = (e) => {
    setShortDescCharCount(e.target.value.length);

    const currentValidation = { ...validationError };

    if (e.file) {
      currentValidation.image = undefined;
    } else {
      const eventTarget = e.target;

      if (eventTarget) {
        const fieldName = eventTarget.name;
        currentValidation[fieldName] = undefined;
      }
    }

    if (validationError) {
      setValidationError(currentValidation);
    }
  };

  const handleDate = () => {
    const currentValidation = { ...validationError };

    currentValidation.date = undefined;

    if (validationError) {
      setValidationError(currentValidation);
    }
  };

  const handleChange = async (e) => {
    const currentValidation = { ...validationError };

    if (e.file) {
      currentValidation.image = undefined;
    } else {
      const eventTarget = e.target;

      if (eventTarget) {
        const fieldName = eventTarget.name;
        currentValidation[fieldName] = undefined;
      }
    }

    if (validationError) {
      setValidationError(currentValidation);
    }

    if (e.file) {
      // Get this url from response in real world.
      setImage(await getBase64(e.file));
    }
  };

  const handleCities = (e) => {
    const currentValidation = { ...validationError };

    currentValidation.city_ids = undefined;

    if (validationError) {
      setValidationError(currentValidation);
    }

    const selectedCitiesList = [];
    const filteredCountriesList = [];

    e.map((id) => {
      const selectedCity = cities.find((city) => {
        return city.id === id;
      });

      return selectedCitiesList.push(selectedCity);
    });

    selectedCitiesList.map((city) => {
      if (!filteredCountriesList.includes(city.country_id)) {
        return filteredCountriesList.push(city.country_id);
      }
      return null;
    });

    setFieldsValue({
      country_ids: filteredCountriesList
    });
  };

  const handleCountries = (e) => {
    const currentValidation = { ...validationError };

    currentValidation.country_ids = undefined;

    if (validationError) {
      setValidationError(currentValidation);
    }

    const filteredCountries = e.map((selection) => {
      return countries.find((country) => {
        return selection === country.id;
      });
    });

    if (filteredCountries.length > 0) {
      setSelectedCountries(filteredCountries);
    } else {
      setSelectedCountries(countries);
    }
  };

  const handleCommunities = () => {
    const currentValidation = { ...validationError };

    currentValidation.community_ids = undefined;

    if (validationError) {
      setValidationError(currentValidation);
    }
  };

  const handleRedeemLocations = () => {
    const currentValidation = { ...validationError };

    currentValidation.redeem_locations_ids = undefined;

    if (validationError) {
      setValidationError(currentValidation);
    }
  };

  const handleMerchants = () => {
    const currentValidation = { ...validationError };

    currentValidation.merchant_id = undefined;

    if (validationError) {
      setValidationError(currentValidation);
    }
  };

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  const handleGalleryCancel = () => setPreviewVisible(false);

  const handleGalleryPreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewVisible(true);
  };

  const handleGalleryChange = ({ fileList }) => {
    setGallery([...fileList]);
  };

  const handleGalleryRemove = (file) => {
    if (file.existing) {
      setDeleteMedias([...deleteMedias, file.uid]);
    }
  };

  const handleStaticCampaign = (e) => {
    setStaticCampaign(e.target.checked);
  };

  useEffect(() => {
    if (modal === 'edit') {
      if (response.errors) {
        setValidationError(response.errors);
      } else {
        setValidationError({});
      }

      if (
        response.requestFor === 'get single' &&
        response.requestInProgress === true
      ) {
        setInitialLoading(true);
      } else if (
        response.requestFor === 'get single' &&
        response.requestInProgress === false
      ) {
        setInitialLoading(false);
      }

      if (
        response.requestFor === 'edit' &&
        response.requestInProgress === false &&
        response.id
      ) {
        closeModal();
      }

      if (
        response.requestFor === 'edit' &&
        response.requestInProgress === false
      ) {
        setLoading(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [response]);

  useEffect(() => {
    if (descInput.current) {
      if (descInput.current.textAreaRef) {
        setDescCharCount(descInput.current.textAreaRef.textLength);
      }
    }

    if (shortDescInput.current) {
      if (shortDescInput.current.textAreaRef) {
        setShortDescCharCount(shortDescInput.current.textAreaRef.textLength);
      }
    }
  }, [selectedCampaign]);

  useEffect(() => {
    if (selectedCountries.length > 0) {
      let filteredCities = [];
      selectedCountries.map((selection) => {
        let filteredCountries = cities.filter((city) => {
          return city.country_id === selection.id;
        });

        filteredCountries.map((country) => {
          return filteredCities.push(country);
        });

        setSelectedCities(filteredCities);

        return filteredCountries;
      });
    } else {
      setSelectedCities(cities);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCountries]);

  useEffect(() => {
    if (selectedCities.length === 0) {
      setSelectedCities(cities);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cities]);

  useEffect(() => {
    setStaticCampaign(selectedCampaign.static);
  }, [selectedCampaign]);

  if (modal === 'edit') {
    return (
      <Modal
        title={`Edit campaign`}
        visible={modalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        bodyStyle={{ maxHeight: '50vh', overflowY: 'scroll' }}
        confirmLoading={loading}
      >
        <Spin spinning={initialLoading}>
          <Form layout="vertical" onSubmit={handleOk}>
            <Form.Item
              label="Name"
              validateStatus={nameError ? 'error' : ''}
              help={nameError || ''}
            >
              {getFieldDecorator('name', {
                initialValue: selectedCampaign.name,
                rules: [
                  { required: true, message: 'Please input a campaign name!' }
                ],
                onChange: (e) => handleChange(e)
              })(<Input name="name" placeholder="Name" />)}
            </Form.Item>
            <Form.Item
              label="Reward points"
              validateStatus={rewardPointsError ? 'error' : ''}
              help={rewardPointsError || ''}
            >
              {getFieldDecorator('reward_points', {
                initialValue: selectedCampaign.reward_points,
                rules: [
                  {
                    pattern: /^\d*$/,
                    message: 'Please input a number!'
                  }
                ],
                onChange: (e) => handleChange(e)
              })(<Input name="reward_points" placeholder="Reward points" />)}
            </Form.Item>
            <Form.Item
              label={`Short description ${shortDescCharCount}/60`}
              validateStatus={shortDescriptionError ? 'error' : ''}
              help={shortDescriptionError || ''}
            >
              {getFieldDecorator('short_description', {
                initialValue: selectedCampaign.short_description,
                rules: [
                  {
                    required: true,
                    message: 'Please enter a short description!'
                  }
                ],
                onChange: (e) => handleShortDescription(e)
              })(
                <TextArea
                  ref={shortDescInput}
                  autoSize={true}
                  name="short_description"
                  placeholder="Short description"
                  maxLength={60}
                />
              )}
            </Form.Item>
            <Form.Item
              label={`Description ${descCharCount}/∞`}
              validateStatus={descriptionError ? 'error' : ''}
              help={descriptionError || ''}
            >
              {getFieldDecorator('description', {
                initialValue: selectedCampaign.description,
                onChange: (e) => handleDescription(e)
              })(
                <TextArea
                  ref={descInput}
                  autoSize={true}
                  name="description"
                  placeholder="Description"
                />
              )}
            </Form.Item>
            <Form.Item
              label="Conditions"
              validateStatus={conditionsError ? 'error' : ''}
              help={conditionsError || ''}
            >
              {getFieldDecorator('conditions', {
                initialValue: selectedCampaign.conditions,
                onChange: (e) => handleChange(e)
              })(
                <TextArea
                  autoSize={true}
                  name="conditions"
                  placeholder="Conditions"
                />
              )}
            </Form.Item>
            <Form.Item
              label="Discount"
              validateStatus={discountError ? 'error' : ''}
              help={discountError || ''}
            >
              {getFieldDecorator('discount', {
                initialValue: selectedCampaign.discount,
                rules: [
                  {
                    pattern: /^\d*$/,
                    message: 'Please input a number!'
                  }
                ],
                onChange: (e) => handleChange(e)
              })(<Input name="discount" placeholder="Discount" />)}
            </Form.Item>
            <Form.Item
              label="Date"
              validateStatus={dateError ? 'error' : ''}
              help={dateError || ''}
            >
              {getFieldDecorator('date', {
                initialValue:
                  selectedCampaign.date_start && selectedCampaign.date_end
                    ? [
                        moment(selectedCampaign.date_start),
                        moment(selectedCampaign.date_end)
                      ]
                    : null,
                onChange: () => handleDate()
              })(<RangePicker showTime format="YYYY-MM-DD HH:mm:ss" />)}
            </Form.Item>
            <div style={{ display: 'flex' }}>
              <Form.Item
                validateStatus={featuredError ? 'error' : ''}
                help={featuredError || ''}
              >
                {getFieldDecorator('featured', {
                  initialValue: selectedCampaign.featured,
                  valuePropName: 'checked'
                })(<Checkbox name="featured">Featured</Checkbox>)}
              </Form.Item>
              <Form.Item
                validateStatus={showEndDateError ? 'error' : ''}
                help={showEndDateError || ''}
              >
                {getFieldDecorator('show_end_date', {
                  initialValue: selectedCampaign.show_end_date,
                  valuePropName: 'checked'
                })(<Checkbox name="show_end_date">Show end date</Checkbox>)}
              </Form.Item>
              <Form.Item
                validateStatus={freemiumError ? 'error' : ''}
                help={freemiumError || ''}
              >
                {getFieldDecorator('freemium', {
                  initialValue: selectedCampaign.freemium,
                  valuePropName: 'checked'
                })(<Checkbox name="freemium">Freemium</Checkbox>)}
              </Form.Item>
              <Form.Item
                validateStatus={staticError ? 'error' : ''}
                help={staticError || ''}
              >
                {getFieldDecorator('static', {
                  initialValue: selectedCampaign.static,
                  valuePropName: 'checked',
                  onChange: (e) => handleStaticCampaign(e)
                })(<Checkbox name="static">Static</Checkbox>)}
              </Form.Item>
            </div>

            {staticCampaign ? (
              <Form.Item
                label="Discount code"
                validateStatus={discountCodeError ? 'error' : ''}
                help={discountCodeError || ''}
              >
                {getFieldDecorator('discount_code', {
                  initialValue: selectedCampaign.discount_code,
                  onChange: (e) => handleChange(e)
                })(<Input name="discount_code" placeholder="Discount code" />)}
              </Form.Item>
            ) : null}

            <div style={{ marginBottom: '32px' }}>
              <h3>Benefits</h3>
              {benefits.map((benefit, i) => {
                return (
                  <Form.Item
                    validateStatus={benefit.error ? 'error' : ''}
                    help={benefit.error ? 'All fields are required' : ''}
                    style={{ marginBottom: '16px' }}
                    key={i}
                  >
                    <Group compact>
                      <Input
                        name={`benefits[${i}}].title`}
                        placeholder="Title"
                        style={{ width: '25%' }}
                        onChange={(e) => {
                          updateBenefit(e, i, 'title');
                        }}
                        value={benefit.title}
                      />
                      <Input
                        name={`benefits[${i}}].price_before`}
                        placeholder="Price before"
                        style={{ width: '25%' }}
                        onChange={(e) => {
                          updateBenefit(e, i, 'price_before');
                        }}
                        value={benefit.price_before}
                      />
                      <Input
                        name={`benefits[${i}}].price_after`}
                        placeholder="Price after"
                        style={{ width: '25%' }}
                        onChange={(e) => {
                          updateBenefit(e, i, 'price_after');
                        }}
                        value={benefit.price_after}
                      />
                      <Button
                        style={{ width: '12.5%' }}
                        onClick={() => {
                          addBenefit(i);
                        }}
                        type="primary"
                      >
                        <Icon type="plus" />
                      </Button>
                      {benefits.length > 1 ? (
                        <Button
                          style={{ width: '12.5%' }}
                          onClick={() => {
                            deleteBenefit(i);
                          }}
                          type="danger"
                        >
                          <Icon type="delete" />
                        </Button>
                      ) : (
                        ''
                      )}
                    </Group>
                  </Form.Item>
                );
              })}
            </div>

            <Form.Item
              label="Merchant"
              validateStatus={merchantError ? 'error' : ''}
              help={merchantError || ''}
            >
              {getFieldDecorator('merchant_id', {
                initialValue: selectedCampaign.merchant_id
                  ? selectedCampaign.merchant_id
                  : null,
                rules: [
                  { required: true, message: 'Please select a merchant!' }
                ],
                onChange: () => handleMerchants()
              })(
                <Select
                  name="merchant_id"
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  placeholder="Merchant"
                >
                  {merchants.map((merchant) => {
                    return (
                      <Option key={merchant.id} value={merchant.id}>
                        {`${merchant.name} (${merchant.address})`}
                      </Option>
                    );
                  })}
                </Select>
              )}
            </Form.Item>
            <Form.Item
              label="Cities"
              validateStatus={citiesError ? 'error' : ''}
              help={citiesError || ''}
            >
              {getFieldDecorator('city_ids', {
                initialValue: selectedCampaign.Cities
                  ? selectedCampaign.Cities.map((city) => city.id)
                  : null,
                onChange: (e) => handleCities(e)
              })(
                <Select
                  name="city_ids"
                  mode="multiple"
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  placeholder="City"
                >
                  {selectedCities.map((city) => {
                    return (
                      <Option key={city.id} value={city.id}>
                        {`${city.name} (${city.postal_code})`}
                      </Option>
                    );
                  })}
                </Select>
              )}
            </Form.Item>
            <Form.Item
              label="Countries"
              validateStatus={countriesError ? 'error' : ''}
              help={countriesError || ''}
            >
              {getFieldDecorator('country_ids', {
                initialValue: selectedCampaign.Countries
                  ? selectedCampaign.Countries.map((country) => country.id)
                  : null,
                onChange: (e) => handleCountries(e)
              })(
                <Select
                  name="country_ids"
                  mode="multiple"
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  placeholder="Country"
                >
                  {countries.map((country) => {
                    return (
                      <Option key={country.id} value={country.id}>
                        {`${country.name} (${country.iso})`}
                      </Option>
                    );
                  })}
                </Select>
              )}
            </Form.Item>
            <Form.Item
              label="Communities"
              validateStatus={communitiesError ? 'error' : ''}
              help={communitiesError || ''}
            >
              {getFieldDecorator('community_ids', {
                initialValue: selectedCampaign.Communities
                  ? selectedCampaign.Communities.map(
                      (community) => community.id
                    )
                  : null,
                rules: [
                  { required: true, message: 'Please select a community!' }
                ],
                onChange: () => handleCommunities()
              })(
                <Select
                  name="community_ids"
                  mode="multiple"
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  placeholder="Community"
                >
                  {communities.map((community) => {
                    return (
                      <Option key={community.id} value={community.id}>
                        {community.name}
                      </Option>
                    );
                  })}
                </Select>
              )}
            </Form.Item>
            <Form.Item
              label="Redeem locations"
              validateStatus={redeemLocationsError ? 'error' : ''}
              help={redeemLocationsError || ''}
            >
              {getFieldDecorator('redeem_locations_ids', {
                initialValue: selectedCampaign.RedeemLocations
                  ? selectedCampaign.RedeemLocations.map(
                      (redeemLocation) => redeemLocation.id
                    )
                  : null,
                onChange: () => handleRedeemLocations()
              })(
                <Select
                  name="redeem_locations_id"
                  mode="multiple"
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  placeholder="Redeem location"
                >
                  {redeemLocations.map((redeemLocation) => {
                    return (
                      <Option key={redeemLocation.id} value={redeemLocation.id}>
                        {redeemLocation.name}
                      </Option>
                    );
                  })}
                </Select>
              )}
            </Form.Item>
            <Form.Item
              label="Image"
              validateStatus={imageError ? 'error' : ''}
              help={imageError || ''}
            >
              {getFieldDecorator('image', {
                valuePropName: 'fileList',
                getValueFromEvent: normFile,
                onChange: (e) => handleChange(e)
              })(
                <Upload
                  name="image"
                  listType="picture-card"
                  className="avatar-uploader"
                  showUploadList={false}
                  beforeUpload={() => false}
                >
                  {image ? (
                    <img src={image} alt="avatar" style={{ width: '100%' }} />
                  ) : (
                    <div>
                      <Icon type={loading ? 'loading' : 'plus'} />
                      <div className="ant-upload-text">Upload</div>
                    </div>
                  )}
                </Upload>
              )}
            </Form.Item>

            <Form.Item
              validateStatus={galleryError ? 'error' : ''}
              help={galleryError || ''}
              label="Gallery"
            >
              <div className="clearfix">
                <Upload
                  beforeUpload={() => false}
                  listType="picture-card"
                  fileList={gallery}
                  onPreview={handleGalleryPreview}
                  onChange={handleGalleryChange}
                  onRemove={handleGalleryRemove}
                >
                  <div>
                    <Icon type="plus" />
                    <div className="ant-upload-text">Upload</div>
                  </div>
                </Upload>
                <Modal
                  visible={previewVisible}
                  footer={null}
                  onCancel={handleGalleryCancel}
                >
                  <img
                    alt="example"
                    style={{ width: '100%' }}
                    src={previewImage}
                  />
                </Modal>
              </div>
            </Form.Item>
          </Form>
        </Spin>
      </Modal>
    );
  } else {
    return null;
  }
};

const EditCampaign = Form.create()(EditCampaignForm);

export default EditCampaign;
