import React, { useContext, useState, Fragment } from 'react';
import styled from 'styled-components';
import { Table, Icon, Button, Divider } from 'antd';

import { CampaignsContext } from '../../contexts/CampaignsContext';
import SingleCampaign from './SingleCampaign';
import CreateCampaign from './CreateCampaign';
import EditCampaign from './EditCampaign';
import DeleteCampaign from './DeleteCampaign';

const StyledButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 1rem;
`;

const StyledButton = styled(Button)`
  margin-right: 0.5rem;
`;

const StyledIcon = styled.span`
  cursor: pointer;
`;

const StyledSpan = styled.span`
  color: #1890ff;
  cursor: pointer;
`;

const CampaignsList = () => {
  const {
    campaigns,
    getSingleCampaign,
    selectedCampaign,
    setSelectedCampaign
  } = useContext(CampaignsContext);
  const [modalVisible, setModalVisible] = useState(false);
  const [modal, setModal] = useState('');
  const [drawer, setDrawer] = useState(false);

  const transformedCampaigns = campaigns.map(campaign => {
    const campaignName = campaign.name
      .split(' ')
      .map(w => w.substring(0, 1).toUpperCase() + w.substring(1))
      .join(' ');

    const transformedCampaign = {
      ...campaign,
      name: campaignName
    };

    return transformedCampaign;
  });

  const columns = [
    {
      title: 'Name',
      key: 'name',
      sorter: (a, b) => a.name.localeCompare(b.name),
      sortDirections: ['descend', 'ascend'],
      render: campaign => (
        <StyledSpan
          onClick={() => {
            getSingleCampaign(campaign.id);
            setDrawer(true);
          }}
        >
          {campaign.name}
        </StyledSpan>
      )
    },
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      sorter: (a, b) => a.id - b.id,
      sortDirections: ['descend', 'ascend']
    },
    {
      title: 'Action',
      render: campaign => (
        <span>
          <StyledIcon
            onClick={() => {
              setModalVisible(true);
              setModal('edit');
              getSingleCampaign(campaign.id);
            }}
          >
            <Icon type="edit" />
          </StyledIcon>
          <Divider type="vertical" />
          <StyledIcon
            onClick={() => {
              setModalVisible(true);
              setModal('delete');
              getSingleCampaign(campaign.id);
            }}
          >
            <Icon type="delete" />
          </StyledIcon>
        </span>
      )
    }
  ];

  return (
    <Fragment>
      <StyledButtonWrapper>
        <StyledButton
          shape="circle"
          icon="plus"
          type="primary"
          onClick={() => {
            setModalVisible(true);
            setModal('create');
          }}
        ></StyledButton>
      </StyledButtonWrapper>
      <Table
        columns={columns}
        rowKey={campaign => campaign.id}
        dataSource={transformedCampaigns}
        pagination={{ pageSize: 10 }}
        scroll={{ x: 'max-content' }}
      />
      <CreateCampaign
        modalVisible={modalVisible}
        setModalVisible={setModalVisible}
        modal={modal}
      />
      <SingleCampaign
        drawer={drawer}
        setDrawer={setDrawer}
        selectedCampaign={selectedCampaign}
        setSelectedCampaign={setSelectedCampaign}
      />
      <EditCampaign
        modalVisible={modalVisible}
        setModalVisible={setModalVisible}
        modal={modal}
        selectedCampaign={selectedCampaign}
        setSelectedCampaign={setSelectedCampaign}
      />
      <DeleteCampaign
        modalVisible={modalVisible}
        setModalVisible={setModalVisible}
        modal={modal}
        selectedCampaign={selectedCampaign}
        setSelectedCampaign={setSelectedCampaign}
      />
    </Fragment>
  );
};

export default CampaignsList;
