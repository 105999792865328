import React, { useState } from 'react';
import styled from 'styled-components';
import { Drawer, Typography, Spin } from 'antd';

const { Text } = Typography;

const StyledImageWrapper = styled.div`
  margin-bottom: 2.5rem;
`;

const StyledImage = styled.img`
  max-width: 100%;
  object-fit: cover;
`;

const SingleMerchant = props => {
  const { drawer, setDrawer, selectedMerchant, setSelectedMerchant } = props;
  const [imageLoaded, setImageLoaded] = useState(false);

  const transformedMerchant = {
    Name: selectedMerchant.name,
    Address: selectedMerchant.address || '-',
    Phone: selectedMerchant.phone || '-',
    Website: selectedMerchant.website || '-',
    Facebook: selectedMerchant.facebook || '-',
    Instagram: selectedMerchant.instagram || '-',
    'Contact e-mail': selectedMerchant.contact_email || '-',
    'Contact person': selectedMerchant.contact_person || '-',
    'Provision percentage': selectedMerchant.provision_percentage || '-'
  };

  return (
    <Drawer
      width={640}
      placement="right"
      closable={false}
      onClose={() => {
        setDrawer(false);
        setTimeout(() => {
          setSelectedMerchant({});
          setImageLoaded(false);
        }, 300);
      }}
      visible={drawer}
    >
      {selectedMerchant.image ? (
        <StyledImageWrapper>
          <Spin spinning={!imageLoaded}>
            <StyledImage
              src={selectedMerchant.image}
              onLoad={() => setImageLoaded(true)}
            />
          </Spin>
        </StyledImageWrapper>
      ) : null}
      <div>
        {Object.keys(transformedMerchant).map((keyName, i) => {
          return (
            <p key={i}>
              <Text>
                {keyName}:{' '}
                <Text type="secondary">{transformedMerchant[keyName]}</Text>
              </Text>
            </p>
          );
        })}
      </div>
    </Drawer>
  );
};

export default SingleMerchant;
