import React, { useContext, useEffect, useState } from 'react';
import { Modal, Form, Input, Upload, Button, Icon, Select } from 'antd';

import { CommunitiesContext } from '../../contexts/CommunitiesContext';

const { Option } = Select;
const { TextArea } = Input;

const CreateCommunityForm = props => {
  const {
    cities,
    countries,
    createCommunity,
    response,
    setResponse
  } = useContext(CommunitiesContext);
  const { modalVisible, setModalVisible, modal } = props;
  const [validationError, setValidationError] = useState({});
  const [loading, setLoading] = useState(false);
  const [selectedCountries, setSelectedCountries] = useState({});
  const [selectedCities, setSelectedCities] = useState({});

  const { getFieldDecorator, getFieldError, setFieldsValue } = props.form;

  const nameError = getFieldError('name') || validationError.name;
  const emailError = getFieldError('email') || validationError.email;
  const phoneError = getFieldError('phone') || validationError.phone;
  const urlError = getFieldError('url') || validationError.url;
  const imageError = getFieldError('image') || validationError.image;
  const addressError = getFieldError('address') || validationError.address;
  const floorError = getFieldError('floor') || validationError.floor;
  const taglineError = getFieldError('tagline') || validationError.tagline;
  const pitchError = getFieldError('pitch') || validationError.pitch;
  const monthlyCreditsError =
    getFieldError('monthly_credits') || validationError.monthly_credits;
  const remainingCreditsError =
    getFieldError('remaining_credits') || validationError.remaining_credits;
  const linkedinError = getFieldError('linkedin') || validationError.linkedin;
  const xingError = getFieldError('xing') || validationError.xing;
  const instagramError =
    getFieldError('instagram') || validationError.instagram;
  const facebookError = getFieldError('facebook') || validationError.facebook;
  const vkontakteError =
    getFieldError('vkontakte') || validationError.vkontakte;
  const newsFeedUrlError =
    getFieldError('news_feed_url') || validationError.news_feed_url;
  const descriptionError =
    getFieldError('description') || validationError.description;
  const citiesError = getFieldError('city_ids') || validationError.city_ids;
  const countriesError =
    getFieldError('country_ids') || validationError.country_ids;

  const closeModal = () => {
    setValidationError({});
    setModalVisible(false);
    props.form.resetFields();
  };

  const handleOk = () => {
    setLoading(true);

    props.form.validateFields((error, values) => {
      if (error) {
        setLoading(false);
        return;
      }

      createCommunity(values);
    });
  };

  const handleCancel = () => {
    setResponse({});
    setLoading(false);
    closeModal();
  };

  const handleChange = e => {
    const currentValidation = { ...validationError };

    if (e.file) {
      currentValidation.image = undefined;
    } else {
      const eventTarget = e.target;

      if (eventTarget) {
        const fieldName = eventTarget.name;
        currentValidation[fieldName] = undefined;
      }
    }

    if (validationError) {
      setValidationError(currentValidation);
    }
  };

  const handleCities = e => {
    const currentValidation = { ...validationError };

    currentValidation.city_ids = undefined;

    if (validationError) {
      setValidationError(currentValidation);
    }

    const selectedCitiesList = [];
    const filteredCountriesList = [];

    e.map(id => {
      const selectedCity = cities.find(city => {
        return city.id === id;
      });

      return selectedCitiesList.push(selectedCity);
    });

    selectedCitiesList.map(city => {
      if (!filteredCountriesList.includes(city.country_id)) {
        return filteredCountriesList.push(city.country_id);
      }
      return null;
    });

    setFieldsValue({
      country_ids: filteredCountriesList
    });
  };

  const handleCountries = e => {
    const currentValidation = { ...validationError };

    currentValidation.country_ids = undefined;

    if (validationError) {
      setValidationError(currentValidation);
    }

    const filteredCountries = e.map(selection => {
      return countries.find(country => {
        return selection === country.id;
      });
    });

    if (filteredCountries.length > 0) {
      setSelectedCountries(filteredCountries);
    } else {
      setSelectedCountries(countries);
    }
  };

  const normFile = e => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  useEffect(() => {
    if (response.errors) {
      setValidationError(response.errors);
    } else {
      setValidationError({});
    }

    if (response.id) {
      closeModal();
    }

    if (Object.entries(response).length !== 0) {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [response]);

  useEffect(() => {
    if (selectedCountries.length > 0) {
      let filteredCities = [];
      selectedCountries.map(selection => {
        let filteredCountries = cities.filter(city => {
          return city.country_id === selection.id;
        });

        filteredCountries.map(country => {
          return filteredCities.push(country);
        });

        setSelectedCities(filteredCities);

        return filteredCountries;
      });
    } else {
      setSelectedCities(cities);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCountries]);

  useEffect(() => {
    if (selectedCities.length === 0) {
      setSelectedCities(cities);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cities]);

  if (modal === 'create') {
    return (
      <Modal
        title={`Add new community`}
        visible={modalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        bodyStyle={{ maxHeight: '50vh', overflowY: 'scroll' }}
        confirmLoading={loading}
      >
        <Form layout="vertical" onSubmit={handleOk}>
          <Form.Item
            label="Name"
            validateStatus={nameError ? 'error' : ''}
            help={nameError || ''}
          >
            {getFieldDecorator('name', {
              rules: [
                { required: true, message: 'Please input a community name!' }
              ],
              onChange: e => handleChange(e)
            })(<Input name="name" placeholder="Name" />)}
          </Form.Item>
          <Form.Item
            label="Email"
            validateStatus={emailError ? 'error' : ''}
            help={emailError || ''}
          >
            {getFieldDecorator('email', {
              rules: [
                {
                  type: 'email',
                  message: 'The input is not a valid e-mail address!'
                }
              ],
              onChange: e => handleChange(e)
            })(<Input name="email" placeholder="E-mail" />)}
          </Form.Item>
          <Form.Item
            label="Phone number"
            validateStatus={phoneError ? 'error' : ''}
            help={phoneError || ''}
          >
            {getFieldDecorator('phone', {
              rules: [
                {
                  pattern: /^[+]?[0-9]{4,16}$/,
                  message: 'The input is not a valid phone number!'
                }
              ],
              onChange: e => handleChange(e)
            })(<Input name="phone" placeholder="Phone" type="tel" />)}
          </Form.Item>
          <Form.Item
            label="URL"
            validateStatus={urlError ? 'error' : ''}
            help={urlError || ''}
          >
            {getFieldDecorator('url', {
              rules: [
                {
                  pattern: /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=+$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=+$,\w]+@)[A-Za-z0-9.-]+)((?:\/[+~%/.\w-_]*)?\??(?:[-+=&;%@.\w_]*)#?(?:[.!/\\\w]*))?)/,
                  message: 'The input is not a valid URL!'
                }
              ],
              onChange: e => handleChange(e)
            })(<Input name="url" placeholder="URL" />)}
          </Form.Item>
          <Form.Item
            label="Address"
            validateStatus={addressError ? 'error' : ''}
            help={addressError || ''}
          >
            {getFieldDecorator('address', {
              onChange: e => handleChange(e)
            })(<Input name="address" placeholder="Address" />)}
          </Form.Item>
          <Form.Item
            label="Floor"
            validateStatus={floorError ? 'error' : ''}
            help={floorError || ''}
          >
            {getFieldDecorator('floor', {
              onChange: e => handleChange(e)
            })(<Input name="floor" placeholder="Floor" />)}
          </Form.Item>
          <Form.Item
            label="Tagline"
            validateStatus={taglineError ? 'error' : ''}
            help={taglineError || ''}
          >
            {getFieldDecorator('tagline', {
              onChange: e => handleChange(e)
            })(<Input name="tagline" placeholder="Tagline" />)}
          </Form.Item>
          <Form.Item
            label="Pitch"
            validateStatus={pitchError ? 'error' : ''}
            help={pitchError || ''}
          >
            {getFieldDecorator('pitch', {
              onChange: e => handleChange(e)
            })(<Input name="pitch" placeholder="Pitch" />)}
          </Form.Item>
          <Form.Item
            label="Monthly credits"
            validateStatus={monthlyCreditsError ? 'error' : ''}
            help={monthlyCreditsError || ''}
          >
            {getFieldDecorator('monthly_credits', {
              onChange: e => handleChange(e)
            })(<Input name="monthly_credits" placeholder="Monthly credits" />)}
          </Form.Item>
          <Form.Item
            label="Remaining credits"
            validateStatus={remainingCreditsError ? 'error' : ''}
            help={remainingCreditsError || ''}
          >
            {getFieldDecorator('remaining_credits', {
              onChange: e => handleChange(e)
            })(
              <Input name="remaining_credits" placeholder="Remaining credits" />
            )}
          </Form.Item>
          <Form.Item
            label="Linkedin URL"
            validateStatus={linkedinError ? 'error' : ''}
            help={linkedinError || ''}
          >
            {getFieldDecorator('linkedin', {
              rules: [
                {
                  pattern: /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=+$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=+$,\w]+@)[A-Za-z0-9.-]+)((?:\/[+~%/.\w-_]*)?\??(?:[-+=&;%@.\w_]*)#?(?:[.!/\\\w]*))?)/,
                  message: 'The input is not a valid URL!'
                }
              ],
              onChange: e => handleChange(e)
            })(<Input name="linkedin" placeholder="Linkedin" />)}
          </Form.Item>
          <Form.Item
            label="Xing URL"
            validateStatus={xingError ? 'error' : ''}
            help={xingError || ''}
          >
            {getFieldDecorator('xing', {
              rules: [
                {
                  pattern: /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=+$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=+$,\w]+@)[A-Za-z0-9.-]+)((?:\/[+~%/.\w-_]*)?\??(?:[-+=&;%@.\w_]*)#?(?:[.!/\\\w]*))?)/,
                  message: 'The input is not a valid URL!'
                }
              ],
              onChange: e => handleChange(e)
            })(<Input name="xing" placeholder="Xing" />)}
          </Form.Item>
          <Form.Item
            label="Instagram URL"
            validateStatus={instagramError ? 'error' : ''}
            help={instagramError || ''}
          >
            {getFieldDecorator('instagram', {
              rules: [
                {
                  pattern: /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=+$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=+$,\w]+@)[A-Za-z0-9.-]+)((?:\/[+~%/.\w-_]*)?\??(?:[-+=&;%@.\w_]*)#?(?:[.!/\\\w]*))?)/,
                  message: 'The input is not a valid URL!'
                }
              ],
              onChange: e => handleChange(e)
            })(<Input name="instagram" placeholder="Instagram" />)}
          </Form.Item>
          <Form.Item
            label="Facebook URL"
            validateStatus={facebookError ? 'error' : ''}
            help={facebookError || ''}
          >
            {getFieldDecorator('facebook', {
              rules: [
                {
                  pattern: /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=+$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=+$,\w]+@)[A-Za-z0-9.-]+)((?:\/[+~%/.\w-_]*)?\??(?:[-+=&;%@.\w_]*)#?(?:[.!/\\\w]*))?)/,
                  message: 'The input is not a valid URL!'
                }
              ],
              onChange: e => handleChange(e)
            })(<Input name="facebook" placeholder="Facebook" />)}
          </Form.Item>
          <Form.Item
            label="Vkontakte URL"
            validateStatus={vkontakteError ? 'error' : ''}
            help={vkontakteError || ''}
          >
            {getFieldDecorator('vkontakte', {
              onChange: e => handleChange(e)
            })(<Input name="vkontakte" placeholder="Vkontakte" />)}
          </Form.Item>
          <Form.Item
            label="News feed URL"
            validateStatus={newsFeedUrlError ? 'error' : ''}
            help={newsFeedUrlError || ''}
          >
            {getFieldDecorator('news_feed_url', {
              rules: [
                {
                  pattern: /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=+$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=+$,\w]+@)[A-Za-z0-9.-]+)((?:\/[+~%/.\w-_]*)?\??(?:[-+=&;%@.\w_]*)#?(?:[.!/\\\w]*))?)/,
                  message: 'The input is not a valid URL!'
                }
              ],
              onChange: e => handleChange(e)
            })(<Input name="news_feed_url" placeholder="News feed URL" />)}
          </Form.Item>
          <Form.Item
            label="Description"
            validateStatus={descriptionError ? 'error' : ''}
            help={descriptionError || ''}
          >
            {getFieldDecorator('description', {
              onChange: e => handleChange(e)
            })(
              <TextArea
                autoSize={true}
                name="description"
                placeholder="Description"
              />
            )}
          </Form.Item>
          <Form.Item
            label="Cities"
            validateStatus={citiesError ? 'error' : ''}
            help={citiesError || ''}
          >
            {getFieldDecorator('city_ids', {
              onChange: e => handleCities(e)
            })(
              <Select
                name="city_ids"
                mode="multiple"
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
                placeholder="City"
              >
                {selectedCities.map(city => {
                  return (
                    <Option key={city.id} value={city.id}>
                      {`${city.name} (${city.postal_code})`}
                    </Option>
                  );
                })}
              </Select>
            )}
          </Form.Item>
          <Form.Item
            label="Countries"
            validateStatus={countriesError ? 'error' : ''}
            help={countriesError || ''}
          >
            {getFieldDecorator('country_ids', {
              onChange: e => handleCountries(e)
            })(
              <Select
                name="country_ids"
                mode="multiple"
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
                placeholder="Country"
              >
                {countries.map(country => {
                  return (
                    <Option key={country.id} value={country.id}>
                      {`${country.name} (${country.iso})`}
                    </Option>
                  );
                })}
              </Select>
            )}
          </Form.Item>
          <Form.Item
            label="Image"
            validateStatus={imageError ? 'error' : ''}
            help={imageError || ''}
          >
            {getFieldDecorator('image', {
              valuePropName: 'fileList',
              getValueFromEvent: normFile,
              onChange: e => handleChange(e)
            })(
              <Upload name="image" beforeUpload={() => false}>
                <Button>
                  <Icon type="upload" /> Click to upload image
                </Button>
              </Upload>
            )}
          </Form.Item>
        </Form>
      </Modal>
    );
  } else {
    return null;
  }
};

const CreateCommunity = Form.create()(CreateCommunityForm);

export default CreateCommunity;
