import React, { useState, useEffect, createContext, useContext } from 'react';
import { AwsClient } from 'aws4fetch';

import { AuthContext } from './AuthContext';
import { CampaignsContext } from './CampaignsContext';
import { API_ENDPOINT } from '../consts/globals';

export const CouponsContext = createContext();

const CouponsContextProvider = props => {
  const { creds } = useContext(AuthContext);
  const { campaigns, selectedCampaign, setSelectedCampaign } = useContext(
    CampaignsContext
  );
  const [coupons, setCoupons] = useState([]);
  const [response, setResponse] = useState({});
  const [couponsChanged, setCouponsChanged] = useState(false);

  const abortController = new AbortController();
  const signal = abortController.signal;

  const aws = new AwsClient({
    accessKeyId: creds.accessKeyId,
    secretAccessKey: creds.secretAccessKey,
    sessionToken: creds.sessionToken
  });

  const getCoupons = async () => {
    try {
      const response = await aws.fetch(
        `${API_ENDPOINT}/system/campaigns/${selectedCampaign.id}/coupons`,
        {
          signal: signal,
          method: 'GET',
          headers: {
            'Content-type': 'application/json',
            Accept: 'application/json'
          }
        }
      );

      if (!response.ok) {
        throw Error(response.statusText);
      }

      const responseJson = await response.json();

      setCoupons(responseJson.data);
      setCouponsChanged(false);
    } catch (error) {
      console.log(error);
    }
  };

  const createCoupon = async coupon => {
    setResponse({});

    const couponCode = {
      coupon_code: coupon.coupon_code,
      unique: coupon.unique
    };

    if (coupon.unique) {
      couponCode.limited = parseInt(coupon.limited);
    }

    try {
      const response = await aws.fetch(
        `${API_ENDPOINT}/system/campaigns/${coupon.campaign}/coupons`,
        {
          method: 'POST',
          headers: {
            'Content-type': 'application/json',
            Accept: 'application/json'
          },
          body: JSON.stringify(couponCode)
        }
      );

      const responseJson = await response.json();
      setResponse(responseJson);

      if (!response.ok) {
        throw Error(response.statusText);
      }

      const currentCampaign = campaigns.find(campaign => {
        return campaign.id === coupon.campaign;
      });

      setSelectedCampaign(currentCampaign);
      setCouponsChanged(true);
    } catch (error) {
      console.log(error);
    }
  };

  const generateCoupon = async coupon => {
    setResponse({});

    const couponObj = {
      unique: false,
      limited: 0,
      number_of_coupons: parseInt(coupon.number_of_coupons)
    };

    try {
      const response = await aws.fetch(
        `${API_ENDPOINT}/system/campaigns/${coupon.campaign}/coupons`,
        {
          method: 'POST',
          headers: {
            'Content-type': 'application/json',
            Accept: 'application/json'
          },
          body: JSON.stringify(couponObj)
        }
      );

      const responseJson = await response.json();
      setResponse(responseJson);

      if (!response.ok) {
        throw Error(response.statusText);
      }

      const currentCampaign = campaigns.find(campaign => {
        return campaign.id === coupon.campaign;
      });

      setSelectedCampaign(currentCampaign);
      setCouponsChanged(true);
    } catch (error) {
      console.log(error);
    }
  };

  const importCoupons = async values => {
    setResponse({});

    const file64 = new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(values.csv[0].originFileObj);
      reader.onload = () => resolve(reader.result.split(',')[1]);
      reader.onerror = error => reject(error);
    });

    try {
      const params = {
        csv_file: await file64
      };

      const response = await aws.fetch(
        `${API_ENDPOINT}/system/campaigns/${values.campaign}/import-coupons`,
        {
          method: 'POST',
          headers: {
            'Content-type': 'application/json',
            Accept: 'application/json'
          },
          body: JSON.stringify(params)
        }
      );

      const responseJson = await response.json();
      setResponse(responseJson);

      if (!response.ok) {
        throw Error(response.statusText);
      }

      const currentCampaign = campaigns.find(campaign => {
        return campaign.id === values.campaign;
      });

      setSelectedCampaign(currentCampaign);
      setCouponsChanged(true);
    } catch (error) {
      console.log(error);
    }
  };

  const exportCoupons = async values => {
    setResponse({});

    try {
      const response = await aws.fetch(
        `${API_ENDPOINT}/system/campaigns/${values.campaign}/export-coupons`,
        {
          signal: signal,
          method: 'GET',
          headers: {
            'Content-type': 'application/json',
            Accept: 'application/json'
          }
        }
      );

      if (!response.ok) {
        throw Error(response.statusText);
      }

      const responseBlob = await response.blob();
      const responseType = response.headers.get('Content-Type');

      //Create a Blob from the PDF Stream
      const file = new Blob([responseBlob], { type: responseType });
      const fileURL = URL.createObjectURL(file);

      const url = fileURL;
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${values.campaign}.xlsx`);
      link.click();

      setResponse({ message: 'Success' });
    } catch (error) {
      console.log(error);
    }
  };

  const deleteCoupon = async id => {
    setResponse({});

    try {
      const response = await aws.fetch(`${API_ENDPOINT}/system/coupons/${id}`, {
        method: 'DELETE',
        headers: {
          'Content-type': 'application/json',
          Accept: 'application/json'
        }
      });

      const responseJson = await response.json();
      setResponse(responseJson);

      if (!response.ok) {
        throw Error(response.statusText);
      }

      getCoupons();
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (selectedCampaign.id) {
      getCoupons();
    }

    return function cleanup() {
      abortController.abort();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCampaign]);

  useEffect(() => {
    if (couponsChanged) {
      getCoupons();
    }

    return function cleanup() {
      abortController.abort();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [couponsChanged]);

  return (
    <CouponsContext.Provider
      value={{
        coupons,
        getCoupons,
        createCoupon,
        generateCoupon,
        importCoupons,
        exportCoupons,
        deleteCoupon,
        response,
        setResponse
      }}
    >
      {props.children}
    </CouponsContext.Provider>
  );
};

export default CouponsContextProvider;
