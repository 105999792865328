import React, { useContext, useState } from 'react';
import { Card, Popover, Icon, Typography } from 'antd';
import styled from 'styled-components';
import moment from 'moment';

import { AnalyticsContext } from '../../contexts/AnalyticsContext';
import { useEffect } from 'react';

const { Text } = Typography;

const StyledCard = styled(Card)`
  min-height: 265px;

  p {
    color: rgba(0, 0, 0, 0.45);
    margin-bottom: 0.5rem;
  }

  span {
    color: rgba(0, 0, 0, 0.45);
    font-size: 14px;
    font-weight: 400;
  }

  h4 {
    span {
      margin-left: 0.5rem;
    }
  }
`;

const StyledTitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: no-wrap;
`;

const StyledOuterWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
`;

const StyledInnerWrapper = styled.div`
  margin-right: 4rem;
`;

const StyledText = styled(Text)`
  display: block;
`;

const StyledCaretUpIcon = styled(Icon)`
  color: #52c41a;
  font-size: 12px;
  margin-left: 4px;
`;

const StyledCaretDownIcon = styled(Icon)`
  color: #ff4d4f;
  font-size: 12px;
  margin-left: 4px;
`;

const StyledActiveIndicator = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 4px;
  background-color: #001529;
`;

const StyledRefreshDate = styled.p`
  font-size: 10px;
`;

const IncomeOverview = (props) => {
  const { active } = props;
  const { benefitWidgetIncome } = useContext(AnalyticsContext);
  const widgetData = benefitWidgetIncome.data || {};
  const [monthDifference, setMonthDifference] = useState({});

  const calculateDifference = () => {
    if (widgetData.this_last_month && widgetData.this_last_month.length > 1) {
      const currentMonth = parseFloat(widgetData.this_last_month[1].total_sum);
      const previousMonth = parseFloat(widgetData.this_last_month[0].total_sum);

      const raw = currentMonth - previousMonth;
      const percentage = (raw / previousMonth) * 100;

      setMonthDifference({
        raw: parseFloat(raw).toFixed(2),
        percentage: parseFloat(percentage).toFixed(2)
      });
    } else {
      setMonthDifference({});
    }
  };

  useEffect(() => {
    calculateDifference();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [benefitWidgetIncome]);

  const popoverContent = (
    <div>
      <StyledText type="secondary">
        {monthDifference.raw ? `${monthDifference.raw} €` : 'no data'}
      </StyledText>
    </div>
  );

  return (
    <StyledCard>
      {active ? <StyledActiveIndicator /> : null}
      <StyledTitleWrapper>
        <p>Total income</p>
        <StyledRefreshDate>
          Refreshed<br></br>
          {benefitWidgetIncome.timestamp
            ? moment(benefitWidgetIncome.timestamp).toLocaleString()
            : 'no data'}
        </StyledRefreshDate>
      </StyledTitleWrapper>
      <h2 className="ant-typography">
        {widgetData.total_income
          ? `${parseFloat(widgetData.total_income).toFixed(2)} €`
          : 'no data'}
      </h2>
      <StyledOuterWrapper>
        <StyledInnerWrapper>
          <p>This month</p>
          <h4 className="ant-typography">
            {widgetData.this_last_month && widgetData.this_last_month.length > 1
              ? `${parseFloat(widgetData.this_last_month[1].total_sum).toFixed(
                  2
                )} €`
              : 'no data'}
            <Popover content={popoverContent}>
              <span>
                {monthDifference.percentage
                  ? `${monthDifference.percentage} %`
                  : 'no data'}
              </span>
              {monthDifference.percentage > 0 ? (
                <StyledCaretUpIcon type="caret-up" />
              ) : (
                <StyledCaretDownIcon type="caret-down" />
              )}
            </Popover>
          </h4>
        </StyledInnerWrapper>
        <div>
          <p>Last month</p>
          <h4 className="ant-typography">
            {widgetData.this_last_month && widgetData.this_last_month.length
              ? `${parseFloat(widgetData.this_last_month[0].total_sum).toFixed(
                  2
                )} €`
              : 'no data'}
          </h4>
        </div>
      </StyledOuterWrapper>
    </StyledCard>
  );
};

export default IncomeOverview;
