import React from 'react';
import { Layout } from 'antd';
import styled from 'styled-components';

const StyledFooter = styled(Layout.Footer)`
  text-align: center;
`;

const Footer = () => {
  return <StyledFooter>Home United ©2019</StyledFooter>;
};

export default Footer;
