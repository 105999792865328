import React, {
  useContext,
  Fragment,
  useRef,
  useState,
  useLayoutEffect
} from 'react';
import { Row, Col, Table } from 'antd';
import styled from 'styled-components';
import { BarChart, CartesianGrid, XAxis, YAxis, Bar, Tooltip } from 'recharts';

import { AnalyticsContext } from '../../contexts/AnalyticsContext';

const StyledWrapper = styled.div`
  padding: 1.5rem;
  background: #fff;
  position: relative;
  margin-bottom: 2rem;
  width: 100%;

  h3 {
    margin-bottom: 1.5rem;
    margin-left: 1rem;
  }
`;

const StyledRechartsTooltip = styled.div`
  background-color: #fff;
  padding: 12px 16px;
  background-clip: padding-box;
  border-radius: 4px;
  -webkit-box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  -webkit-box-shadow: 0 0 8px rgba(0, 0, 0, 0.15);
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.15);
  display: flex;
  flex-direction: column;
  flex-wrap: no-wrap;

  p {
    color: rgba(0, 0, 0, 0.45);
    margin-bottom: 0;
  }
`;

const CampaignsDetails = () => {
  const { couponsStats } = useContext(AnalyticsContext);
  const [dimensions, setDimensions] = useState({});
  const graphWrapper = useRef();

  // holds the timer for setTimeout and clearInterval
  let movement_timer = null;

  // the number of ms the window size must stay the same size before the
  // dimension state variable is reset
  const RESET_TIMEOUT = 100;

  let graphData;
  let tableData;

  if (couponsStats) {
    graphData = couponsStats.graph.data;

    graphData = couponsStats.graph.data.map((entry) => {
      const bar = {
        calendar_date: entry.calendar_date.substr(5),
        redeemed_coupons: entry.redeemed_coupons
      };

      return bar;
    });

    tableData = couponsStats.table.data;

    tableData = couponsStats.table.data.map((entry, index) => {
      let arr = [];
      const normal = {
        key: entry.id + 100000,
        name: `Normal: ${entry.coupons.normal.total} total, ${entry.coupons.normal.redeemed} redeemed`
      };
      const unique_limited = {
        key: entry.id + 200000,
        name: `Unique limited: ${entry.coupons.unique_limited.total} total, ${entry.coupons.unique_limited.redeemed} redeemed`
      };
      const unique_unlimited = {
        key: entry.id + 300000,
        name: `Unique unlimited: ${entry.coupons.unique_unlimited.total} total, ${entry.coupons.unique_unlimited.redeemed} redeemed`
      };
      arr.push(normal);
      arr.push(unique_limited);
      arr.push(unique_unlimited);

      const row = {
        ...entry,
        key: entry.id,
        children: arr
      };

      return row;
    });
  }

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id'
    },
    {
      title: 'Date end',
      dataIndex: 'date_end',
      key: 'date_end'
    }
  ];

  const CustomTooltip = ({ active, payload, label }) => {
    if (active) {
      return (
        <StyledRechartsTooltip>
          <p>{`Date: ${payload[0].payload.calendar_date}`}</p>
          <p>{`Redeemed: ${parseInt(payload[0].payload.redeemed_coupons)}`}</p>
        </StyledRechartsTooltip>
      );
    }

    return null;
  };

  const setGraphDimensions = () => {
    if (graphWrapper.current) {
      setDimensions({
        width: graphWrapper.current.getBoundingClientRect().width - 100
      });
    }
  };

  useLayoutEffect(() => {
    setTimeout(() => {
      setGraphDimensions();
    }, 0);
  }, []);

  window.addEventListener('resize', () => {
    clearInterval(movement_timer);
    movement_timer = setTimeout(setGraphDimensions, RESET_TIMEOUT);
  });

  return (
    <Fragment>
      <Row gutter={24}>
        <Col xs={24} sm={24} md={24} lg={24} xl={12}>
          <StyledWrapper>
            <h3>Campaigns</h3>
            <Table
              columns={columns}
              dataSource={tableData}
              pagination={{ pageSize: 10 }}
              scroll={{ x: 'max-content' }}
              size="middle"
            />
          </StyledWrapper>
        </Col>
        <Col xs={24} sm={24} md={24} lg={24} xl={12}>
          <StyledWrapper ref={graphWrapper}>
            <h3>Coupons redeemed in last 30 days</h3>
            <BarChart width={dimensions.width} height={250} data={graphData}>
              <CartesianGrid stroke="#eee" strokeDasharray="3 3" />
              <XAxis dataKey="calendar_date" />
              <YAxis />
              <Tooltip
                content={CustomTooltip}
                allowEscapeViewBox={{ x: true, y: true }}
                wrapperStyle={{ zIndex: 1000 }}
              />
              <Bar dataKey="redeemed_coupons" fill="#1890ff" />
            </BarChart>
          </StyledWrapper>
        </Col>
      </Row>
    </Fragment>
  );
};

export default CampaignsDetails;
