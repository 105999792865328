import React, { useContext, useState, Fragment } from 'react';
import styled from 'styled-components';
import { Table, Icon, Button, Divider } from 'antd';

import { MembershipPlansContext } from '../../contexts/MembershipPlansContext';
import SingleMembershipPlan from './SingleMembershipPlan';
import CreateMembershipPlan from './CreateMembershipPlan';
import EditMembershipPlan from './EditMembershipPlan';
import DeleteMembershipPlan from './DeleteMembershipPlan';

const StyledButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 1rem;
`;

const StyledButton = styled(Button)`
  margin-right: 0.5rem;
`;

const StyledIcon = styled.span`
  cursor: pointer;
`;

const StyledSpan = styled.span`
  color: #1890ff;
  cursor: pointer;
`;

const MembershipPlanList = () => {
  const {
    membershipPlans,
    getSingleMembershipPlan,
    selectedMembershipPlan,
    setSelectedMembershipPlan
  } = useContext(MembershipPlansContext);
  const [modalVisible, setModalVisible] = useState(false);
  const [modal, setModal] = useState('');
  const [drawer, setDrawer] = useState(false);

  const transformedMembershipPlan = membershipPlans.map(membershipPlan => {
    const membershipPlanName = membershipPlan.name
      .split(' ')
      .map(w => w.substring(0, 1).toUpperCase() + w.substring(1))
      .join(' ');

    const transformedMembershipPlan = {
      ...membershipPlan,
      name: membershipPlanName
    };

    return transformedMembershipPlan;
  });

  const columns = [
    {
      title: 'Name',
      key: 'name',
      sorter: (a, b) => a.name.localeCompare(b.name),
      sortDirections: ['descend', 'ascend'],
      render: membershipPlan => (
        <StyledSpan
          onClick={() => {
            getSingleMembershipPlan(membershipPlan.id);
            setDrawer(true);
          }}
        >
          {membershipPlan.name}
        </StyledSpan>
      )
    },
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      sorter: (a, b) => a.id - b.id,
      sortDirections: ['descend', 'ascend']
    },
    {
      title: 'Price',
      dataIndex: 'price',
      sorter: (a, b) => a.price - b.price,
      sortDirections: ['descend', 'ascend'],
      render: price => <span>€ {price}</span>
    },
    {
      title: 'Days',
      dataIndex: 'days',
      sorter: (a, b) => a.days - b.days,
      sortDirections: ['descend', 'ascend']
    },
    {
      title: 'Action',
      render: membershipPlan => (
        <span>
          <StyledIcon
            onClick={() => {
              setModalVisible(true);
              setModal('edit');
              getSingleMembershipPlan(membershipPlan.id);
            }}
          >
            <Icon type="edit" />
          </StyledIcon>
          <Divider type="vertical" />
          <StyledIcon
            onClick={() => {
              setModalVisible(true);
              setModal('delete');
              getSingleMembershipPlan(membershipPlan.id);
            }}
          >
            <Icon type="delete" />
          </StyledIcon>
        </span>
      )
    }
  ];

  return (
    <Fragment>
      <StyledButtonWrapper>
        <StyledButton
          shape="circle"
          icon="plus"
          type="primary"
          onClick={() => {
            setModalVisible(true);
            setModal('create');
          }}
        ></StyledButton>
      </StyledButtonWrapper>
      <Table
        columns={columns}
        rowKey={membershipPlan => membershipPlan.id}
        dataSource={transformedMembershipPlan}
        pagination={{ pageSize: 10 }}
        scroll={{ x: 'max-content' }}
      />
      <CreateMembershipPlan
        modalVisible={modalVisible}
        setModalVisible={setModalVisible}
        modal={modal}
      />
      <SingleMembershipPlan
        drawer={drawer}
        setDrawer={setDrawer}
        selectedMembershipPlan={selectedMembershipPlan}
        setSelectedMembershipPlan={setSelectedMembershipPlan}
      />
      <EditMembershipPlan
        modalVisible={modalVisible}
        setModalVisible={setModalVisible}
        modal={modal}
        selectedMembershipPlan={selectedMembershipPlan}
        setSelectedMembershipPlan={setSelectedMembershipPlan}
      />
      <DeleteMembershipPlan
        modalVisible={modalVisible}
        setModalVisible={setModalVisible}
        modal={modal}
        selectedMembershipPlan={selectedMembershipPlan}
        setSelectedMembershipPlan={setSelectedMembershipPlan}
      />
    </Fragment>
  );
};

export default MembershipPlanList;
