import React, { useContext, useState, Fragment } from 'react';
import styled from 'styled-components';
import { Table, Icon, Button, Divider } from 'antd';

import { CommunitiesContext } from '../../contexts/CommunitiesContext';
import CreateCommunity from './CreateCommunity';
import EditCommunity from './EditCommunity';
import DeleteCommunity from './DeleteCommunity';
import SingleCommunity from './SingleCommunity';

const StyledButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 1rem;
`;

const StyledButton = styled(Button)`
  margin-right: 0.5rem;
`;

const StyledIcon = styled.span`
  cursor: pointer;
`;

const StyledSpan = styled.span`
  color: #1890ff;
  cursor: pointer;
`;

const CommunitiesList = () => {
  const { communities } = useContext(CommunitiesContext);
  const [modalVisible, setModalVisible] = useState(false);
  const [modal, setModal] = useState('');
  const [drawer, setDrawer] = useState(false);
  const [selectedCommunity, setSelectedCommunity] = useState({});

  const transformedCommunities = communities.map(community => {
    const communityName = community.name
      .split(' ')
      .map(w => w.substring(0, 1).toUpperCase() + w.substring(1))
      .join(' ');

    let communityAddress;

    if (community.address) {
      communityAddress = community.address
        .split(' ')
        .map(w => w.substring(0, 1).toUpperCase() + w.substring(1))
        .join(' ');
    }

    const transformedCommunity = {
      ...community,
      name: communityName,
      address: communityAddress
    };

    return transformedCommunity;
  });

  const columns = [
    {
      title: 'Name',
      key: 'name',
      sorter: (a, b) => a.name.localeCompare(b.name),
      sortDirections: ['descend', 'ascend'],
      render: community => (
        <StyledSpan
          onClick={() => {
            setSelectedCommunity(community);
            setDrawer(true);
          }}
        >
          {community.name}
        </StyledSpan>
      )
    },
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      sorter: (a, b) => a.id - b.id,
      sortDirections: ['descend', 'ascend']
    },
    {
      title: 'Address',
      dataIndex: 'address',
      key: 'address'
    },
    {
      title: 'Action',
      render: community => (
        <span>
          <StyledIcon
            onClick={() => {
              setModalVisible(true);
              setModal('edit');
              setSelectedCommunity(community);
            }}
          >
            <Icon type="edit" />
          </StyledIcon>
          <Divider type="vertical" />
          <StyledIcon
            onClick={() => {
              setModalVisible(true);
              setModal('delete');
              setSelectedCommunity(community);
            }}
          >
            <Icon type="delete" />
          </StyledIcon>
        </span>
      )
    }
  ];

  return (
    <Fragment>
      <StyledButtonWrapper>
        <StyledButton
          shape="circle"
          icon="plus"
          type="primary"
          onClick={() => {
            setModalVisible(true);
            setModal('create');
          }}
        ></StyledButton>
      </StyledButtonWrapper>
      <Table
        columns={columns}
        rowKey={community => community.id}
        dataSource={transformedCommunities}
        pagination={{ pageSize: 10 }}
        scroll={{ x: 'max-content' }}
      />
      <SingleCommunity
        drawer={drawer}
        setDrawer={setDrawer}
        selectedCommunity={selectedCommunity}
        setSelectedCommunity={setSelectedCommunity}
      />
      <CreateCommunity
        modalVisible={modalVisible}
        setModalVisible={setModalVisible}
        modal={modal}
      />
      <EditCommunity
        modalVisible={modalVisible}
        setModalVisible={setModalVisible}
        modal={modal}
        selectedCommunity={selectedCommunity}
      />
      <DeleteCommunity
        modalVisible={modalVisible}
        setModalVisible={setModalVisible}
        modal={modal}
        selectedCommunity={selectedCommunity}
      />
    </Fragment>
  );
};

export default CommunitiesList;
