import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Drawer, Typography, Spin, Row, Col } from 'antd';

import { CampaignsContext } from '../../contexts/CampaignsContext';
import moment from 'moment';

const { Text } = Typography;

const StyledImageWrapper = styled.div`
  margin-bottom: 2.5rem;
`;

const StyledImage = styled.img`
  max-width: 100%;
  object-fit: cover;
`;

const SingleCampaign = props => {
  const { response, setResponse } = useContext(CampaignsContext);
  const { drawer, setDrawer, selectedCampaign, setSelectedCampaign } = props;
  const [imageLoaded, setImageLoaded] = useState(false);
  const [loading, setLoading] = useState(true);

  const merchant = selectedCampaign.Merchant;

  const transformedCampaign = {
    Name: selectedCampaign.name,
    Unlimited: selectedCampaign.unlimited ? 'Yes' : 'No',
    'Total coupons': selectedCampaign.total_coupons || '0',
    'Available coupons': selectedCampaign.available_coupons || '0',
    'Used coupons': selectedCampaign.used_coupons || '0',
    'Reward points': selectedCampaign.reward_points || '0',
    'Short description': selectedCampaign.short_description || '-',
    Description: selectedCampaign.description || '-',
    Featured: selectedCampaign.featured ? 'Yes' : 'No',
    Freemium: selectedCampaign.freemium ? 'Yes' : 'No',
    Static: selectedCampaign.static ? 'Yes' : 'No'
  };

  if (selectedCampaign.static) {
    transformedCampaign['Discount code'] =
      selectedCampaign.discount_code || '-';
  }

  if (selectedCampaign.date_start) {
    transformedCampaign['Start date'] = moment(
      selectedCampaign.date_start
    ).format('YYYY-MM-DD HH:mm:ss');
  } else {
    transformedCampaign['Start date'] = '-';
  }

  if (selectedCampaign.date_end) {
    transformedCampaign['End date'] = moment(selectedCampaign.date_end).format(
      'YYYY-MM-DD HH:mm:ss'
    );
  } else {
    transformedCampaign['End date'] = '-';
  }

  if (merchant) {
    transformedCampaign.Merchant = `${merchant.name} (${merchant.address})`;
  } else {
    transformedCampaign.Merchant = '-';
  }

  if (selectedCampaign.Cities && selectedCampaign.Cities.length > 0) {
    transformedCampaign.Cities = selectedCampaign.Cities.map((city, index) => {
      if (index === selectedCampaign.Cities.length - 1) {
        return `${city.name} (${city.postal_code})`;
      } else {
        return `${city.name} (${city.postal_code}), `;
      }
    });
  } else {
    transformedCampaign.Cities = '-';
  }

  if (selectedCampaign.Countries && selectedCampaign.Countries.length > 0) {
    transformedCampaign.Countries = selectedCampaign.Countries.map(
      (country, index) => {
        if (index === selectedCampaign.Countries.length - 1) {
          return `${country.name} (${country.iso})`;
        } else {
          return `${country.name} (${country.iso}), `;
        }
      }
    );
  } else {
    transformedCampaign.Countries = '-';
  }

  if (selectedCampaign.Communities && selectedCampaign.Communities.length > 0) {
    transformedCampaign.Communities = selectedCampaign.Communities.map(
      (community, index) => {
        if (index === selectedCampaign.Communities.length - 1) {
          return `${community.name} (${community.address})`;
        } else {
          return `${community.name} (${community.address}), `;
        }
      }
    );
  } else {
    transformedCampaign.Communities = '-';
  }

  if (
    selectedCampaign.RedeemLocations &&
    selectedCampaign.RedeemLocations.length > 0
  ) {
    transformedCampaign[
      'Redeem locations'
    ] = selectedCampaign.RedeemLocations.map((redeemLocation, index) => {
      if (index === selectedCampaign.RedeemLocations.length - 1) {
        return `${redeemLocation.name} (${redeemLocation.address})`;
      } else {
        return `${redeemLocation.name} (${redeemLocation.address}), `;
      }
    });
  } else {
    transformedCampaign['Redeem locations'] = '-';
  }

  useEffect(() => {
    if (drawer) {
      setLoading(true);
    }
  }, [drawer]);

  useEffect(() => {
    if (
      response.requestFor === 'get single' &&
      response.requestInProgress === false &&
      response.id
    ) {
      setLoading(false);
    }
  }, [response]);

  return (
    <Drawer
      width={640}
      placement="right"
      closable={false}
      onClose={() => {
        setDrawer(false);
        setResponse({});
        setLoading(false);
        setTimeout(() => {
          setSelectedCampaign({});
        }, 300);
      }}
      visible={drawer}
    >
      <Spin spinning={loading}>
        {selectedCampaign.image ? (
          <StyledImageWrapper>
            <Spin spinning={!imageLoaded}>
              <StyledImage
                src={selectedCampaign.image}
                onLoad={() => setImageLoaded(true)}
              />
            </Spin>
          </StyledImageWrapper>
        ) : null}
        <div>
          {Object.keys(transformedCampaign).map((keyName, i) => {
            return (
              <p key={i}>
                <Text>
                  {keyName}:{' '}
                  <Text type="secondary">{transformedCampaign[keyName]}</Text>
                </Text>
              </p>
            );
          })}
          <p>
            Coupons: <Link to="/coupons">Link</Link>
          </p>

          {selectedCampaign.benefits && selectedCampaign.benefits.length > 0 && <h3>Benefits</h3>}

          {( Array.isArray(selectedCampaign.benefits) ? selectedCampaign.benefits : []).map(benefit => {
            return (
              <Row>
                <Col span={8}><b>{benefit.title}</b></Col>
                <Col span={8}><b>Price before:</b> {benefit.price_before}</Col>
                <Col span={8}><b>Price after:</b> {benefit.price_after}</Col>
              </Row>
            );
          })}

        </div>
        {selectedCampaign.Medias && selectedCampaign.Medias.length > 0 && <h3>Gallery</h3>}
        <Row gutter={16}>
          {(selectedCampaign.Medias || []).map(media => {
            return (
              <Col className="gutter-row" span={6} key={media.id}>
                <StyledImage src={media.path} />
              </Col>
            );
          })}
        </Row>
      </Spin>
    </Drawer>
  );
};

export default SingleCampaign;
